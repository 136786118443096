<div class="page md">
  <app-packages-slider></app-packages-slider>

  <app-breadcrumbs
    [links]="[
      ['/', 'Main page'],
      ['/personal-area', 'Personal area'],
      ['/personal-area/packages', 'My packages'],
      ['', h1]
    ]"
  ></app-breadcrumbs>

  <div class="pa">
    <h1 class="title">{{ h1 | translate }}</h1>

    <div class="pa-container">
      <app-sidebar active="packages/{{ request.packageState }}"></app-sidebar>

      <div class="pa-content">
        <div class="filter df aic jcsb mb4">
          <div class="filter-components ui accordion">
            <button
              type="button"
              class="title btn filter__btn mr3"
              data-position="bottom center"
              data-tooltip="Фильтр"
            >
              <svg class="ic ic-filter mr05">
                <use xlink:href="/assets/img/sprite.svg#filter"></use>
              </svg>
              {{ 'Filter' | translate }}
            </button>
            <div class="content">
              <form class="filter-form" (submit)="onFilterSubmit()">
                <div class="filter-form__search mb15">
                  <div class="df aic mb15">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Club' | translate }}
                    </span>
                  </div>
                  <div class="pr">
                    <input
                      type="search"
                      id="filter-input"
                      class="form__input h4"
                      placeholder="Ввести название"
                    />
                    <button type="button" class="form-serach__btn">
                      <span class="feather-icon icon-search"></span>
                    </button>
                  </div>
                  <div class="filter-form__search-result">
                    <div class="form__item mb15">
                      <div class="ch">
                        <label class="ch__content">
                          <input
                            id="checkbox1"
                            type="checkbox"
                            class="ch__input"
                          />
                          <span class="ch__icon"></span>
                          <span class="ch__tx">Altamira</span>
                        </label>
                      </div>
                    </div>
                    <div class="form__item mb15">
                      <div class="ch">
                        <label class="ch__content">
                          <input
                            id="checkbox2"
                            type="checkbox"
                            class="ch__input"
                          />
                          <span class="ch__icon"></span>
                          <span class="ch__tx">Altamira 2</span>
                        </label>
                      </div>
                    </div>
                    <div class="form__item mb15">
                      <div class="ch">
                        <label class="ch__content">
                          <input
                            id="checkbox3"
                            type="checkbox"
                            class="ch__input"
                          />
                          <span class="ch__icon"></span>
                          <span class="ch__tx">Altamira 3</span>
                        </label>
                      </div>
                    </div>
                    <div class="form__item mb15">
                      <div class="ch">
                        <label class="ch__content">
                          <input
                            id="checkbox4"
                            type="checkbox"
                            class="ch__input"
                          />
                          <span class="ch__icon"></span>
                          <span class="ch__tx">Altamira 4</span>
                        </label>
                      </div>
                    </div>
                    <div class="form__item mb0">
                      <div class="ch">
                        <label class="ch__content">
                          <input
                            id="checkbox5"
                            type="checkbox"
                            class="ch__input"
                          />
                          <span class="ch__icon"></span>
                          <span class="ch__tx">Altamira 5</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="selection-filter">
                  <div id="selected1" class="selection-filter__item aic">
                    <p>Altamira</p>
                    <button
                      type="button"
                      class="selection-filter__close js-selection-filter-close"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 1L11 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div id="selected2" class="selection-filter__item aic">
                    <p>Altamira 2</p>
                    <button
                      type="button"
                      class="selection-filter__close js-selection-filter-close"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 1L11 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div id="selected3" class="selection-filter__item aic">
                    <p>Altamira 3</p>
                    <button
                      type="button"
                      class="selection-filter__close js-selection-filter-close"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 1L11 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div id="selected4" class="selection-filter__item aic">
                    <p>Altamira 4</p>
                    <button
                      type="button"
                      class="selection-filter__close js-selection-filter-close"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 1L11 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div id="selected5" class="selection-filter__item aic">
                    <p>Altamira 5</p>
                    <button
                      type="button"
                      class="selection-filter__close js-selection-filter-close"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 1L11 11"
                          stroke="#223355"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="filter-form__line"></div>
                <div class="filter-form__select">
                  <div class="df aic mb15">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Package type' | translate }}
                    </span>
                  </div>
                  <select
                    class="ui selection dropdown ddown filter__dropdown w100"
                    [(ngModel)]="request.packageType"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="">{{ 'All' | translate }}</option>
                    <option value=" ">{{ 'All' | translate }}</option>
                    <option value="Mixed">{{ 'Mixed' | translate }}</option>
                    <option value="One Club">
                      {{ 'One Club' | translate }}
                    </option>
                  </select>
                </div>
                <div class="filter-form__line"></div>
                <div class="filter-form__check">
                  <div class="df aic mb15">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Owner rating' | translate }}:
                    </span>
                  </div>
                  <div class="mcard__title title">
                    <svg class="ic ic-card-arrow ic-round">
                      <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
                    </svg>
                  </div>
                  <div class="content ui accordion">
                    <div class="">
                      <div
                        class="form__item mb1"
                        *ngFor="let userRating of userRatings"
                      >
                        <div class="ch">
                          <label class="ch__content">
                            <input
                              type="checkbox"
                              class="ch__input"
                              [(ngModel)]="userRating.selected"
                              (change)="onUserRatingsChange()"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <span class="ch__icon"></span>
                            <span class="ch__tx">{{ userRating.name }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="form__item mb1">
                        <div class="ch">
                          <label class="ch__content">
                            <input
                              type="checkbox"
                              class="ch__input"
                              [(ngModel)]="userRatingsAllSelected"
                              (change)="onChangeAllUserRatings()"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <span class="ch__icon"></span>
                            <span class="ch__tx">{{ 'All' | translate }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-form__line"></div>
                <div class="filter-form__check">
                  <div class="df aic mb15">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Package rating' | translate }}:
                    </span>
                  </div>
                  <div class="mcard__title title">
                    <svg class="ic ic-card-arrow ic-round">
                      <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
                    </svg>
                  </div>
                  <div class="content ui accordion">
                    <div class="">
                      <div
                        class="form__item mb1"
                        *ngFor="let packageRating of packageRatings"
                      >
                        <div class="ch">
                          <label class="ch__content">
                            <input
                              type="checkbox"
                              class="ch__input"
                              [(ngModel)]="packageRating.selected"
                              (change)="onPackageRatingsChange()"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <span class="ch__icon"></span>
                            <span class="ch__tx">{{ packageRating.name }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="form__item mb1">
                        <div class="ch">
                          <label class="ch__content">
                            <input
                              type="checkbox"
                              class="ch__input"
                              [(ngModel)]="packageRatingsAllSelected"
                              (change)="onChangeAllPackageRatings()"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <span class="ch__icon"></span>
                            <span class="ch__tx">{{ 'All' | translate }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-form__line"></div>
                <div class="filter-form__chose mb2">
                  <div class="df aic">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Package cost' | translate }}, USD:
                    </span>
                  </div>
                  <div class="chose__range-items">
                    <input
                      type="range"
                      name=""
                      class="js-range-slider1"
                      id="js-range-slider"
                    />
                    <div class="extra-controls">
                      <span>
                        {{ 'From' | translate }}
                        <input
                          [(ngModel)]="request.minPrice"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_from"
                          class="js-input-from rang_slider_from1"
                          value=""
                        />
                      </span>
                      <span>
                        {{ 'To' | translate }}
                        <input
                          [(ngModel)]="request.maxPrice"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_to"
                          class="js-input-to rang_slider_to1"
                          value=""
                        />
                      </span>
                    </div>
                    <!-- <div id="cost"></div> -->
                  </div>
                </div>
                <div class="filter-form__chose mb2">
                  <div class="df aic">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Cost for 1 certificate' | translate }}, USD:
                    </span>
                  </div>
                  <div class="chose__range-items">
                    <input
                      type="range"
                      name=""
                      class="js-range-slider1"
                      id="js-range-slider2"
                    />
                    <div class="extra-controls">
                      <span>
                        {{ 'From' | translate }}
                        <input
                          [(ngModel)]="request.minCertPrice"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_from"
                          class="js-input-from rang_slider_from2"
                          value=""
                        />
                      </span>
                      <span>
                        {{ 'To' | translate }}
                        <input
                          [(ngModel)]="request.maxCertPrice"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_to"
                          class="js-input-to rang_slider_to2"
                          value=""
                        />
                      </span>
                    </div>
                    <!-- <div id="cost"></div> -->
                  </div>
                </div>
                <div class="filter-form__chose mb2">
                  <div class="df aic">
                    <span class="fz14 ff:CormorantInfant">
                      {{ 'Number of certificates' | translate }}:
                    </span>
                  </div>
                  <div class="chose__range-items">
                    <input
                      type="range"
                      name=""
                      class="js-range-slider1"
                      id="js-range-slider3"
                    />
                    <div class="extra-controls">
                      <span>
                        {{ 'From' | translate }}
                        <input
                          [(ngModel)]="request.minCertQuantity"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_from"
                          class="js-input-from rang_slider_from3"
                          value=""
                        />
                      </span>
                      <span>
                        {{ 'To' | translate }}
                        <input
                          [(ngModel)]="request.maxCertQuantity"
                          [ngModelOptions]="{ standalone: true }"
                          type="number"
                          name="price_to"
                          class="js-input-to rang_slider_to3"
                          value=""
                        />
                      </span>
                    </div>
                    <!-- <div id="cost"></div> -->
                  </div>
                </div>
                <div class="filter-form__line"></div>
                <div class="df aic jcc filter-form__moretitle title">
                  <div class="df aic mr15">
                    <svg
                      class="ic ic-sort mr05"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66797 14.9768V10.2558"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.66797 7.55815V2.83722"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 14.9767V8.90698"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 6.20931V2.83722"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.332 14.9768V11.6047"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.332 8.90699V2.83722"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.667969 10.2558H4.66797"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 6.20932H10"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.332 11.6047H15.332"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span class="fz14 ff:CormorantInfant open">
                      Расширенный фильтр
                    </span>
                    <span class="fz14 ff:CormorantInfant close">
                      Сокращенный фильтр
                    </span>
                  </div>
                </div>

                <div class="filter-form__morecontent content mb2">
                  <div class="filter-form__select mb2">
                    <div class="df aic mb15">
                      <span class="fz14 ff:CormorantInfant">
                        Тип права требования
                      </span>
                    </div>
                    <select
                      class="ui selection dropdown ddown filter__dropdown w100"
                    >
                      <option value="0">Бессрочный</option>
                      <option value="1">Cрочный</option>
                    </select>
                  </div>
                  <div class="filter-form__params">
                    <div class="filter-form__title mb15">
                      Параметры права требования:
                    </div>
                    <div class="df aic jcsb mb15">
                      <div class="filter-form__select">
                        <div class="df aic mb15">
                          <span class="fz14 ff:CormorantInfant">
                            Кол-во интервалов
                          </span>
                        </div>
                        <select
                          class="ui selection dropdown ddown filter__dropdown"
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                      <div class="filter-form__select">
                        <div class="df aic mb15">
                          <span class="fz14 ff:CormorantInfant">
                            Номер недели
                          </span>
                        </div>
                        <select
                          class="ui selection dropdown ddown filter__dropdown"
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </div>
                    </div>
                    <div class="filter-form__check">
                      <div class="df aic mb15">
                        <span class="fz14 ff:CormorantInfant">
                          Наличие документов:
                        </span>
                      </div>
                      <div class="form__item mb1">
                        <div class="ch">
                          <label class="ch__content mb15">
                            <input
                              type="radio"
                              name="doc"
                              value="Да"
                              class="ch-radio__input"
                              checked
                            />
                            <span class="ch__tx">Да</span>
                          </label>
                          <label class="ch__content">
                            <input
                              type="radio"
                              name="doc"
                              value="Нет"
                              class="ch-radio__input"
                            />
                            <span class="ch__tx">Нет</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="filter-form__line"></div>
                  <div class="filter-form__select">
                    <div class="df aic mb15">
                      <span class="fz14 ff:CormorantInfant">
                        Система обмена
                      </span>
                    </div>
                    <select
                      class="ui selection dropdown ddown filter__dropdown w100"
                    >
                      <option value="0">RCI</option>
                      <option value="1">RCI</option>
                    </select>
                  </div>
                  <div class="filter-form__line"></div>
                  <div class="filter-form__select">
                    <div class="df aic mb15">
                      <span class="fz14 ff:CormorantInfant">
                        Управленческие взносы, последний
                        <br />
                        год оплаты:
                      </span>
                    </div>
                    <select
                      class="ui selection dropdown ddown filter__dropdown w100"
                    >
                      <option value="0">1990</option>
                      <option value="1">1991</option>
                      <option value="1">1992</option>
                      <option value="1">1993</option>
                    </select>
                  </div>
                  <div class="filter-form__line"></div>
                  <div class="filter-form__check">
                    <div class="df aic mb15">
                      <span class="fz14 ff:CormorantInfant">Сезон:</span>
                    </div>
                    <div class="mcard__title title">
                      <svg class="ic ic-card-arrow ic-round">
                        <use
                          xlink:href="/assets/img/sprite.svg#card-arrow"
                        ></use>
                      </svg>
                    </div>
                    <div class="content ui accordion">
                      <div class="">
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content aifs">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">
                                Super High – (супер высокий) RED
                              </span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content aifs">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">High – (высокий) RED</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content aifs">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">
                                Middle – (cредний) YELLOW,
                                <br />
                                GREEN (желтый, зеленый)
                              </span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content aifs">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">
                                Low – (низкий) BLUE,
                                <br />
                                WHITE (голубой, белый)
                              </span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">Все</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="filter-form__line"></div>
                  <div class="filter-form__check">
                    <div class="df aic mb15">
                      <span class="fz14 ff:CormorantInfant">
                        Тип апартаментов:
                      </span>
                    </div>
                    <div class="mcard__title title">
                      <svg class="ic ic-card-arrow ic-round">
                        <use
                          xlink:href="/assets/img/sprite.svg#card-arrow"
                        ></use>
                      </svg>
                    </div>
                    <div class="content ui accordion">
                      <div class="">
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">T0</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">T1</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">T2</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">T3</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">T4</span>
                            </label>
                          </div>
                        </div>
                        <div class="form__item mb1">
                          <div class="ch">
                            <label class="ch__content">
                              <input type="checkbox" class="ch__input" />
                              <span class="ch__icon"></span>
                              <span class="ch__tx">Все</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filter-form__btns df aic jcsb">
                  <button type="submit" class="btn">
                    {{ 'Apply' | translate }}
                  </button>
                  <button
                    type="button"
                    class="btn btn_wt"
                    (click)="onResetFilterClick()"
                  >
                    {{ 'Reset' | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="df aitc">
            <button
              class="btn filter__btn mr2"
              data-position="bottom center"
              data-tooltip="Право требования"
              (click)="addNewCertificate()"
            >
              <svg
                class="ic-plus mr05"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.49982 14.318C11.2654 14.318 14.318 11.2654 14.318 7.49982C14.318 3.73424 11.2654 0.681641 7.49982 0.681641C3.73424 0.681641 0.681641 3.73424 0.681641 7.49982C0.681641 11.2654 3.73424 14.318 7.49982 14.318Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M7.5 4.77295V10.2275"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.77344 7.5H10.228"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Право требования
            </button>
            <button
              class="btn filter__btn mr2"
              data-position="bottom center"
              data-tooltip="Объединить выделенные"
            >
              <svg
                class="ic-plus mr05"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.29395 6.70589L7.33014 8.82353L14.1175 1.76471"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M13.2358 7.05879V11.8627C13.2358 12.2267 13.0911 12.5759 12.8337 12.8333C12.5763 13.0907 12.2272 13.2353 11.8632 13.2353H2.25536C1.89134 13.2353 1.54223 13.0907 1.28482 12.8333C1.02742 12.5759 0.882813 12.2267 0.882812 11.8627V2.25487C0.882812 1.89085 1.02742 1.54174 1.28482 1.28433C1.54223 1.02693 1.89134 0.882324 2.25536 0.882324H9.80438"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Объединить выделенные
            </button>
            <button
              class="btn filter__btn"
              data-position="bottom center"
              data-tooltip="Снять выбор"
            >
              <svg
                class="ic-plus mr05"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 0.976744C3.67345 0.976744 0.976744 3.67345 0.976744 7C0.976744 10.3266 3.67345 13.0233 7 13.0233C10.3266 13.0233 13.0233 10.3266 13.0233 7C13.0233 3.67345 10.3266 0.976744 7 0.976744ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
                  fill="white"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.09728 11.9034C1.90656 11.7127 1.90656 11.4035 2.09728 11.2128L11.2136 2.0965C11.4043 1.90577 11.7135 1.90577 11.9042 2.0965C12.0949 2.28722 12.0949 2.59644 11.9042 2.78716L2.78795 11.9034C2.59723 12.0942 2.28801 12.0942 2.09728 11.9034Z"
                  fill="white"
                ></path>
              </svg>
              Снять выбор
            </button>
          </div>
        </div>
        <div *ngIf="response">
          <div *ngIf="response.list.length > 0; else noResults">
            <app-publication-cards
              [state]="request.packageState"
              [list]="response.list"
              [mode]="10000"
            ></app-publication-cards>
          </div>
          <ng-template #noResults>
            <p class="sm">{{ noResultsText | translate }}</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
