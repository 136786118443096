import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembersRequest } from 'src/app/core/models/members/members-request.model';
import { Utils } from 'src/app/core/utils/utils';

declare function initAccordion(): void;
declare function initFilterButton(): void;
declare function initDropdowns(): void;

@Component({
  selector: 'app-members-filter',
  templateUrl: './members-filter.component.html',
  styleUrls: ['./members-filter.component.css']
})
export class MembersFilterComponent implements OnInit {

  @Input() request!: MembersRequest;
  @Output() changed = new EventEmitter(true);

  ratings = [
    { id: 0, name: 'A', selected: false },
    { id: 1, name: 'B', selected: false },
    { id: 2, name: 'C', selected: false },
  ];
  ratingsAllSelected: boolean = false;

  roles = [
    { id: 2, name: 'Dealer', selected: false },
    { id: 4, name: 'Broker', selected: false },
  ];
  rolesAllSelected: boolean = false;

  constructor(
    private utils: Utils
  ) { }

  ngOnInit(): void {
    initAccordion();
    initFilterButton();
    initDropdowns();
  }

  onSortChange(): void {
    this.changed.emit();
  }

  toggleSortWay(): void {
    this.request.sortWay = this.request.sortWay == '' ? 'desc' : '';
    this.changed.emit();
  }

  onSubmit(): void {
    const filterElement = document.querySelector('.filter') as HTMLElement;
    const headerElement = document.querySelector('.header') as HTMLElement;
    const position = filterElement.offsetTop - headerElement.offsetHeight - 32
    this.utils.scrollToTop(position);
    this.utils.closeAccordion();
    this.changed.emit();
  }

  onRatingsChange() {
    this.request.ratings = this.ratings.filter(x => x.selected).map(x => x.id).join();
    this.ratingsAllSelected = this.ratings.every(x => x.selected);
  }

  onChangeAllRatings() {
    this.ratings.map(x => x.selected = this.ratingsAllSelected);
    this.request.ratings = this.ratings.filter(x => x.selected).map(x => x.id).join();
  }

  onTypesChange() {
    this.request.roles = this.roles.filter(x => x.selected).map(x => x.id).join();
    this.rolesAllSelected = this.roles.every(x => x.selected);
  }

  onChangeAllTypes() {
    this.roles.map(x => x.selected = this.rolesAllSelected);
    this.request.roles = this.roles.filter(x => x.selected).map(x => x.id).join();
  }
}
