import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { BuyWfComponent } from 'src/app/blocks/buy-wf/buy-wf.component';
import { PublicationStatusesEnum } from 'src/app/core/enums/publication-statuses.enum';
import { PublicationTypesEnum } from 'src/app/core/enums/publication-types.enum';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { FinancialStatisticsService } from 'src/app/features/personal-area/services/financial-statistics/financial-statistics.service';

declare var $: any;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;

@Component({
  selector: 'app-publication-cards',
  templateUrl: './publication-cards.component.html',
  styleUrls: ['./publication-cards.component.css']
})
export class PublicationCardsComponent implements OnInit, OnChanges {
  @Input() list!: Publication[];
  @Input() mode: number = 8;
  @Input() state: string = 'unpublished';

  isAuthorized: boolean = false;
  isDealer: boolean = false;
  getPercentProgressBar = this.financialService.getPercentProgressBar

  pdfOptions = {
    filename: 'package.pdf',
    margin: 2,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { scale: 2 },
    enableLinks: false,
    jsPDF: { unit: 'em', format: 'a4', orientation: 'portrait' }
  };

  constructor(public bw: BuyWfComponent, private router: Router, private financialService: FinancialStatisticsService) {}
 

  ngOnInit(): void {
    // if(this.list.length > 0){
      
    //   this.list.forEach( element=> {
    //     element.persentConnectionAmount = this.getPercentProgressBar(element);
    //   })
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.list.length > 0){
      
      this.list.forEach( element=> {
        if(element.publicationTypeCode ==='type_pub_offer_add'){
          element.persentConnectionAmount = this.getPercentProgressBar(element);
        }
        
      })
    }
  }


  preparePdfContent() {
    const doc = $('.html2pdf__container');
    doc.find('.pdf-add').removeClass('hidden');
    doc.find('.pdf-remove').remove();
    doc
      .find('.pdf-shrink')
      .css({ width: '70%', 'margin-right': 'auto', 'margin-left': 'auto' });
    doc.find('a').css({ color: 'inherit', 'text-decoration': 'none' });
  }

  download(event: Event, publication: Publication) {
    $('#downloading-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      this.pdfOptions.filename = `${
        publication.dealTypeId != 3 ? 'package' : 'auction'
      }-${publication.id}.pdf`;
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .save()
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }

  async doAction(item: Publication): Promise<void> {
    //debugger;
    if (
      item.publicationTypeCode == PublicationTypesEnum.Sell &&
      item.publicationStatusCode == PublicationStatusesEnum.Published
    ) {
      this.bw.dealStart(item);
    } else if (
      item.publicationTypeCode == PublicationTypesEnum.Buy &&
      item.publicationStatusCode == PublicationStatusesEnum.Published
    ) {
      this.router.navigateByUrl(`/publications/${item.publicationId}`);
    } else if (
      item.publicationTypeCode == PublicationTypesEnum.Add &&
      item.publicationStatusCode == PublicationStatusesEnum.Published
    ) {
      this.router.navigateByUrl(`/publications/${item.publicationId}`);
    } else {
      let msg = `Не верный статус или тип лота: ${item.publicationTypeCode}/${item.publicationStatusCode}. Данное действие не возможно.`;

      anAlert(msg, 'OK');
    }
  }

  print(event: Event, publication: Publication) {
    $('#printing-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      this.pdfOptions.filename = `${
        publication.dealTypeId != 3 ? 'package' : 'auction'
      }-${publication.id}.pdf`;
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          pdf.autoPrint();
          $.modal.close();
          setTimeout(() => {
            window.open(pdf.output('bloburl'), '_blank');
          }, 100);
        })
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }
}
