import { formatDate, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';

//import { PublicationService } from 'src/app/publication.service';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

import { BuyWfComponent } from 'src/app/blocks/buy-wf/buy-wf.component';
import { SecurityContext } from 'src/app/core/models/auth/security-context.model';
import { AuthorizationService } from 'src/app/core/services/authorization/authorization.service';
declare function ucwords(str: string): string;
declare var scrollIntoView: any;
declare var $: any;
declare var gsap: any;

@Component({
  selector: 'app-top5-top',
  templateUrl: './top5-top.component.html',
  styleUrls: ['./top5-top.component.css']
})
export class Top5TopComponent implements OnInit {
  top5: any = {};
  bodyOpened: boolean = false;
  request: PublicationsRequest = new PublicationsRequest();

  colors = [
    'h:bg-twilight-blue',
    'h:bg-alice-blue',
    'h:bg-champagne',
    'h:bg-iron',
    'h:bg-janna'
  ];

  securityContext: SecurityContext = new SecurityContext();

  constructor(
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private authorizationService: AuthorizationService,
    public bw: BuyWfComponent
  ) {
    this.authorizationService.securityContext.subscribe(
      (securityContext) => (this.securityContext = securityContext)
    );
  }

  async ngOnInit() {
    //this.request.orderBy = 'stopDate,price';
    this.request.orderBy = 'price';
    this.request.orderWay = 'desc';
    this.request.page = 0;
    this.request.limit = 5;
    this.request.onlyActual = 1;

    ['sale', 'purchase'].map(async (block: string) => {
      this.request.packageType =
        block == 'sale'
          ? 'type_pub_packet'
          : 'type_pub_offer_buy, type_pub_offer_add';
      this.request.orderBy = block == 'sale' ? 'claimsAmount' : 'startingPrice';

      this.top5[block] = await this.publicationsService.getWidgetList(
        this.request
      );

      this.top5[block].map((item: any) => {
        (item.itemPrice = item.price),
          (item.price = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`);
        item.stopDate = formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU');
        item.clubName = ucwords(item.clubName);
        item.claimsAmount =
          block == 'sale'
            ? `RUB ${formatNumber(item.claimsAmount, 'ru-RU', '1.2-2')}`
            : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
        item.publicationId = item.publicationId;
      });
    });
  }
  buy(item) {
    this.bw.dealStart(item);
  }
  scrollBody() {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('.offers-top').offset().top - 70 - $('.nav').height()
      },
      500
    );
  }
}
