import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tinymce',
  templateUrl: './tinymce.component.html',
  styleUrls: ['./tinymce.component.css']
})
export class TinymceComponent {
  @Input() content!: string | null;
  @Output() contentChange = new EventEmitter<string>();
  apiKey = '63ljo1wq443ktbbyd3f5b3kuf518vrs8sdncy73v855v2kd2';

  init = {
    height: 300,
    menubar: false,
    statusbar: false,
    plugins: ['code image link table'],
    relative_urls: false,
    link_assume_external_targets: true,
    remove_script_host: false,
    content_style:
      '* { line-height: 1.57143 !important; font-size: 13.281px !important; }',
    toolbar:
      '\
      undo redo | formatselect | bold italic backcolor fontsizeselect | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | \
      image link | \
      table tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | \
      tableinsertcolbefore tableinsertcolafter tabledeletecol | \
      code \
    '
  };

  constructor() {}

  onNgModelChange(newContent: string) {
    this.contentChange.emit(newContent);
  }
}
