<div class="pa-nav ui accordion">
  <div class="pa-nav__item">
    <div class="pa-nav__head title" [class.is-active]="['profile', 'subscription', 'offers'].includes(active)">
      <div class="pa-nav__title">
        <a routerLink="/personal-area">{{'User profile' | translate}}</a>
      </div>
      <div class="pa-nav__icon">
        <svg class="ic ic-card-arrow ic-round">
          <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
        </svg>
      </div>
    </div>
    <div class="pa-nav-list content" [class.active]="['profile', 'subscription', 'offers'].includes(active)">
      <div class="pa-nav-list__wrap">
        <a routerLink="/personal-area/profile" class="pa-nav-list__item" [class.is-active]="active == 'profile'">
          {{'User data' | translate}}
        </a>
        <a routerLink="/personal-area/subscription" class="pa-nav-list__item"
          [class.is-active]="active == 'subscription'">
          {{'Manage subscription' | translate}}
        </a>
        <a routerLink="/personal-area/offers" class="pa-nav-list__item" [class.is-active]="active == 'offers'">
          {{'Site offers' | translate}}
        </a>
      </div>
    </div>
  </div>
  <div class="pa-nav__item">
    <div class="pa-nav__head title"
      [class.is-active]="['new-certificate','private-packages/published', 'private-packages/unpublished', 'private-packages/blocked', 'private-packages/archived','private-packages/myParticipateLots'].includes(active)">
      <div class="pa-nav__title">{{ 'My packages' | translate}}</div>
      <div class="pa-nav__icon">
        <svg class="ic ic-card-arrow ic-round">
          <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
        </svg>
      </div>
    </div>
    <div class="pa-nav-list content"
      [class.active]="['new-certificate','private-packages/published', 'private-packages/unpublished', 'private-packages/blocked', 'private-packages/archived','private-packages/myParticipateLots'].includes(active)">
      <div class="pa-nav-list__wrap">
        <a href="/personal-area/new-certificate" [class.is-active]="active == 'new-certificate'"
          class="pa-nav-list__item">{{ 'Add certificate' | translate }}</a>
        <a href="/personal-area/private-packages/unpublished" class="pa-nav-list__item"
          [class.is-active]="active == 'private-packages/unpublished'">{{ 'Unpublished' | translate }}</a>
        <a href="/personal-area/private-packages/published" class="pa-nav-list__item"
          [class.is-active]="active == 'private-packages/published'">{{ 'Published' | translate }}</a>
          <a href="/personal-area/private-packages/myParticipateLots" class="pa-nav-list__item orange-text-sidebar"
          [class.is-active]="active == 'private-packages/myParticipateLots'">{{ 'I participate in Lots' | translate }}</a>
        <!--<a href="/personal-area/private-packages/blocked" class="pa-nav-list__item" [class.is-active]="active == 'private-packages/blocked'">{{ 'Blocked' | translate }}</a>
        <a href="/personal-area/private-packages/inprogress" class="pa-nav-list__item" [class.is-active]="active == 'private-packages/inprogress'">{{ 'Deals in progress' | translate }}</a>-->
      </div>
    </div>


  </div>
  <!--<div class="pa-nav__item">
    <a routerLink="/personal-area/deals" [class.is-active]="active == 'deals'" class="pa-nav__head">
      <div class="pa-nav__title">{{ 'My deals' | translate}}</div>
    </a>
  </div>
  <div class="pa-nav__item">
    <a routerLink="/personal-area/contracts" [class.is-active]="active == 'contracts'" class="pa-nav__head">
      <div class="pa-nav__title">{{ 'My contracts' | translate }}</div>
    </a>
  </div>-->
  <!--<div class="pa-nav__item">
    <a routerLink="/personal-area/add-certificate" [class.is-active]="active == 'add-certificate'" class="pa-nav__head">
      <div class="pa-nav__title">{{ 'Add certificate' | translate}}</div>
    </a>
  </div>-->
  <div class="pa-nav__item">
    <div class="pa-nav__head title"
      [class.is-active]="['add-purchase-requisition','purchase-requisitions/published','purchase-requisitions/unpublished'].includes(active)">
      <div class="pa-nav__title">
        {{ 'My purchase' | translate }}
      </div>
      <div class="pa-nav__icon">
        <svg class="ic ic-card-arrow ic-round">
          <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
        </svg>
      </div>
    </div>

    <div class="pa-nav-list content padding-top-0"
      [class.active]="['add-purchase-requisition','purchase-requisitions/published','purchase-requisitions/unpublished', 'add-purchase-joining','purchase-joining/published','purchase-joining/unpublished','participate-applications-joining'].includes(active)">
      
      <div class="pa-nav__sub__head title "
      [class.active]="['add-purchase-requisition','purchase-requisitions/published','purchase-requisitions/unpublished'].includes(active)">
      <div class="pa-nav__title">
        {{ 'Purchase requests' | translate }}
      </div>
      <div class="pa-nav__icon">
        <svg class="ic ic-card-arrow ic-round">
          <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
        </svg>
      </div>
    </div>
      
      <div class="pa-nav-list content padding-top-0"
        [class.active]="['add-purchase-requisition','purchase-requisitions/published','purchase-requisitions/unpublished'].includes(active)">
      

        <div class="pa-nav-list__wrap">
          <a routerLink="/personal-area/add-purchase-requisition"
            [class.is-active]="active == 'add-purchase-requisition'" class="pa-nav-list__item">
            {{ 'Add purchase requisition' | translate }}
          </a>
          <a routerLink="/personal-area/purchase-requisitions/published"
            [class.is-active]="active == 'purchase-requisitions/published'" class="pa-nav-list__item">
            {{ 'Published purchase requisitions' | translate }}
          </a>
          <a routerLink="/personal-area/purchase-requisitions/unpublished"
            [class.is-active]="active == 'purchase-requisitions/unpublished'" class="pa-nav-list__item">
            {{ 'Unpublished purchase requisitions' | translate }}
          </a>
        </div>
      </div>
      <div class="pa-nav__sub__head title" [class.is-active]="['add-purchase-joining','purchase-joining/published','purchase-joining/unpublished','participate-applications-joining'].includes(active)">
        <div class="pa-nav__title">
          {{ 'Applications for joining' | translate }}
        </div>
        <div class="pa-nav__icon">
          <svg class="ic ic-card-arrow ic-round">
            <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
          </svg>
        </div>
      </div>
      <div class="pa-nav-list content padding-top-0"
        [class.active]="['add-purchase-joining','purchase-joining/published','purchase-joining/unpublished','participate-applications-joining'].includes(active)">

        <div class="pa-nav-list__wrap">
          <a routerLink="/personal-area/add-purchase-joining" [class.is-active]="active == 'add-purchase-joining'"
            class="pa-nav-list__item">
            {{ 'Add Application for Joining' | translate }}
          </a>
          <a routerLink="/personal-area/purchase-joining/published" [class.is-active]="active == 'purchase-joining/published'"
            class="pa-nav-list__item">
            {{ 'Published purchase requisitions' | translate }}
          </a>
          <a routerLink="/personal-area/participate-applications-joining" [class.is-active]="active == 'participate-applications-joining'"
            class="pa-nav-list__item">
            {{ 'I participate in Applications' | translate }}
          </a>
          <a routerLink="/personal-area/purchase-joining/unpublished" [class.is-active]="active == 'purchase-joining/unpublished'"
            class="pa-nav-list__item">
            {{ 'Unpublished purchase requisitions' | translate }}
          </a>
        </div>
      </div>

    </div>

    <div class="pa-nav__item">
      <a routerLink="/personal-area/balance" [class.is-active]="active == 'balance'" class="pa-nav__head">
        <div class="pa-nav__title">{{ 'Balance' | translate}}</div>
      </a>
    </div>
    <!--<div class="pa-nav__item">
    <a routerLink="/personal-area" class="pa-nav__head">
      <div class="pa-nav__title">{{ 'Operations history' | translate }}</div>
    </a>
  </div>
  <div class="pa-nav__item">
    <a routerLink="/personal-area" class="pa-nav__head">
      <div class="pa-nav__title">{{ 'Sold packages archive' | translate }}</div>
    </a>
  </div>-->
    <div class="pa-nav__item">
      <a href="javascript:;" (click)="logout()" class="pa-nav__head">
        <div class="pa-nav__title">{{ 'Logout' | translate }}</div>
      </a>
    </div>
  </div>