
<div class="smodal-content w-500">
    <div class="modal-header">
        <div (click)="cancel()" class="modal-close"><svg class="icon"><use xlink:href="/assets/img/svg-sprite.svg#times-light"></use></svg></div>
     </div>
     <div class="text-center">
        <div class="font-alt-extra text-xl">{{data["title"]}}</div>
        <div class="divider-sm df"></div>
        <div class="text-sm mb-50" [innerHtml]="data['text']"></div>

        <ng-container *ngFor="let packet of packets" class="form__item">
            <div class="df align-center mb-20 p-10 shadow">
                <div>
                    <div class="mb-08 text-left color-primary-lighten text-sm font-alt-extra">Лот {{packet.number}}</div>
                    <div class="dl maw-full p-0">
                        <div class="dl__dt">Сумма исковых требований</div>
                        <div class="dl__points"></div>
                        <div class="dl__dd font-bold">{{packet.name}}</div>
                    </div>
                </div>
                <div class="dif ml-a">
                    <label class="ch__content">
                        <input type="radio" class="ch__input" [(ngModel)]="selected" [value]="packet.id">
                        <span class="ch__icon circle rounded"></span>
                    </label>
                </div>
            </div>    
        </ng-container>

        <button type="button" [disabled]="!selected" (click)="accept()" class="btn btn--primary mt-25 h-50">Продать свой лот за {{purchaseRequisition.priceFormatted}}</button>
    </div>  

<!--<div class="scont">
    <div *ngFor="let packet of packets" class="form__item">
        <div class="ch">
            <label  class="ch__content">
                <input  type="radio" [(ngModel)]="selected" [value]="packet.id" class="ch__input ng-untouched ng-pristine ng-valid">
                <span  class="ch__icon"></span>
                <span _ngcontent-wum-c147="" class="chform__input__tx">{{packet.name}}</span>
            </label>
        </div>
    </div>
</div>
<div class="smodal-footer mt2 pt2">
  <button type="button" [disabled]="!selected" (click)="accept()" class="btn btn--primary">Принять</button>
  <button type="button" class="btn btn--dark-outline" (click)="cancel()">Отказаться</button>
</div>-->

</div>
