<div class="smodal-content w-480">
    <div class="modal-header">
       <div (click)="Cancel()" class="modal-close"><svg class="icon"><use xlink:href="/assets/img/svg-sprite.svg#times-light"></use></svg></div>
    </div>   
    <div class="text-center">
        <div>
            <div class="font-alt text-xl">Поздравляем,<br />
            Ваш Лот успешно активирован</div>
            <div class="divider-sm"></div>
            <p class="text-sm">Лот № {{data["id"]}} активирован в вашем личном кабинете.<br />
            Теперь Лот может полноценно участвовать в<br />
            сделках на площадке!</p>
        </div>
        <div class="df justify-center">
            <div class="w-300">
                <button type="button" class="btn h-50 mt3 w-full" (click)="Accept()">Перейти к лоту</button>
                <button type="button" class="btn h-50 btn--dark-outline w-full" (click)="Cancel()">Назад в личный кабинет</button>
            </div>
        </div>        
    </div>
</div>
