<div class="filter df aic jcsb mb4">

  <div class="filter-components ui accordion">
    <button type="button" class="title btn filter__btn mr3" data-position="bottom center"
      [attr.data-tooltip]="'Filter' | translate">
      <svg class="ic ic-filter mr05">
        <use xlink:href="/assets/img/sprite.svg#filter"></use>
      </svg>
      {{'Filter'|translate}}
    </button>
    <div class="content">
      <form class="filter-form" (ngSubmit)="onSubmit()">
        <div class="filter-form__search mb15">
          <div class="df aic mb15">
            <span class="fz18 ff:CormorantInfant">{{'Surname'|translate}}</span>
          </div>
          <div class="pr">
            <input type="search" [(ngModel)]="request.surname" [ngModelOptions]="{standalone: true}"
              class="form__input h4" placeholder="{{'Ivanov'|translate}}">
          </div>
        </div>
        <div class="filter-form__search mb15">
          <div class="df aic mb15">
            <span class="fz18 ff:CormorantInfant">{{'Name'|translate}}</span>
          </div>
          <div class="pr">
            <input type="search" [(ngModel)]="request.name" [ngModelOptions]="{standalone: true}" class="form__input h4"
              placeholder="{{'Ivan'|translate}}">
          </div>
        </div>
        <div class="filter-form__search mb15">
          <div class="df aic mb15">
            <span class="fz18 ff:CormorantInfant">{{'Patronymic name'|translate}}</span>
          </div>
          <div class="pr">
            <input type="search" [(ngModel)]="request.patronymic" [ngModelOptions]="{standalone: true}"
              class="form__input h4" placeholder="{{'Ivanovich'|translate}}">
          </div>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__search mb15 df aic">
          <div class="df aic jcsb w100">
            <span class="fz18 ff:CormorantInfant">{{'From_(date)'|translate}}</span>
            <div class="pr">
              <input style="width: 22rem;" type="date" [(ngModel)]="request.fromDate"
                [ngModelOptions]="{standalone: true}" class="form__input h4">
            </div>
          </div>
        </div>
        <div class="filter-form__search mb15 df aic">
          <div class="df aic jcsb w100">
            <span class="fz18 ff:CormorantInfant">{{'To_(date)'|translate}}</span>
            <div class="pr">
              <input style="width: 22rem;" type="date" [(ngModel)]="request.toDate"
                [ngModelOptions]="{standalone: true}" class="form__input h4">
            </div>
          </div>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__check">
          <div class="df aic mb15">
            <span class="fz18 ff:CormorantInfant">{{'User type'|translate}}: </span>
          </div>
          <div class="mcard__title title">
            <svg class="ic ic-card-arrow ic-round">
              <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
            </svg>
          </div>
          <div class="content ui accordion">
            <div class="">
              <div class="form__item mb1" *ngFor="let role of roles">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" [(ngModel)]="role.selected"
                      (ngModelChange)="onTypesChange()" [ngModelOptions]="{standalone: true}">
                    <span class="ch__icon"></span>
                    <span class="ch__tx">{{role.name|translate}}</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" [(ngModel)]="rolesAllSelected"
                      (ngModelChange)="onChangeAllTypes()" [ngModelOptions]="{standalone: true}">
                    <span class="ch__icon"></span>
                    <span class="ch__tx">{{'All' | translate}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__btns df aic jcsb">
          <button type="submit" class="btn">{{'Apply'|translate}}</button>
          <button type="button" class="btn btn_wt">{{'Reset'|translate}}</button>
        </div>
      </form>
    </div>
  </div>

  <div class="df aitc">
    <a href="javascript:;" class="df aic mr15 sort__btn" (click)="toggleSortWay()"
      [class.active]="request.sortWay == ''">
      <svg class="ic ic-sort mr05" data-position="top center"
        [attr.data-tooltip]="(request.sortWay == '' ? 'Ascending' : 'Descending') | translate">
        <use xlink:href="/assets/img/sprite.svg#sort"></use>
      </svg>
      <span class="fz18 ff:CormorantInfant" data-position="top center" [attr.data-tooltip]="'Sort' | translate">
        {{'Sort' | translate}}
      </span>
    </a>
    <select class="ui selection dropdown ddown filter__dropdown" [(ngModel)]="request.sortBy"
      (ngModelChange)="onSortChange()">
      <option value="name">{{'By name' | translate}}</option>
      <option value="date">{{'By registration date' | translate}}</option>
      <option value="role">{{'By type' | translate}}</option>
    </select>
  </div>

</div>