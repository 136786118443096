<div *ngIf="securityContext.topFiveRequisitions == 'isDisplayed'" class="offers offers-bottom">
  <div class="offers__container">



    <div class="offers-body" [class.opened]="bodyOpened">
      <div class="md">
        <div class="offers-body__wrap ">
          <ng-container *ngIf="top5?.sale?.length">


              <div class="offers-header2">
                  <div class="offers-header__nav0">{{ 'TOP-5 for sale' | translate }}</div>
                <div class="offers-header__nav">
                  <div class="offers-header__item offers-header__item_title2">
                    <div>{{ 'Package Category' | translate }}</div>
                  </div>
                  <div class="offers-header__item">
                    <div class="offers-header__icons">
                      <svg class="offers-header__icon offers-header__icon-diamant" width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      
                          <path d="M13.8545 1.84689C13.785 1.69732 13.6215 1.6001 13.4405 1.6001H4.92866C4.76827 1.6001 4.62043 1.6765 4.54027 1.80091C4.4601 1.92531 4.46052 2.0777 4.54163 2.20168L7.67757 7.00162C7.74745 7.1084 7.86888 7.18082 8.00685 7.1968C8.02612 7.19882 8.04538 7.19999 8.06418 7.19999C8.18246 7.19999 8.2967 7.15841 8.38138 7.0828L13.7573 2.28287C13.8854 2.1685 13.9239 1.99647 13.8545 1.84689ZM8.15826 6.15043L5.70817 2.4001H12.3586L8.15826 6.15043Z" />
                          <path d="M26.776 6.5441L22.2961 1.74416C22.211 1.65337 22.0851 1.60059 21.952 1.60059H4.9282C4.79517 1.60059 4.66928 1.65299 4.58412 1.74416L0.104203 6.5441C-0.00735823 6.6633 -0.0315604 6.82928 0.0423586 6.96967C0.116278 7.11006 0.274405 7.20006 0.448231 7.20006H26.4319C26.6057 7.20006 26.7638 7.11006 26.8377 6.96967C26.9113 6.8297 26.8876 6.66367 26.776 6.5441ZM1.40477 6.4001L5.13789 2.40017H21.7419L25.475 6.4001H1.40477Z" />
                        <path d="M26.8327 6.6227C26.7574 6.48629 26.6016 6.3999 26.4313 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919594 7.04269L13.0838 22.2425C13.1685 22.3417 13.3002 22.4001 13.44 22.4001C13.5797 22.4001 13.7114 22.3417 13.7952 22.2425L26.7871 7.04269C26.8905 6.9219 26.908 6.7591 26.8327 6.6227ZM13.44 21.3409L1.35352 7.20033H25.5264L13.44 21.3409Z" />
                        <path d="M13.8669 21.8793L8.49101 6.67951C8.43189 6.5131 8.25938 6.3999 8.06361 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919595 7.04269L13.0838 22.2425C13.1707 22.3445 13.3038 22.4001 13.44 22.4001C13.5098 22.4001 13.5806 22.3857 13.646 22.3545C13.8392 22.2653 13.9323 22.0645 13.8669 21.8793ZM1.3531 7.20033H7.73523L12.2402 19.9378L1.3531 7.20033Z" />
                        <path d="M22.3406 1.80091C22.2608 1.6765 22.1126 1.6001 21.9522 1.6001H13.4403C13.2593 1.6001 13.0958 1.69732 13.0263 1.84689C12.9569 1.99647 12.9954 2.1685 13.1236 2.28287L18.4995 7.0828C18.5837 7.15841 18.6979 7.19999 18.8162 7.19999C18.8355 7.19999 18.8547 7.19877 18.874 7.1968C19.0116 7.18082 19.1334 7.10882 19.2033 7.00162L22.3392 2.20168C22.4199 2.0777 22.4203 1.92531 22.3406 1.80091ZM18.7221 6.15043L14.5217 2.4001H21.1722L18.7221 6.15043Z" />
                        <path d="M26.8336 6.62318C26.7583 6.48678 26.6025 6.40039 26.4322 6.40039H18.8163C18.6205 6.40039 18.4476 6.51359 18.3889 6.68L13.013 21.8798C12.9476 22.0646 13.0407 22.2654 13.2338 22.355C13.2992 22.3854 13.37 22.3998 13.4399 22.3998C13.5757 22.3998 13.7087 22.3442 13.796 22.243L26.7879 7.04323C26.8914 6.92234 26.9088 6.75954 26.8336 6.62318ZM14.6392 19.9374L19.1443 7.19993H25.5264L14.6392 19.9374Z" />
        
                      </svg>
                      
                    </div>
                    <span class="offers-header__tx">{{ 'brilliant' | translate }}</span>
                  </div>
                  <div class="offers-header__item">
                    <div class="offers-header__icons">
                      <svg class="offers-header__icon offers-header__icon-platinum" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                        <path d="M16.0509 16.3V7.16C16.0509 5.82 16.3709 5.74 17.6909 5.74C19.4109 5.74 20.7509 6.96 20.7509 9.12C20.7509 11.42 19.2509 12.48 17.6109 12.48C17.3909 12.48 17.1309 12.46 16.8909 12.4C16.8109 12.38 16.7509 12.64 16.8509 12.66C17.1509 12.74 17.4909 12.76 17.8309 12.76C19.8509 12.76 22.2509 11.52 22.2309 8.72C22.2109 6.4 20.4509 5.44 18.1909 5.44C17.1509 5.44 16.0709 5.56 15.3909 5.56C14.5109 5.56 13.7109 5.5 13.0509 5.5C12.9909 5.5 12.9909 5.74 13.0509 5.74C14.5109 5.74 14.7709 5.98 14.7709 7.12V16.38C14.7709 17.54 14.5109 17.76 13.0509 17.76C12.9909 17.76 12.9909 18 13.0509 18C13.7109 18 14.5309 17.96 15.4109 17.96C16.5909 17.96 17.5709 18 18.4309 18C18.5109 18 18.5109 17.76 18.4309 17.76C16.3709 17.76 16.0509 17.5 16.0509 16.3ZM29.2313 17C28.8513 17.28 28.3112 17.54 27.6113 17.54C26.5913 17.54 26.0313 17.04 26.0313 15.92V10.94C26.9713 10.96 27.8113 11.02 28.7313 11.26C28.8313 11.28 28.9513 10.98 28.9513 10.76C28.9513 10.5 28.8713 10.32 28.8113 10.32C27.8113 10.42 26.9513 10.48 26.0313 10.5V9.02C26.0313 8.92 25.7313 8.92 25.7313 9.02C25.6313 9.92 24.9313 10.52 23.8513 10.52C23.7513 10.52 23.7513 10.8 23.8513 10.8C24.7513 10.8 24.8713 10.94 24.8713 11.9V16.6C24.8713 17.86 25.8113 18.24 26.7913 18.24C27.6513 18.24 28.3713 17.94 29.3513 17.18C29.4513 17.1 29.3113 16.92 29.2313 17Z" />
                      </svg>
                      
                  </div>
                    <span class="offers-header__tx">{{ 'platinum' | translate }}</span>
                  </div>
                  <div class="offers-header__item">
                    <div class="offers-header__icons">
                      <svg class="offers-header__icon offers-header__icon-gold" width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                        <path d="M23.0069 17.76C22.1669 17.76 21.6669 17.26 20.7269 15.26L16.0069 5.32C15.9869 5.24 15.7669 5.26 15.7469 5.32L11.5269 15.28C10.6869 17.26 10.1469 17.76 9.06688 17.76C8.94687 17.76 8.94687 18 9.06688 18C9.88688 18 10.3069 17.92 11.2669 17.92C12.3069 17.92 12.8269 18 13.6269 18C13.7469 18 13.7469 17.76 13.6269 17.76C11.8669 17.76 11.2469 17.1 11.9469 15.46L13.6669 11.38H17.4269L19.7469 16.28C20.2869 17.44 20.0069 17.76 18.5869 17.76C18.4669 17.76 18.4669 18 18.5869 18C19.3269 18 19.8669 17.92 20.7069 17.92C21.4269 17.92 22.2669 18 23.0069 18C23.1269 18 23.1269 17.76 23.0069 17.76ZM13.8469 10.96L15.4469 7.18L17.2269 10.96H13.8469ZM32.7898 16.86C32.3298 17.04 31.9898 17.14 31.7298 17.14C31.2098 17.14 31.0298 16.76 31.0298 15.86V10.26C31.0298 10.18 30.9098 10.1 30.8498 10.1C30.8098 10.1 30.8098 10.1 30.7698 10.12L28.5498 11.12C28.4298 11.16 28.5298 11.4 28.6298 11.36C28.9298 11.22 29.1498 11.16 29.3298 11.16C29.7698 11.16 29.9098 11.6 29.9098 12.64V16.12C29.1898 16.9 28.2498 17.36 27.4098 17.36C26.2898 17.36 25.7298 16.62 25.7298 15.04V10.26C25.7298 10.18 25.5898 10.1 25.5298 10.1L25.4498 10.12L23.2298 11.12C23.1298 11.16 23.2098 11.4 23.3298 11.36C23.6098 11.22 23.8298 11.16 24.0098 11.16C24.4498 11.16 24.6098 11.6 24.6098 12.64V15.72C24.6098 17.32 25.4098 18.16 26.7498 18.16C27.8298 18.16 28.8498 17.64 29.9098 16.46V16.54C29.9098 17.62 30.2698 18.2 30.4698 18.2C30.4898 18.2 30.5098 18.2 30.5698 18.18L32.8898 17.08C33.0098 17.02 32.9098 16.82 32.7898 16.86Z" />
                      </svg>
                      
                                  </div>
                    <span class="offers-header__tx">{{ 'gold' | translate }}</span>
                  </div>
                   <div class="offers-header__item">
                    <div class="offers-header__icons">
                      <svg class="offers-header__icon offers-header__icon-silver" width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                        <path d="M25.2803 17.76C24.4403 17.76 23.9403 17.26 23.0003 15.26L18.2803 5.32C18.2603 5.24 18.0403 5.26 18.0203 5.32L13.8003 15.28C12.9603 17.26 12.4203 17.76 11.3403 17.76C11.2203 17.76 11.2203 18 11.3403 18C12.1603 18 12.5803 17.92 13.5403 17.92C14.5803 17.92 15.1003 18 15.9003 18C16.0203 18 16.0203 17.76 15.9003 17.76C14.1403 17.76 13.5203 17.1 14.2203 15.46L15.9403 11.38H19.7003L22.0203 16.28C22.5603 17.44 22.2803 17.76 20.8603 17.76C20.7403 17.76 20.7403 18 20.8603 18C21.6003 18 22.1403 17.92 22.9803 17.92C23.7003 17.92 24.5403 18 25.2803 18C25.4003 18 25.4003 17.76 25.2803 17.76ZM16.1203 10.96L17.7203 7.18L19.5003 10.96H16.1203ZM30.9233 10.02C28.4233 10.02 26.0833 11.74 26.0833 14.76C26.0833 17.02 27.4033 18.26 29.0233 18.26C30.3033 18.26 31.3433 17.4 32.1033 16.62V19.88C32.1033 22.68 31.0833 23.2 30.0033 23.2C27.9633 23.2 28.1633 21.46 27.1433 21.46C26.8433 21.46 26.5233 21.62 26.5233 22.12C26.5233 22.96 27.6033 23.62 29.2433 23.62C31.7833 23.62 33.2433 21.92 33.2433 19.4V10.52C32.5433 10.22 31.7433 10.02 30.9233 10.02ZM29.6833 17.6C28.2433 17.6 27.4033 16.18 27.4033 14.18C27.4033 11.98 28.4033 10.42 30.2033 10.42C30.9433 10.42 31.5833 10.72 32.1033 11.36V16.3C31.4233 16.96 30.5833 17.6 29.6833 17.6Z" />
                      </svg>
                      
                       </div>
                    <span class="offers-header__tx">{{ 'silver' | translate }}</span>
                  </div>
                  <div class="offers-header__item">
                    <div class="offers-header__icons">
                      <svg class="offers-header__icon offers-header__icon-bronze" width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" fill="white" />
                        <path d="M20.9873 17.76C20.1473 17.76 19.6473 17.26 18.7073 15.26L13.9873 5.32C13.9673 5.24 13.7473 5.26 13.7273 5.32L9.50734 15.28C8.66734 17.26 8.12734 17.76 7.04734 17.76C6.92734 17.76 6.92734 18 7.04734 18C7.86734 18 8.28734 17.92 9.24734 17.92C10.2873 17.92 10.8073 18 11.6073 18C11.7273 18 11.7273 17.76 11.6073 17.76C9.84734 17.76 9.22734 17.1 9.92734 15.46L11.6473 11.38H15.4073L17.7273 16.28C18.2673 17.44 17.9873 17.76 16.5673 17.76C16.4473 17.76 16.4473 18 16.5673 18C17.3073 18 17.8473 17.92 18.6873 17.92C19.4073 17.92 20.2473 18 20.9873 18C21.1073 18 21.1073 17.76 20.9873 17.76ZM11.8273 10.96L13.4273 7.18L15.2073 10.96H11.8273ZM28.3303 16.88C27.6903 17.32 26.9503 17.54 26.2503 17.54C24.2703 17.54 23.1103 15.9 23.1103 13.62C23.1103 13.24 23.1503 12.9 23.2103 12.58L28.1303 12.56C28.3303 12.56 28.3703 12.38 28.3703 12.08C28.3703 10.86 27.5503 10.1 26.0903 10.1C23.6503 10.1 21.7903 12 21.7903 14.4C21.7903 16.38 23.0903 18.24 25.5503 18.24C26.5703 18.24 27.5103 17.92 28.4703 17.06C28.5303 17 28.3903 16.82 28.3303 16.88ZM25.4103 10.5C26.3503 10.5 26.9703 11.12 26.9903 12.22L23.2703 12.3C23.5703 11.16 24.3103 10.5 25.4103 10.5ZM31.2148 11.5C31.2148 10.76 31.6748 10.44 32.3148 10.44C33.2548 10.44 34.4148 11.14 34.4148 12.02C34.4148 12.08 34.6348 12.08 34.6348 12.02C34.6348 11.46 34.6948 10.98 34.6948 10.56C34.6948 10.46 34.6548 10.4 34.4948 10.34C33.9948 10.12 33.5348 10.04 33.0348 10.04C31.6948 10.04 30.3148 10.72 30.3148 12.26C30.3148 13.6 31.3148 14.08 32.2748 14.56C33.1948 15 34.0948 15.44 34.1348 16.58C34.1748 17.42 33.6748 17.84 32.8348 17.84C31.7548 17.84 30.8948 17.14 30.3948 15.8C30.3748 15.72 30.1748 15.76 30.1748 15.82L30.2148 17.38C30.2148 17.5 30.2548 17.56 30.3348 17.62C30.9948 18.08 31.6148 18.22 32.2548 18.22C34.0748 18.22 35.0948 17.06 35.0948 15.8C35.0948 14.5 34.0348 13.98 33.0348 13.48C32.1148 13.02 31.2148 12.56 31.2148 11.5Z" fill="white" />
                      </svg>
                      
                    </div>
                                <span class="offers-header__tx">{{ 'bronze' | translate }}</span>
                  </div>
                </div>
              </div>


            <div class="offers-body__list">
              <div class="offers-body__item offers-body__item_desc">
                <div class="offers-body__desc">
                  <span class="offers-body__desc-tx">{{ 'Defendant (Club)' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'Amount of claims' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'Purchase price' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'validity' | translate }}</span>
                </div>
              </div>
              <div #offer class="offers-body__item" *ngFor="let color of colors; index as i">
                <div class="offers-item" [ngClass]="color" *ngIf="top5.sale[i]">
                  <a routerLink="/clubs/{{top5.sale[i].clubId}}" class="offers-item__texts">
                    <div class="offers-item__line">
                      <div class="ellipsis" attr.data-tooltip="{{ top5.sale[i].clubName }}">
                        {{ top5.sale[i].clubName }}
                      </div>
                    </div>
                    <div class="offers-item__line">{{ top5.sale[i].claimsAmount }}</div>
                    <div class="offers-item__line">{{ top5.sale[i].price }}</div>
                    <div class="offers-item__line">{{ top5.sale[i].stopDate }}</div>
                  </a>
                  <div class="offers-item__link">
                    <a href="publications/{{top5.sale[i].publicationId}}" class="btn" attr.data-tooltip="{{ 'More details' | translate }}" data-position="bottom center">
                      {{ 'More details' | translate }}
                    </a>
                    <!-- <button type="button" (click)="buy(top5.sale[i])" [disabled]="bw.ts.dealBlocked(top5.sale[i].id)" class="btn" attr.data-tooltip="{{ 'Buy' | translate }}" data-position="bottom center">
                      {{ 'Buy' | translate }}
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="top5?.purchase?.length">


            <div class="offers-header2">
              <div class="offers-header__nav0">{{ 'TOP-5 to buy' | translate }}</div>
            <div class="offers-header__nav">
              <div class="offers-header__item offers-header__item_title2">
                <div>{{ 'Requisition Category' | translate }}</div>
              </div>
              <div class="offers-header__item">
                <div class="offers-header__icons">
                  <svg class="offers-header__icon offers-header__icon-diamant" width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  
                      <path d="M13.8545 1.84689C13.785 1.69732 13.6215 1.6001 13.4405 1.6001H4.92866C4.76827 1.6001 4.62043 1.6765 4.54027 1.80091C4.4601 1.92531 4.46052 2.0777 4.54163 2.20168L7.67757 7.00162C7.74745 7.1084 7.86888 7.18082 8.00685 7.1968C8.02612 7.19882 8.04538 7.19999 8.06418 7.19999C8.18246 7.19999 8.2967 7.15841 8.38138 7.0828L13.7573 2.28287C13.8854 2.1685 13.9239 1.99647 13.8545 1.84689ZM8.15826 6.15043L5.70817 2.4001H12.3586L8.15826 6.15043Z" />
                      <path d="M26.776 6.5441L22.2961 1.74416C22.211 1.65337 22.0851 1.60059 21.952 1.60059H4.9282C4.79517 1.60059 4.66928 1.65299 4.58412 1.74416L0.104203 6.5441C-0.00735823 6.6633 -0.0315604 6.82928 0.0423586 6.96967C0.116278 7.11006 0.274405 7.20006 0.448231 7.20006H26.4319C26.6057 7.20006 26.7638 7.11006 26.8377 6.96967C26.9113 6.8297 26.8876 6.66367 26.776 6.5441ZM1.40477 6.4001L5.13789 2.40017H21.7419L25.475 6.4001H1.40477Z" />
                    <path d="M26.8327 6.6227C26.7574 6.48629 26.6016 6.3999 26.4313 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919594 7.04269L13.0838 22.2425C13.1685 22.3417 13.3002 22.4001 13.44 22.4001C13.5797 22.4001 13.7114 22.3417 13.7952 22.2425L26.7871 7.04269C26.8905 6.9219 26.908 6.7591 26.8327 6.6227ZM13.44 21.3409L1.35352 7.20033H25.5264L13.44 21.3409Z" />
                    <path d="M13.8669 21.8793L8.49101 6.67951C8.43189 6.5131 8.25938 6.3999 8.06361 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919595 7.04269L13.0838 22.2425C13.1707 22.3445 13.3038 22.4001 13.44 22.4001C13.5098 22.4001 13.5806 22.3857 13.646 22.3545C13.8392 22.2653 13.9323 22.0645 13.8669 21.8793ZM1.3531 7.20033H7.73523L12.2402 19.9378L1.3531 7.20033Z" />
                    <path d="M22.3406 1.80091C22.2608 1.6765 22.1126 1.6001 21.9522 1.6001H13.4403C13.2593 1.6001 13.0958 1.69732 13.0263 1.84689C12.9569 1.99647 12.9954 2.1685 13.1236 2.28287L18.4995 7.0828C18.5837 7.15841 18.6979 7.19999 18.8162 7.19999C18.8355 7.19999 18.8547 7.19877 18.874 7.1968C19.0116 7.18082 19.1334 7.10882 19.2033 7.00162L22.3392 2.20168C22.4199 2.0777 22.4203 1.92531 22.3406 1.80091ZM18.7221 6.15043L14.5217 2.4001H21.1722L18.7221 6.15043Z" />
                    <path d="M26.8336 6.62318C26.7583 6.48678 26.6025 6.40039 26.4322 6.40039H18.8163C18.6205 6.40039 18.4476 6.51359 18.3889 6.68L13.013 21.8798C12.9476 22.0646 13.0407 22.2654 13.2338 22.355C13.2992 22.3854 13.37 22.3998 13.4399 22.3998C13.5757 22.3998 13.7087 22.3442 13.796 22.243L26.7879 7.04323C26.8914 6.92234 26.9088 6.75954 26.8336 6.62318ZM14.6392 19.9374L19.1443 7.19993H25.5264L14.6392 19.9374Z" />
    
                  </svg>
                  
                </div>
                <span class="offers-header__tx">{{ 'brilliant' | translate }}</span>
              </div>
              <div class="offers-header__item">
                <div class="offers-header__icons">
                  <svg class="offers-header__icon offers-header__icon-platinum" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                    <path d="M16.0509 16.3V7.16C16.0509 5.82 16.3709 5.74 17.6909 5.74C19.4109 5.74 20.7509 6.96 20.7509 9.12C20.7509 11.42 19.2509 12.48 17.6109 12.48C17.3909 12.48 17.1309 12.46 16.8909 12.4C16.8109 12.38 16.7509 12.64 16.8509 12.66C17.1509 12.74 17.4909 12.76 17.8309 12.76C19.8509 12.76 22.2509 11.52 22.2309 8.72C22.2109 6.4 20.4509 5.44 18.1909 5.44C17.1509 5.44 16.0709 5.56 15.3909 5.56C14.5109 5.56 13.7109 5.5 13.0509 5.5C12.9909 5.5 12.9909 5.74 13.0509 5.74C14.5109 5.74 14.7709 5.98 14.7709 7.12V16.38C14.7709 17.54 14.5109 17.76 13.0509 17.76C12.9909 17.76 12.9909 18 13.0509 18C13.7109 18 14.5309 17.96 15.4109 17.96C16.5909 17.96 17.5709 18 18.4309 18C18.5109 18 18.5109 17.76 18.4309 17.76C16.3709 17.76 16.0509 17.5 16.0509 16.3ZM29.2313 17C28.8513 17.28 28.3112 17.54 27.6113 17.54C26.5913 17.54 26.0313 17.04 26.0313 15.92V10.94C26.9713 10.96 27.8113 11.02 28.7313 11.26C28.8313 11.28 28.9513 10.98 28.9513 10.76C28.9513 10.5 28.8713 10.32 28.8113 10.32C27.8113 10.42 26.9513 10.48 26.0313 10.5V9.02C26.0313 8.92 25.7313 8.92 25.7313 9.02C25.6313 9.92 24.9313 10.52 23.8513 10.52C23.7513 10.52 23.7513 10.8 23.8513 10.8C24.7513 10.8 24.8713 10.94 24.8713 11.9V16.6C24.8713 17.86 25.8113 18.24 26.7913 18.24C27.6513 18.24 28.3713 17.94 29.3513 17.18C29.4513 17.1 29.3113 16.92 29.2313 17Z" />
                  </svg>
                  
              </div>
                <span class="offers-header__tx">{{ 'platinum' | translate }}</span>
              </div>
              <div class="offers-header__item">
                <div class="offers-header__icons">
                  <svg class="offers-header__icon offers-header__icon-gold" width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                    <path d="M23.0069 17.76C22.1669 17.76 21.6669 17.26 20.7269 15.26L16.0069 5.32C15.9869 5.24 15.7669 5.26 15.7469 5.32L11.5269 15.28C10.6869 17.26 10.1469 17.76 9.06688 17.76C8.94687 17.76 8.94687 18 9.06688 18C9.88688 18 10.3069 17.92 11.2669 17.92C12.3069 17.92 12.8269 18 13.6269 18C13.7469 18 13.7469 17.76 13.6269 17.76C11.8669 17.76 11.2469 17.1 11.9469 15.46L13.6669 11.38H17.4269L19.7469 16.28C20.2869 17.44 20.0069 17.76 18.5869 17.76C18.4669 17.76 18.4669 18 18.5869 18C19.3269 18 19.8669 17.92 20.7069 17.92C21.4269 17.92 22.2669 18 23.0069 18C23.1269 18 23.1269 17.76 23.0069 17.76ZM13.8469 10.96L15.4469 7.18L17.2269 10.96H13.8469ZM32.7898 16.86C32.3298 17.04 31.9898 17.14 31.7298 17.14C31.2098 17.14 31.0298 16.76 31.0298 15.86V10.26C31.0298 10.18 30.9098 10.1 30.8498 10.1C30.8098 10.1 30.8098 10.1 30.7698 10.12L28.5498 11.12C28.4298 11.16 28.5298 11.4 28.6298 11.36C28.9298 11.22 29.1498 11.16 29.3298 11.16C29.7698 11.16 29.9098 11.6 29.9098 12.64V16.12C29.1898 16.9 28.2498 17.36 27.4098 17.36C26.2898 17.36 25.7298 16.62 25.7298 15.04V10.26C25.7298 10.18 25.5898 10.1 25.5298 10.1L25.4498 10.12L23.2298 11.12C23.1298 11.16 23.2098 11.4 23.3298 11.36C23.6098 11.22 23.8298 11.16 24.0098 11.16C24.4498 11.16 24.6098 11.6 24.6098 12.64V15.72C24.6098 17.32 25.4098 18.16 26.7498 18.16C27.8298 18.16 28.8498 17.64 29.9098 16.46V16.54C29.9098 17.62 30.2698 18.2 30.4698 18.2C30.4898 18.2 30.5098 18.2 30.5698 18.18L32.8898 17.08C33.0098 17.02 32.9098 16.82 32.7898 16.86Z" />
                  </svg>
                  
                              </div>
                <span class="offers-header__tx">{{ 'gold' | translate }}</span>
              </div>
               <div class="offers-header__item">
                <div class="offers-header__icons">
                  <svg class="offers-header__icon offers-header__icon-silver" width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                    <path d="M25.2803 17.76C24.4403 17.76 23.9403 17.26 23.0003 15.26L18.2803 5.32C18.2603 5.24 18.0403 5.26 18.0203 5.32L13.8003 15.28C12.9603 17.26 12.4203 17.76 11.3403 17.76C11.2203 17.76 11.2203 18 11.3403 18C12.1603 18 12.5803 17.92 13.5403 17.92C14.5803 17.92 15.1003 18 15.9003 18C16.0203 18 16.0203 17.76 15.9003 17.76C14.1403 17.76 13.5203 17.1 14.2203 15.46L15.9403 11.38H19.7003L22.0203 16.28C22.5603 17.44 22.2803 17.76 20.8603 17.76C20.7403 17.76 20.7403 18 20.8603 18C21.6003 18 22.1403 17.92 22.9803 17.92C23.7003 17.92 24.5403 18 25.2803 18C25.4003 18 25.4003 17.76 25.2803 17.76ZM16.1203 10.96L17.7203 7.18L19.5003 10.96H16.1203ZM30.9233 10.02C28.4233 10.02 26.0833 11.74 26.0833 14.76C26.0833 17.02 27.4033 18.26 29.0233 18.26C30.3033 18.26 31.3433 17.4 32.1033 16.62V19.88C32.1033 22.68 31.0833 23.2 30.0033 23.2C27.9633 23.2 28.1633 21.46 27.1433 21.46C26.8433 21.46 26.5233 21.62 26.5233 22.12C26.5233 22.96 27.6033 23.62 29.2433 23.62C31.7833 23.62 33.2433 21.92 33.2433 19.4V10.52C32.5433 10.22 31.7433 10.02 30.9233 10.02ZM29.6833 17.6C28.2433 17.6 27.4033 16.18 27.4033 14.18C27.4033 11.98 28.4033 10.42 30.2033 10.42C30.9433 10.42 31.5833 10.72 32.1033 11.36V16.3C31.4233 16.96 30.5833 17.6 29.6833 17.6Z" />
                  </svg>
                  
                   </div>
                <span class="offers-header__tx">{{ 'silver' | translate }}</span>
              </div>
              <div class="offers-header__item">
                <div class="offers-header__icons">
                  <svg class="offers-header__icon offers-header__icon-bronze" width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" fill="white" />
                    <path d="M20.9873 17.76C20.1473 17.76 19.6473 17.26 18.7073 15.26L13.9873 5.32C13.9673 5.24 13.7473 5.26 13.7273 5.32L9.50734 15.28C8.66734 17.26 8.12734 17.76 7.04734 17.76C6.92734 17.76 6.92734 18 7.04734 18C7.86734 18 8.28734 17.92 9.24734 17.92C10.2873 17.92 10.8073 18 11.6073 18C11.7273 18 11.7273 17.76 11.6073 17.76C9.84734 17.76 9.22734 17.1 9.92734 15.46L11.6473 11.38H15.4073L17.7273 16.28C18.2673 17.44 17.9873 17.76 16.5673 17.76C16.4473 17.76 16.4473 18 16.5673 18C17.3073 18 17.8473 17.92 18.6873 17.92C19.4073 17.92 20.2473 18 20.9873 18C21.1073 18 21.1073 17.76 20.9873 17.76ZM11.8273 10.96L13.4273 7.18L15.2073 10.96H11.8273ZM28.3303 16.88C27.6903 17.32 26.9503 17.54 26.2503 17.54C24.2703 17.54 23.1103 15.9 23.1103 13.62C23.1103 13.24 23.1503 12.9 23.2103 12.58L28.1303 12.56C28.3303 12.56 28.3703 12.38 28.3703 12.08C28.3703 10.86 27.5503 10.1 26.0903 10.1C23.6503 10.1 21.7903 12 21.7903 14.4C21.7903 16.38 23.0903 18.24 25.5503 18.24C26.5703 18.24 27.5103 17.92 28.4703 17.06C28.5303 17 28.3903 16.82 28.3303 16.88ZM25.4103 10.5C26.3503 10.5 26.9703 11.12 26.9903 12.22L23.2703 12.3C23.5703 11.16 24.3103 10.5 25.4103 10.5ZM31.2148 11.5C31.2148 10.76 31.6748 10.44 32.3148 10.44C33.2548 10.44 34.4148 11.14 34.4148 12.02C34.4148 12.08 34.6348 12.08 34.6348 12.02C34.6348 11.46 34.6948 10.98 34.6948 10.56C34.6948 10.46 34.6548 10.4 34.4948 10.34C33.9948 10.12 33.5348 10.04 33.0348 10.04C31.6948 10.04 30.3148 10.72 30.3148 12.26C30.3148 13.6 31.3148 14.08 32.2748 14.56C33.1948 15 34.0948 15.44 34.1348 16.58C34.1748 17.42 33.6748 17.84 32.8348 17.84C31.7548 17.84 30.8948 17.14 30.3948 15.8C30.3748 15.72 30.1748 15.76 30.1748 15.82L30.2148 17.38C30.2148 17.5 30.2548 17.56 30.3348 17.62C30.9948 18.08 31.6148 18.22 32.2548 18.22C34.0748 18.22 35.0948 17.06 35.0948 15.8C35.0948 14.5 34.0348 13.98 33.0348 13.48C32.1148 13.02 31.2148 12.56 31.2148 11.5Z" fill="white" />
                  </svg>
                  
                </div>
                            <span class="offers-header__tx">{{ 'bronze' | translate }}</span>
              </div>
            </div>
          </div>


            <div class="offers-body__list">
              <div class="offers-body__item offers-body__item_desc">
                <div class="offers-body__desc">
                  <span class="offers-body__desc-tx">{{ 'Defendant (Club)' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'Amount of claims' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'Purchase price' | translate }}</span>
                  <span class="offers-body__desc-tx">{{ 'validity' | translate }}</span>
                </div>
              </div>
              <div class="offers-body__item" *ngFor="let color of colors; index as i">
                <div class="offers-item" [ngClass]="color" *ngIf="top5.purchase[i]">
                  <a routerLink="/clubs/{{top5.purchase[i].clubId}}" class="offers-item__texts">
                    <div class="offers-item__line">
                      <div class="ellipsis" attr.data-tooltip="{{ top5.purchase[i].clubName }}">
                        {{ top5.purchase[i].clubName }}
                      </div>
                    </div>
                    <div class="offers-item__line">{{ top5.purchase[i].claimsAmount }}</div>
                    <div class="offers-item__line">{{ top5.purchase[i].price }}</div>
                    <div class="offers-item__line">{{ top5.purchase[i].stopDate }}</div>
                  </a>
                  <div class="offers-item__link">
                    <a href="publications/{{top5.purchase[i].publicationId}}" class="btn" attr.data-tooltip="{{ 'More details' | translate }}" data-position="bottom center">
                      {{ 'More details' | translate }}
                    </a>
                    <!-- <a href="#" class="btn" attr.data-tooltip="{{ 'Sell' | translate }}" data-position="bottom center">
                      {{ 'Sell' | translate }}
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="offers-header" *ngIf="top5?.purchase?.length || top5?.sale?.length">
      <div class="offers-header__wrap md">
        <div class="offers-header__nav">
          <div class="offers-header__item offers-header__item_title" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__title">{{ 'bids' | translate }}</div>
          </div>
          <div class="offers-header__item" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__icons">
              <svg class="offers-header__icon offers-header__icon-diamant" width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">

                <path d="M26.776 6.5441L22.2961 1.74416C22.211 1.65337 22.0851 1.60059 21.952 1.60059H4.9282C4.79517 1.60059 4.66928 1.65299 4.58412 1.74416L0.104203 6.5441C-0.00735823 6.6633 -0.0315604 6.82928 0.0423586 6.96967C0.116278 7.11006 0.274405 7.20006 0.448231 7.20006H26.4319C26.6057 7.20006 26.7638 7.11006 26.8377 6.96967C26.9113 6.8297 26.8876 6.66367 26.776 6.5441ZM1.40477 6.4001L5.13789 2.40017H21.7419L25.475 6.4001H1.40477Z" />
                <path d="M13.8545 1.84689C13.785 1.69732 13.6215 1.6001 13.4405 1.6001H4.92866C4.76827 1.6001 4.62043 1.6765 4.54027 1.80091C4.4601 1.92531 4.46052 2.0777 4.54163 2.20168L7.67757 7.00162C7.74745 7.1084 7.86888 7.18082 8.00685 7.1968C8.02612 7.19882 8.04538 7.19999 8.06418 7.19999C8.18246 7.19999 8.2967 7.15841 8.38138 7.0828L13.7573 2.28287C13.8854 2.1685 13.9239 1.99647 13.8545 1.84689ZM8.15826 6.15043L5.70817 2.4001H12.3586L8.15826 6.15043Z" />
                <path d="M26.8327 6.6227C26.7574 6.48629 26.6016 6.3999 26.4313 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919594 7.04269L13.0838 22.2425C13.1685 22.3417 13.3002 22.4001 13.44 22.4001C13.5797 22.4001 13.7114 22.3417 13.7952 22.2425L26.7871 7.04269C26.8905 6.9219 26.908 6.7591 26.8327 6.6227ZM13.44 21.3409L1.35352 7.20033H25.5264L13.44 21.3409Z" />
                <path d="M13.8669 21.8793L8.49101 6.67951C8.43189 6.5131 8.25938 6.3999 8.06361 6.3999H0.447695C0.277439 6.3999 0.121989 6.48629 0.0462851 6.6227C-0.0289989 6.7591 -0.0115166 6.9219 0.0919595 7.04269L13.0838 22.2425C13.1707 22.3445 13.3038 22.4001 13.44 22.4001C13.5098 22.4001 13.5806 22.3857 13.646 22.3545C13.8392 22.2653 13.9323 22.0645 13.8669 21.8793ZM1.3531 7.20033H7.73523L12.2402 19.9378L1.3531 7.20033Z" />
                <path d="M22.3406 1.80091C22.2608 1.6765 22.1126 1.6001 21.9522 1.6001H13.4403C13.2593 1.6001 13.0958 1.69732 13.0263 1.84689C12.9569 1.99647 12.9954 2.1685 13.1236 2.28287L18.4995 7.0828C18.5837 7.15841 18.6979 7.19999 18.8162 7.19999C18.8355 7.19999 18.8547 7.19877 18.874 7.1968C19.0116 7.18082 19.1334 7.10882 19.2033 7.00162L22.3392 2.20168C22.4199 2.0777 22.4203 1.92531 22.3406 1.80091ZM18.7221 6.15043L14.5217 2.4001H21.1722L18.7221 6.15043Z" />
                <path d="M26.8336 6.62318C26.7583 6.48678 26.6025 6.40039 26.4322 6.40039H18.8163C18.6205 6.40039 18.4476 6.51359 18.3889 6.68L13.013 21.8798C12.9476 22.0646 13.0407 22.2654 13.2338 22.355C13.2992 22.3854 13.37 22.3998 13.4399 22.3998C13.5757 22.3998 13.7087 22.3442 13.796 22.243L26.7879 7.04323C26.8914 6.92234 26.9088 6.75954 26.8336 6.62318ZM14.6392 19.9374L19.1443 7.19993H25.5264L14.6392 19.9374Z" />

              </svg>

            </div>
            <span class="offers-header__tx">{{ 'brilliant' | translate }}</span>
          </div>
          <div class="offers-header__item" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__icons">
              <svg class="offers-header__icon offers-header__icon-platinum" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                <path d="M16.0509 16.3V7.16C16.0509 5.82 16.3709 5.74 17.6909 5.74C19.4109 5.74 20.7509 6.96 20.7509 9.12C20.7509 11.42 19.2509 12.48 17.6109 12.48C17.3909 12.48 17.1309 12.46 16.8909 12.4C16.8109 12.38 16.7509 12.64 16.8509 12.66C17.1509 12.74 17.4909 12.76 17.8309 12.76C19.8509 12.76 22.2509 11.52 22.2309 8.72C22.2109 6.4 20.4509 5.44 18.1909 5.44C17.1509 5.44 16.0709 5.56 15.3909 5.56C14.5109 5.56 13.7109 5.5 13.0509 5.5C12.9909 5.5 12.9909 5.74 13.0509 5.74C14.5109 5.74 14.7709 5.98 14.7709 7.12V16.38C14.7709 17.54 14.5109 17.76 13.0509 17.76C12.9909 17.76 12.9909 18 13.0509 18C13.7109 18 14.5309 17.96 15.4109 17.96C16.5909 17.96 17.5709 18 18.4309 18C18.5109 18 18.5109 17.76 18.4309 17.76C16.3709 17.76 16.0509 17.5 16.0509 16.3ZM29.2313 17C28.8513 17.28 28.3112 17.54 27.6113 17.54C26.5913 17.54 26.0313 17.04 26.0313 15.92V10.94C26.9713 10.96 27.8113 11.02 28.7313 11.26C28.8313 11.28 28.9513 10.98 28.9513 10.76C28.9513 10.5 28.8713 10.32 28.8113 10.32C27.8113 10.42 26.9513 10.48 26.0313 10.5V9.02C26.0313 8.92 25.7313 8.92 25.7313 9.02C25.6313 9.92 24.9313 10.52 23.8513 10.52C23.7513 10.52 23.7513 10.8 23.8513 10.8C24.7513 10.8 24.8713 10.94 24.8713 11.9V16.6C24.8713 17.86 25.8113 18.24 26.7913 18.24C27.6513 18.24 28.3713 17.94 29.3513 17.18C29.4513 17.1 29.3113 16.92 29.2313 17Z" />
              </svg>

            </div>
            <span class="offers-header__tx">{{ 'platinum' | translate }}</span>
          </div>
          <div class="offers-header__item" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__icons">
              <svg class="offers-header__icon offers-header__icon-gold" width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                <path d="M23.0069 17.76C22.1669 17.76 21.6669 17.26 20.7269 15.26L16.0069 5.32C15.9869 5.24 15.7669 5.26 15.7469 5.32L11.5269 15.28C10.6869 17.26 10.1469 17.76 9.06688 17.76C8.94687 17.76 8.94687 18 9.06688 18C9.88688 18 10.3069 17.92 11.2669 17.92C12.3069 17.92 12.8269 18 13.6269 18C13.7469 18 13.7469 17.76 13.6269 17.76C11.8669 17.76 11.2469 17.1 11.9469 15.46L13.6669 11.38H17.4269L19.7469 16.28C20.2869 17.44 20.0069 17.76 18.5869 17.76C18.4669 17.76 18.4669 18 18.5869 18C19.3269 18 19.8669 17.92 20.7069 17.92C21.4269 17.92 22.2669 18 23.0069 18C23.1269 18 23.1269 17.76 23.0069 17.76ZM13.8469 10.96L15.4469 7.18L17.2269 10.96H13.8469ZM32.7898 16.86C32.3298 17.04 31.9898 17.14 31.7298 17.14C31.2098 17.14 31.0298 16.76 31.0298 15.86V10.26C31.0298 10.18 30.9098 10.1 30.8498 10.1C30.8098 10.1 30.8098 10.1 30.7698 10.12L28.5498 11.12C28.4298 11.16 28.5298 11.4 28.6298 11.36C28.9298 11.22 29.1498 11.16 29.3298 11.16C29.7698 11.16 29.9098 11.6 29.9098 12.64V16.12C29.1898 16.9 28.2498 17.36 27.4098 17.36C26.2898 17.36 25.7298 16.62 25.7298 15.04V10.26C25.7298 10.18 25.5898 10.1 25.5298 10.1L25.4498 10.12L23.2298 11.12C23.1298 11.16 23.2098 11.4 23.3298 11.36C23.6098 11.22 23.8298 11.16 24.0098 11.16C24.4498 11.16 24.6098 11.6 24.6098 12.64V15.72C24.6098 17.32 25.4098 18.16 26.7498 18.16C27.8298 18.16 28.8498 17.64 29.9098 16.46V16.54C29.9098 17.62 30.2698 18.2 30.4698 18.2C30.4898 18.2 30.5098 18.2 30.5698 18.18L32.8898 17.08C33.0098 17.02 32.9098 16.82 32.7898 16.86Z" />
              </svg>

            </div>
            <span class="offers-header__tx">{{ 'gold' | translate }}</span>
          </div>
          <div class="offers-header__item" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__icons">
              <svg class="offers-header__icon offers-header__icon-silver" width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" />
                <path d="M25.2803 17.76C24.4403 17.76 23.9403 17.26 23.0003 15.26L18.2803 5.32C18.2603 5.24 18.0403 5.26 18.0203 5.32L13.8003 15.28C12.9603 17.26 12.4203 17.76 11.3403 17.76C11.2203 17.76 11.2203 18 11.3403 18C12.1603 18 12.5803 17.92 13.5403 17.92C14.5803 17.92 15.1003 18 15.9003 18C16.0203 18 16.0203 17.76 15.9003 17.76C14.1403 17.76 13.5203 17.1 14.2203 15.46L15.9403 11.38H19.7003L22.0203 16.28C22.5603 17.44 22.2803 17.76 20.8603 17.76C20.7403 17.76 20.7403 18 20.8603 18C21.6003 18 22.1403 17.92 22.9803 17.92C23.7003 17.92 24.5403 18 25.2803 18C25.4003 18 25.4003 17.76 25.2803 17.76ZM16.1203 10.96L17.7203 7.18L19.5003 10.96H16.1203ZM30.9233 10.02C28.4233 10.02 26.0833 11.74 26.0833 14.76C26.0833 17.02 27.4033 18.26 29.0233 18.26C30.3033 18.26 31.3433 17.4 32.1033 16.62V19.88C32.1033 22.68 31.0833 23.2 30.0033 23.2C27.9633 23.2 28.1633 21.46 27.1433 21.46C26.8433 21.46 26.5233 21.62 26.5233 22.12C26.5233 22.96 27.6033 23.62 29.2433 23.62C31.7833 23.62 33.2433 21.92 33.2433 19.4V10.52C32.5433 10.22 31.7433 10.02 30.9233 10.02ZM29.6833 17.6C28.2433 17.6 27.4033 16.18 27.4033 14.18C27.4033 11.98 28.4033 10.42 30.2033 10.42C30.9433 10.42 31.5833 10.72 32.1033 11.36V16.3C31.4233 16.96 30.5833 17.6 29.6833 17.6Z" />
              </svg>

            </div>
            <span class="offers-header__tx">{{ 'silver' | translate }}</span>
          </div>
          <div class="offers-header__item" (click)="withCloseControl ? openBody() : toggleBody()">
            <div class="offers-header__icons">
              <svg class="offers-header__icon offers-header__icon-bronze" width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H23V3H24V1C24 0.447715 23.5523 0 23 0H1C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V21H23V23H1V1Z" fill="white" />
                <path d="M20.9873 17.76C20.1473 17.76 19.6473 17.26 18.7073 15.26L13.9873 5.32C13.9673 5.24 13.7473 5.26 13.7273 5.32L9.50734 15.28C8.66734 17.26 8.12734 17.76 7.04734 17.76C6.92734 17.76 6.92734 18 7.04734 18C7.86734 18 8.28734 17.92 9.24734 17.92C10.2873 17.92 10.8073 18 11.6073 18C11.7273 18 11.7273 17.76 11.6073 17.76C9.84734 17.76 9.22734 17.1 9.92734 15.46L11.6473 11.38H15.4073L17.7273 16.28C18.2673 17.44 17.9873 17.76 16.5673 17.76C16.4473 17.76 16.4473 18 16.5673 18C17.3073 18 17.8473 17.92 18.6873 17.92C19.4073 17.92 20.2473 18 20.9873 18C21.1073 18 21.1073 17.76 20.9873 17.76ZM11.8273 10.96L13.4273 7.18L15.2073 10.96H11.8273ZM28.3303 16.88C27.6903 17.32 26.9503 17.54 26.2503 17.54C24.2703 17.54 23.1103 15.9 23.1103 13.62C23.1103 13.24 23.1503 12.9 23.2103 12.58L28.1303 12.56C28.3303 12.56 28.3703 12.38 28.3703 12.08C28.3703 10.86 27.5503 10.1 26.0903 10.1C23.6503 10.1 21.7903 12 21.7903 14.4C21.7903 16.38 23.0903 18.24 25.5503 18.24C26.5703 18.24 27.5103 17.92 28.4703 17.06C28.5303 17 28.3903 16.82 28.3303 16.88ZM25.4103 10.5C26.3503 10.5 26.9703 11.12 26.9903 12.22L23.2703 12.3C23.5703 11.16 24.3103 10.5 25.4103 10.5ZM31.2148 11.5C31.2148 10.76 31.6748 10.44 32.3148 10.44C33.2548 10.44 34.4148 11.14 34.4148 12.02C34.4148 12.08 34.6348 12.08 34.6348 12.02C34.6348 11.46 34.6948 10.98 34.6948 10.56C34.6948 10.46 34.6548 10.4 34.4948 10.34C33.9948 10.12 33.5348 10.04 33.0348 10.04C31.6948 10.04 30.3148 10.72 30.3148 12.26C30.3148 13.6 31.3148 14.08 32.2748 14.56C33.1948 15 34.0948 15.44 34.1348 16.58C34.1748 17.42 33.6748 17.84 32.8348 17.84C31.7548 17.84 30.8948 17.14 30.3948 15.8C30.3748 15.72 30.1748 15.76 30.1748 15.82L30.2148 17.38C30.2148 17.5 30.2548 17.56 30.3348 17.62C30.9948 18.08 31.6148 18.22 32.2548 18.22C34.0748 18.22 35.0948 17.06 35.0948 15.8C35.0948 14.5 34.0348 13.98 33.0348 13.48C32.1148 13.02 31.2148 12.56 31.2148 11.5Z" fill="white" />
              </svg>

            </div>
            <span class="offers-header__tx">{{ 'bronze' | translate }}</span>
          </div>

        </div>
        <div class="offers-header__item offers-header__item_close" *ngIf="withCloseControl == true">
          <button type="button" (click)="closeBody()" class="offers-header__close js-offers-header-close"> 
            <span></span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="offers-bottom-end"></div>