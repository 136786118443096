<div style="padding: 20rem 20rem 15rem">

  <h1 class="title ttu">Тест модальных окон</h1>

  <ol>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="login">Форма входа</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="registration">Форма регистрации</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="registration-success">Подтверждение регистрации</a>
    </li>

    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="warning">Внимание</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="personal-data">Сбор и обработка персональных данных</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="password-recovery">Восстановление пароля
      </a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="re-password-recovery">Повторить отправку кода</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="new-password">Изменить пароль</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="cookie">Поп-апп куки</a>
    </li>
    <li style="margin: 2rem 0;">
      <a href="javascript:;" data-modal="modal-card">Карточка лота</a>
    </li>
  </ol>
</div>

<div id="aura"></div>