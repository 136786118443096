export class ExpertOpinionsRequest {

  constructor() { }

  page: number = 0;
  limit: number = 100;
  query: string = '';
  onlyActive: boolean = true;
  sortBy: string = 'expertOpinionId';
  sortWay: string = 'asc';
  format: string = '';
}
