import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { Utils } from 'src/app/core/utils/utils';

declare function initDropdowns(): void;
declare function resetRangeSliders(): void;

@Component({
  selector: 'app-publications-filter',
  templateUrl: './publications-filter.component.html',
  styleUrls: ['./publications-filter.component.css']
})
export class PublicationsFilterComponent implements OnInit {
  @Input() request: PublicationsRequest = new PublicationsRequest();
  @Output() requestChanged = new EventEmitter(true);

  userRatings: any[] = [];
  userRatingsAllSelected = false;
  packageRatings: any[] = [];
  packageRatingsAllSelected = false;
  slidersInitialValues: any = {};

  constructor(private utils: Utils) {
    this.userRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false }
    ];
    this.packageRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false }
    ];
  }

  ngOnInit(): void {}

  onSubmit(): void {
    const filterElement = document.querySelector('.filter') as HTMLElement;
    const headerElement = document.querySelector('.header') as HTMLElement;
    const position = filterElement.offsetTop - headerElement.offsetHeight - 32;
    this.utils.scrollToTop(position);
    this.utils.closeAccordion();
    this.requestChanged.emit();
  }

  onUserRatingsChange(): void {
    this.request.userRatings = this.userRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
    this.userRatingsAllSelected = this.userRatings.every((x) => x.selected);
  }

  onPackageRatingsChange(): void {
    this.request.packageRatings = this.packageRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
    this.packageRatingsAllSelected = this.packageRatings.every(
      (x) => x.selected
    );
  }

  onChangeAllUserRatings(): void {
    this.userRatings.map((x) => (x.selected = this.userRatingsAllSelected));
    this.request.userRatings = this.userRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
  }

  onChangeAllPackageRatings(): void {
    this.packageRatings.map(
      (x) => (x.selected = this.packageRatingsAllSelected)
    );
    this.request.packageRatings = this.packageRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
  }

  onResetFilterClick(): void {
    this.userRatings.map((x) => (x.selected = false));
    this.packageRatings.map((x) => (x.selected = false));
    this.request.userRatings = '';
    this.request.packageRatings = '';
    this.request.clubName = '';
    this.request.packageType = ' ';
    this.request.minPrice = this.slidersInitialValues.minPrice;
    this.request.maxPrice = this.slidersInitialValues.maxPrice;
    this.request.minCertPrice = this.slidersInitialValues.minCertPrice;
    this.request.maxCertPrice = this.slidersInitialValues.maxCertPrice;
    this.request.minCertQuantity = this.slidersInitialValues.minCertQuantity;
    this.request.maxCertQuantity = this.slidersInitialValues.maxCertQuantity;
    resetRangeSliders();
    initDropdowns();
  }
}
