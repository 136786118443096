import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { ExpertOpinion } from 'src/app/features/manager-area/models/expert-opinions/expert-opinion.model';
import { ExpertOpinionsRequest } from 'src/app/features/manager-area/models/expert-opinions/expert-opinions-request.model';
import { ExpertOpinionsResponse } from 'src/app/features/manager-area/models/expert-opinions/expert-opinions-response.model';
import { ExpertOpinionsService } from 'src/app/features/manager-area/services/expert-opinions/expert-opinions.service';

export interface SelectOperatorModel {
  data:any|undefined;
}
//declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function initDropdowns(): void;
@Component({
  selector: 'app-just-select-operator-modal',
  templateUrl: './just-select-operator-modal.component.html',
  styleUrls: ['./just-select-operator-modal.component.css']
})
export class JustSelectOperatorModalComponent extends SimpleModalComponent<SelectOperatorModel, number> implements SelectOperatorModel, OnInit, AfterViewInit  {
  data: any = null;
  response: ExpertOpinionsResponse | null = null;
  params: ExpertOpinionsRequest = new ExpertOpinionsRequest();
  entityId: number = 0;
  entity: ExpertOpinion = new ExpertOpinion();
  siteUrl: string | null = null;
  expertOpinionDescription: string | null = null;
  constructor(
    private mainService: ExpertOpinionsService
  ) {
    super()
   }
  async ngAfterViewInit(): Promise<void> {
    // initDatepicker();
  
    initDropdowns();
  }
   async ngOnInit(): Promise<void> {
    await this.getList();
  }


  async getList(page: number = 0): Promise<void> {
    this.params.page = page;
    this.response = await this.mainService.filter(this.params);
  }

  async select(): Promise<void> {
      this.result = this.entityId;
      this.close();
  }

  async cancel() {
    this.result = 0;
    this.close();
  }

  async contactSupport(): Promise<void> {

  }

  async onExpertChange(): Promise<void> {
    this.entity = await this.mainService.get(this.entityId);
    this.siteUrl = this.entity.siteUrl ?? '';
    this.expertOpinionDescription = this.entity.expertOpinionDescription ?? '';
  }
}
