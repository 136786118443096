<div class="smodal-content">
    <!-- <div class="modal-header">
      <div (click)="cancel()" class="modal-close"><svg class="icon"><use xlink:href="/assets/img/svg-sprite.svg#times-light"></use></svg></div>
   </div>  -->
    <div>
        <!-- <div class="font-alt text-xl mb-1">{{'Selecting an expert opinion operator' | translate}}</div> -->
        <div class="form__item">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title font-alt">{{'Choose an expert opinion operator' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Choose an expert opinion operator' | translate"></div>
            </div>
            <select class="ui selection dropdown ddown form-dropdown" required [(ngModel)]="entityId" (ngModelChange)="onExpertChange();">
              <option disabled selected hidden value="">{{'Select'|translate}}</option>
              <option *ngFor="let x of response?.result" [ngValue]="x.expertOpinionId">
                {{x.expertOpinionName}}
              </option>
            </select>
          </div>
          <div class="mb-15">
            <span class="text-lg font-alt font-medium">{{'Site of operator' | translate}}</span> <a href="{{siteUrl}}" target="_blank" class="btn-link btn-link--primary text-underline text-sm">{{entity.siteUrl}}</a>
          </div>
          <div class="mb-25">
            <span class="text-lg font-alt font-medium">{{'Email:' | translate}}</span> <a href="javascript:void(0)" class="btn-link btn-link--primary text-underline text-sm">...</a>
          </div>
          <div class="mih-150 mb-40">
            <div class="mb-08 text-lg font-alt font-medium">{{'Description of operator:' | translate}}</div>
            <div class="mb-10 text-sm color-secondary" [innerHTML]="expertOpinionDescription"></div>
            <!-- <a href="javascript:void(0)" class="text-sm">{{'Read more...' | translate}}</a> -->
          </div>
          <div class="d-flex flex-column align-center">
            <button type="button" class="btn btn--primary w-375 maw-100p mb-20" (click)="select()">{{'Submit a request for an expert opinion' | translate}}</button>
            <button type="button" class="btn btn--dark-outline w-375 maw-100p mb-20" (click)="cancel()">{{'Cancel adding ccertificate' | translate}}</button>
            <button type="button" class="btn btn--dark-outline w-375 maw-100p" (click)="contactSupport()">{{'Contact with support' | translate}}</button>
          </div>
</div>
