<div class="mcards__list" *ngIf="mode == 10000" data-attr="shared-area">
  <div class="mcards__item" *ngFor="let item of list">
    <div class="pdf-container">
      <div
        class="hidden pdf-add pb2 mb5 w100"
        style="border-bottom: 1px solid #235"
      >
        <img src="/assets/img/logo-all_dark.svg" alt="" />
        <div class="ff:CormorantInfant fz18 mt1">www.tst-trade.ru</div>
      </div>
      <div class="mcard-mini ui accordion mcard12 pdf-shrink">
        <div class="mcard-mini__content">
          <div class="mcard-mini__head">
            <div class="mcard-mini__picture">
              <img
                [src]="item.clubPhoto"
                alt=""
                class="img-full"
                width="190"
                height="90"
              />
            </div>
            <div class="mcard-mini__info">
              <div>
                <a
                  class="mcard-mini__pname"
                  routerLink="/public-area/package/{{ item.publicationId }}"
                  fragment="{{ state }}"
                  attr.data-tooltip="{{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'package'
                      : 'Request'
                    ) | translate
                  }} {{ item.publicationNumber }}"
                >
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'package'
                      : 'Request'
                    ) | translate
                  }}
                  {{ item.publicationNumber }}
                </a>
                <div
                  *ngIf="item.publicationTypeCode != 'type_pub_packet'"
                  class="mb-05 text-sm font-alt-extra font-semibold"
                >
                  На покупку Лота по Ответчику
                </div>
              </div>
              <div
                attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
                data-position="top center"
                data-variation="basic"
              >
                <a
                  routerLink="/clubs/{{ item.clubId }}"
                  class="mcard-mini__name mcard-mini__ci"
                >
                  {{ item.clubNameFormatted | translate }}
                </a>
              </div>
              <div class="dl">
                <span class="dl__dt">{{ item.dealTypeName | translate }}</span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.priceFormatted }}</span>
              </div>
            </div>
          </div>
          <div class="mcard-mini__description">
            <div class="mcard-mini__title title">
              <svg class="ic ic-card-arrow ic-round">
                <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
              </svg>
            </div>
            <div class="content">
              <ul class="mcard-mini__list pb15 transition hidden">
                <ng-container *ngIf="item.dealTypeId != 3; else auctionBlock">
                  <li class="dl">
                    <span class="dl__dt">
                      {{ item.dealTypeName | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.priceFormatted }}</span>
                  </li>
                  <li class="dl" *ngIf="item.certCount > 0">
                    <span class="dl__dt">{{ 'certCount' | translate }}</span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.certCount }}</span>
                  </li>
                  <li class="dl" *ngIf="item.claimsAmountFormatted">
                    <span class="dl__dt">
                      {{ 'The amount of the claims' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.claimsAmountFormatted }}</span>
                  </li>
                </ng-container>
                <ng-template #auctionBlock>
                  <li class="dl" *ngIf="item.startingPriceFormatted">
                    <span class="dl__dt">
                      {{ 'Auction starting price' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">
                      {{ item.startingPriceFormatted }}
                    </span>
                  </li>
                  <li class="dl">
                    <span class="dl__dt">
                      {{ 'Redemption price' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.priceFormatted }}</span>
                  </li>
                </ng-template>
                <li class="dl" *ngIf="item.packageType">
                  <span class="dl__dt">{{ 'packageType' | translate }}</span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.packageType | translate }}</span>
                </li>
                <!-- <li class="dl" *ngIf="item.startDateFormatted">
    <span class="dl__dt">
      {{ (item.publicationTypeCode == 'type_pub_packet' ? 'Publication date' : (item.publicationTypeCode == 'type_pub_auction' ? 'Auction start' : 'Purchase start')) | translate }}
    </span>
    <span class="dl__points"></span>
    <span class="dl__dd">{{ item.startDateFormatted }}</span>
  </li>
  <li class="dl dl_mod">
    <span class="dl__dt">
      {{ (item.publicationTypeCode == 'type_pub_packet' ? 'Sale ends in' : (item.publicationTypeCode == 'type_pub_auction' ? 'Auction ends in' : 'Requisition expires in')) | translate }}
    </span>
    <span class="dl__points"></span>
    <span class="dl__dd">
      <div *ngIf="(item.endsIn | json) != ({} | json); else elseBlock">
        <span *ngFor="let part of item.endsIn | keyvalue">
          {{part.value}} {{ $any(part.key) | translate }}
        </span>
      </div>
      <ng-template #elseBlock>
        {{ (item.publicationTypeCode == 'type_pub_packet' ? 'Sale finished' : (item.publicationTypeCode == 'type_pub_auction' ? 'Auction finished' : 'Purchase finished')) | translate }}
      </ng-template>
    </span>
  </li> -->
              </ul>
            </div>
          </div>
          <div class="mcard-mini__footer pdf-remove">
            <div class="mcard-mini__nav">
              <div
                data-position="bottom center"
                [attr.data-tooltip]="'packageRating' | translate"
                data-variation="basic"
              >
                <div class="sticker">{{ item.packageRatingName }}</div>
              </div>
              <div
                data-position="bottom center"
                [attr.data-tooltip]="'userRating' | translate"
                data-variation="basic"
              >
                <div class="sticker">{{ item.userRatingName }}</div>
              </div>
              <div
                *ngIf="item.certCount"
                data-position="bottom center"
                [attr.data-tooltip]="'certCount' | translate"
                data-variation="basic"
              >
                <div class="sticker">{{ item.certCount }}</div>
              </div>
            </div>
            <div class="mcard-mini__footer-rt">
              <!-- <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
              [attr.data-tooltip]="'Add' | translate" *ngIf="isAuthorized">
              <svg class="ic ic-card-add ic-round ic-stroke">
                <use xlink:href="/assets/img/sprite.svg#card-add"></use>
              </svg>
            </button>
            <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
              [attr.data-tooltip]="'Edit' | translate" *ngIf="isAuthorized">
              <svg class="ic ic-card-edit ic-round ic-stroke">
                <use xlink:href="/assets/img/sprite.svg#card-edit"></use>
              </svg>
            </button>
            <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
              [attr.data-tooltip]="'Delete' | translate" *ngIf="isAuthorized">
              <svg class="ic ic-card-del ic-round ic-stroke">
                <use xlink:href="/assets/img/sprite.svg#card-del"></use>
              </svg>
            </button> -->
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Download' | translate"
                (click)="download($event, item)"
              >
                <svg class="ic ic-card-download ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-download"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Print' | translate"
                (click)="print($event, item)"
              >
                <svg class="ic ic-card-printer ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
                </svg>
              </button>
              <div
                *ngIf="item.dealTypeId != 3; else openAuction2"
                class="mcard-mini__btn c-link"
                [attr.data-tooltip]="
                  (item.publicationTypeCode != 'type_pub_packet'
                    ? 'Details'
                    : 'doBuy'
                  ) | translate
                "
                data-position="bottom center"
              >
                <button
                  type="button"
                  [disabled]="bw.ts.dealBlocked(item.id)"
                  (click)="doAction(item)"
                  class="btn"
                >
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'doBuy'
                      : 'Details'
                    ) | translate
                  }}
                </button>
              </div>
              <ng-template #openAuction2>
                <div
                  class="mcard-mini__btn c-link"
                  data-position="bottom center"
                  [attr.data-tooltip]="'Auction' | translate"
                >
                  <a routerLink="/publications/{{ item.id }}" class="btn">
                    {{ 'Auction' | translate }}
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mcards__list" *ngIf="mode == 8 || mode == 10">
  <div class="mcards__item" *ngFor="let item of list">
    <div class="pdf-container pdf-shrink">
      <div class="mcard ui accordion">
        <div
          class="hidden pdf-add pb2 mb2 w100"
          style="border-bottom: 1px solid #235"
        >
          <img src="/assets/img/logo-all_dark.svg" alt="" />
          <div class="ff:CormorantInfant fz18 mt1">https://tst-trade.ru</div>
        </div>
        <div class="mcard__picture">
          <img
            [src]="item.clubPhoto"
            alt=""
            class="img-full"
            width="370"
            height="200"
          />
          <div class="mcard__favorite dn auth-df c-pointer pdf-remove">
            <div class="add-favorite">
              <label
                class="c-pointer add-favorite__label"
                data-position="bottom center"
                data-tooltip
                data-favorite
              >
                <input type="checkbox" class="add-favorite__input" />
                <svg class="ic ic-add-favorite add-favorite__icon">
                  <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
                </svg>
              </label>
            </div>
          </div>
        </div>
        <div class="mcard__content">
          <div class="mcard__body">
            <div>
              <a
                class="mcard__pname"
                routerLink="/publications/{{ item.publicationId }}"
                attr.data-tooltip="{{
                  (item.publicationTypeCode == 'type_pub_packet'
                    ? 'package'
                    : 'Request'
                  ) | translate
                }} {{ item.publicationNumber }}"
              >
                {{
                  (item.publicationTypeCode == 'type_pub_packet'
                    ? 'package'
                    : 'Request'
                  ) | translate
                }}
                {{ item.publicationNumber }}
              </a>
              <div
                *ngIf="item.publicationTypeCode != 'type_pub_packet'"
                class="mb-05 text-sm font-alt-extra font-semibold display-as-block"
              >
                На покупку Лота по Ответчику
              </div>
            </div>
            <div>
              <a
                routerLink="/clubs/{{ item.clubId }}"
                attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
                data-position="top center"
                class="mcard__name mcard__ci"
              >
                {{ item.clubNameFormatted | translate }}
              </a>
            </div>
            <div class="mcard__nav mt2 hidden pdf-add">
              <ng-container *ngIf="item.packageRatingName">
                <div
                  class="sticker"
                  [attr.data-tooltip]="'packageRating' | translate"
                  data-position="bottom center"
                >
                  {{ item.packageRatingName }}
                </div>
              </ng-container>
              <ng-container *ngIf="item.userRatingName">
                <div
                  class="sticker"
                  [attr.data-tooltip]="'userRating' | translate"
                  data-position="bottom center"
                >
                  {{ item.userRatingName }}
                </div>
              </ng-container>
              <ng-container
                *ngIf="item.publicationTypeCode == 'type_pub_packet'"
              >
                <div
                  *ngIf="item.certCount"
                  class="sticker"
                  [attr.data-tooltip]="'certCount' | translate"
                  data-position="bottom center"
                >
                  {{ item.certCount }}
                </div>
              </ng-container>
            </div>
            <div
              class="dl"
              *ngIf="item.publicationTypeCode == 'type_pub_packet'"
            >
              <span class="dl__dt">{{ item.dealTypeName | translate }}</span>
              <span class="dl__points"></span>
              <span class="dl__dd">{{ item.priceFormatted }}</span>
            </div>
          </div>
          <div
            class="mcard__footer {{
              item.publicationTypeCode != 'type_pub_packet'
                ? 'display-as-block'
                : ''
            }}"
          >
            <!-- start of part for replace from new markup -->
            <div
              class="dl"
              *ngIf="item.publicationTypeCode != 'type_pub_packet'"
            >
              <span class="dl__dt">
                {{ 'The amount of the claims' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">{{ item.claimsAmountFormatted }}</span>
            </div>

            <!--<div class="dl" *ngIf="item.publicationTypeCode != 'type_pub_packet'">
              <span class="dl__dt">{{ 'Amount of court costs' | translate }}</span>
              <span class="dl__points"></span>
              <span class="dl__dd">{{ item.courtCostsFormatted }}</span>
            </div>-->
            <div
              class="dl"
              *ngIf="item.publicationTypeCode != 'type_pub_packet'"
            >
              <span class="dl__dt">{{item.publicationTypeCode ==='type_pub_offer_add'?'Общая стоимость Заявки':'Стоимость заявки'}}</span>
              <span class="dl__points"></span>
              <span class="dl__dd">{{ item.priceFormatted }}</span>
            </div>
            <!-- end of part for replace from new markup -->

            <div
              class="mcard__nav"
              *ngIf="item.publicationTypeCode == 'type_pub_packet'"
            >
              <ng-container *ngIf="item.certCount">
                <div
                  *ngIf="item.certCount"
                  class="sticker"
                  [attr.data-tooltip]="'certCount' | translate"
                  data-position="bottom center"
                >
                  {{ item.certCount }}
                </div>
              </ng-container>
            </div>
            <div
              class="mcard__btn c-link {{
                item.publicationTypeCode != 'type_pub_packet' ? 'pt-1rem' : ''
              }}"

              [ngClass]="{'type-joining-add':item?.publicationTypeCode === 'type_pub_offer_add'}"
            >
            <div class="w-100-percent" *ngIf="item?.publicationTypeCode === 'type_pub_offer_add' ">
              <div class="connection-amount-text-requistion margin-bootom-4px">
                Внесено {{item.allConnectionAmount}} из {{(item.priceFormatted.replace("RUB", "")).replace(",00", "")}}
              </div>
              <div class="progress-bar">
                <div class="progress-line" 
                [ngClass]="{'progress-line-100-percent': item.persentConnectionAmount === '100'}"
                [style.width]='
                  item.persentConnectionAmount + "%"'>
                   {{item.persentConnectionAmount}} %</div>
              </div>
            </div>
              <button
                type="button"
                [disabled]="bw.ts.dealBlocked(item.id)"
                (click)="doAction(item)"
                *ngIf="item.dealTypeId != 3; else openAuction1"
                class="btn {{
                  item.publicationTypeCode != 'type_pub_packet' ? 'mla-i' : ''
                }}"
                [attr.data-tooltip]="
                  (item.publicationTypeCode != 'type_pub_packet'
                    ? 'Details'
                    : 'doBuy'
                  ) | translate
                "
                data-position="bottom center"
              >
                {{
                  (item.publicationTypeCode != 'type_pub_packet'
                    ? 'Details'
                    : 'doBuy'
                  ) | translate
                }}
              </button>
              <ng-template #openAuction1>
                <a
                  routerLink="/publications/{{ item.id }}"
                  class="btn"
                  [attr.data-tooltip]="'Auction' | translate"
                  data-position="bottom center"
                >
                  {{item.publicationTypeCode ==='type_pub_offer_add'?('Details' | translate) :( 'Auction' | translate )}}
                </a>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="mcard__description">
          <div class="mcard__title title">
            <svg
              class="ic ic-card-arrow ic-round"
              style="{{
                item.publicationTypeCode != 'type_pub_packet'
                  ? 'display:none!important;'
                  : ''
              }}"
            >
              <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
            </svg>
          </div>
          <div class="content">
            <ul class="mcard__list pb15">
              <ng-container *ngIf="item.dealTypeId != 3; else auctionBlock">
                <li class="dl">
                  <span class="dl__dt">
                    {{ item.dealTypeName | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.priceFormatted }}</span>
                </li>
                <li
                  class="dl"
                  *ngIf="item.publicationTypeCode == 'type_pub_packet'"
                >
                  <span class="dl__dt">{{ 'certCount' | translate }}</span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.certCount }}</span>
                </li>
                <li class="dl" *ngIf="item.claimsAmountFormatted">
                  <span class="dl__dt">
                    {{ 'The amount of the claims' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.claimsAmountFormatted }}</span>
                </li>
              </ng-container>
              <ng-template #auctionBlock>
                <li class="dl" *ngIf="item.startingPriceFormatted">
                  <span class="dl__dt">
                    {{ 'Auction starting price' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.startingPriceFormatted }}</span>
                </li>
                <li class="dl">
                  <span class="dl__dt">
                    {{ 'Redemption price' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.priceFormatted }}</span>
                </li>
                <li class="dl">
                  <span class="dl__dt">{{ 'Club' | translate }}</span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">
                    {{ item.clubNameFormatted | translate }}
                  </span>
                </li>
              </ng-template>
              <li class="dl" *ngIf="item.packageType">
                <span class="dl__dt">{{ 'packageType' | translate }}</span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.packageType | translate }}</span>
              </li>
              <li class="dl" *ngIf="item.startDateFormatted">
                <span class="dl__dt">
                  {{
                    (item.publicationTypeCode != 'type_pub_packet'
                      ? 'Purchase start'
                      : item.publicationTypeCode == 'type_pub_packet'
                      ? 'Publication date'
                      : 'Auction start'
                    ) | translate
                  }}
                </span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.startDateFormatted }}</span>
              </li>
              <li class="dl dl_mod">
                <span class="dl__dt">
                  {{
                    (item.publicationTypeCode != 'type_pub_packet'
                      ? 'Requisition expires in'
                      : item.publicationTypeCode == 'type_pub_packet'
                      ? 'Sale ends in'
                      : 'Auction ends in'
                    ) | translate
                  }}
                </span>
                <span class="dl__points"></span>
                <span class="dl__dd">
                  <div
                    *ngIf="(item.endsIn | json) != ({} | json); else elseBlock"
                  >
                    <span *ngFor="let part of item.endsIn | keyvalue">
                      {{ part.value }} {{ $any(part.key) | translate }}
                    </span>
                  </div>
                  <ng-template #elseBlock>
                    {{
                      (item.publicationTypeCode != 'type_pub_packet'
                        ? 'Purchase finished'
                        : item.publicationTypeCode == 'type_pub_packet'
                        ? 'Sale finished'
                        : 'Auction finished'
                      ) | translate
                    }}
                  </ng-template>
                </span>
              </li>
            </ul>
            <div class="modcard__nav df aic pdf-remove hidden">
              <button
                type="button"
                class="mr18 h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Add' | translate"
                *ngIf="isAuthorized"
              >
                <svg class="ic ic-card-add ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-add"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr18 h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Edit' | translate"
                *ngIf="isAuthorized"
              >
                <svg class="ic ic-card-edit ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-edit"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr18 h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Delete' | translate"
                *ngIf="isAuthorized"
              >
                <svg class="ic ic-card-del ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-del"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr18 h-orange h-light:c-blue"
                (click)="download($event, item)"
              >
                <svg
                  class="ic ic-card-download ic-round ic-stroke"
                  data-position="bottom center"
                  [attr.data-tooltip]="'Download' | translate"
                >
                  <use xlink:href="/assets/img/sprite.svg#card-download"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr18 h-orange h-light:c-blue"
                (click)="print($event, item)"
              >
                <svg
                  class="ic ic-card-printer ic-round ic-stroke"
                  data-position="bottom center"
                  [attr.data-tooltip]="'Print' | translate"
                >
                  <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
                </svg>
              </button>
              <div class="mcard__nav mla">
                <ng-container *ngIf="item.certCount > 0">
                  <div
                    *ngIf="item.certCount"
                    class="sticker"
                    [attr.data-tooltip]="'certCount' | translate"
                    data-position="bottom center"
                  >
                    {{ item.certCount }}
                  </div>
                </ng-container>
              </div>
              <div class="modcard__nav-btns mla df aic">
                <div class="mcard__btn c-link">
                  <button
                    type="button"
                    [disabled]="bw.ts.dealBlocked(item.id)"
                    (click)="doAction(item)"
                    *ngIf="item.dealTypeId != 3; else openAuction"
                    class="btn"
                    [attr.data-tooltip]="
                      (item.publicationTypeCode != 'type_pub_packet'
                        ? 'Details'
                        : 'doBuy'
                      ) | translate
                    "
                    data-position="bottom center"
                  >
                    {{
                      (item.publicationTypeCode == 'type_pub_packet'
                        ? 'doBuy'
                        : 'Details'
                      ) | translate
                    }}
                  </button>
                  <ng-template #openAuction>
                    <a
                      routerLink="/publications/{{ item.id }}"
                      class="btn"
                      [attr.data-tooltip]="'Auction' | translate"
                      data-position="bottom center"
                    >
                      {{ 'Auction' | translate }}
                    </a>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mcards__list jcfs" *ngIf="mode == 12">
  <div class="mcards__item mcards__item27" *ngFor="let item of list">
    <div class="pdf-container">
      <div
        class="hidden pdf-add pb2 mb5 w100"
        style="border-bottom: 1px solid #235"
      >
        <img src="/assets/img/logo-all_dark.svg" alt="" />
        <div class="ff:CormorantInfant fz18 mt1">https://tst-trade.ru</div>
      </div>
      <div class="mcard-mini ui accordion mcard12 pdf-shrink">
        <div class="mcard-mini__content">
          <div class="mcard-mini__head" [ngClass]="{'border-bottom-0': item?.publicationTypeCode === 'type_pub_offer_add'}">
            <div class="mcard-mini__picture">
              <img
                [src]="item.clubPhoto"
                alt=""
                class="img-full"
                width="190"
                height="90"
              />
            </div>
            <div class="add-favorite pdf-remove" *ngIf="isAuthorized">
              <label
                class="c-pointer add-favorite__label"
                data-position="top center"
                data-tooltip
                data-favorite
              >
                <input type="checkbox" class="add-favorite__input" />
                <svg class="ic ic-add-favorite add-favorite__icon">
                  <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
                </svg>
              </label>
            </div>
            <div class="mcard-mini__info">
              <div>
                <a
                  routerLink="/publications/{{ item.publicationId }}"
                  class="mcard-mini__pname"
                  attr.data-tooltip="{{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'package'
                      : 'Request'
                    ) | translate
                  }} {{ item.publicationNumber }}"
                  data-position="top center"
                >
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'package'
                      : 'Request'
                    ) | translate
                  }}
                  {{ item.publicationNumber }}
                </a>
                <div
                  *ngIf="item.publicationTypeCode != 'type_pub_packet'"
                  class="mb-05 text-sm font-alt-extra font-semibold"
                >
                  На покупку Лота по Ответчику
                </div>
              </div>
              <div
                attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
                data-position="top center"
                data-variation="basic"
              >
                <a
                  routerLink="/clubs/{{ item.clubId }}"
                  class="mcard-mini__name mcard-mini__ci"
                >
                  {{ item.clubNameFormatted | translate }}
                </a>
              </div>
              <div  *ngIf="item?.publicationTypeCode !== 'type_pub_offer_add'"  class="dl">
                
                <span class="dl__dt">{{ item.dealTypeName | translate }} </span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.priceFormatted }}</span>
              </div>
            </div>
          
          </div>
          <div  *ngIf="item?.publicationTypeCode === 'type_pub_offer_add'"  class="dl border-bottom-1px">
                
            <span class="dl__dt">{{ 'Total cost of the Application' | translate }} </span>
            <span class="dl__points"></span>
            <span class="dl__dd">{{ item.priceFormatted }}</span>
          </div>
          <!-- <div class="mcard-mini__description">
            <div class="mcard-mini__title title">
              <svg class="ic ic-card-arrow ic-round">
                <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
              </svg>
            </div>
            <div class="content">
              <ul class="mcard-mini__list pb15">
                <ng-container *ngIf="item.dealTypeId != 3; else auctionBlock">
                  <li class="dl">
                    <span class="dl__dt">
                      {{ item.dealTypeName | translate }} 
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.priceFormatted }}</span>
                  </li>
                  <li class="dl" *ngIf="item.certCount > 0">
                    <span class="dl__dt">{{ 'certCount' | translate }}</span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.certCount }}</span>
                  </li>
                  <li class="dl" *ngIf="item.claimsAmountFormatted">
                    <span class="dl__dt">
                      {{ 'The amount of the claims' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.claimsAmountFormatted }}</span>
                  </li>
                </ng-container>
                <ng-template #auctionBlock>
                  <li class="dl" *ngIf="item.startingPriceFormatted">
                    <span class="dl__dt">
                      {{ 'Auction starting price' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">
                      {{ item.startingPriceFormatted }}
                    </span>
                  </li>
                  <li class="dl">
                    <span class="dl__dt">
                      {{ 'Redemption price' | translate }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.priceFormatted }}</span>
                  </li>
                </ng-template>
                <li class="dl" *ngIf="item.packageType">
                  <span class="dl__dt">{{ 'packageType' | translate }}</span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.packageType | translate }}</span>
                </li>
                <li class="dl" *ngIf="item.startDateFormatted">
                  <span class="dl__dt">
                    {{
                      (item.publicationTypeCode == 'type_pub_packet'
                        ? 'Publication date'
                        : item.publicationTypeCode == 'type_pub_auction'
                        ? 'Auction start'
                        : 'Purchase start'
                      ) | translate
                    }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.startDateFormatted }}</span>
                </li>
                <li class="dl dl_mod">
                  <span class="dl__dt">
                    {{
                      (item.publicationTypeCode == 'type_pub_packet'
                        ? 'Sale ends in'
                        : item.publicationTypeCode == 'type_pub_auction'
                        ? 'Auction ends in'
                        : 'Requisition expires in'
                      ) | translate
                    }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">
                    <div
                      *ngIf="
                        (item.endsIn | json) != ({} | json);
                        else elseBlock
                      "
                    >
                      <span *ngFor="let part of item.endsIn | keyvalue">
                        {{ part.value }} {{ $any(part.key) | translate }}
                      </span>
                    </div>
                    <ng-template #elseBlock>
                      {{
                        (item.publicationTypeCode == 'type_pub_packet'
                          ? 'Sale finished'
                          : item.publicationTypeCode == 'type_pub_auction'
                          ? 'Auction finished'
                          : 'Purchase finished'
                        ) | translate
                      }}
                    </ng-template>
                  </span>
                </li>
              </ul>
            </div>
          </div> -->
          <div class="mcard-mini__footer pdf-remove">
            <div class="mcard-mini__nav" *ngIf="item?.publicationTypeCode !== 'type_pub_offer_add'">
              <div
                *ngIf="item.certCount"
                data-position="bottom center"
                [attr.data-tooltip]="'certCount' | translate"
                data-variation="basic"
              >
                <div class="sticker">{{ item.certCount }}</div>
              </div>
            </div>
            <div class="mcard-mini__footer-rt" [ngClass]="{'w-100-percent': item?.publicationTypeCode === 'type_pub_offer_add'}">
              <!-- <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Add' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-add ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-add"></use>
                </svg>
              </button>
              <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Edit' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-edit ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-edit"></use>
                </svg>
              </button>
              <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Delete' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-del ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-del"></use>
                </svg>
              </button> -->
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Download' | translate"
                (click)="download($event, item)"
              >
                <svg class="ic ic-card-download ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-download"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Print' | translate"
                (click)="print($event, item)"
              >
                <svg class="ic ic-card-printer ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
                </svg>
              </button>
              <div class="w-100-percent" *ngIf="item?.publicationTypeCode === 'type_pub_offer_add' ">
                <div class="connection-amount-text-requistion margin-bootom-4px">
                  Внесено {{item.allConnectionAmount}} из {{(item.priceFormatted.replace("RUB", "")).replace(",00", "")}}
                </div>
                <div class="progress-bar">
                  <div class="progress-line" 
                  [ngClass]="{'progress-line-100-percent': item.persentConnectionAmount === '100'}"
                  [style.width]='
                    item.persentConnectionAmount + "%"'>
                     {{item.persentConnectionAmount}} %</div>
                </div>
              </div>
              <div
                *ngIf="item.dealTypeId != 3; else openAuction2"
                class="mcard-mini__btn c-link"
                [attr.data-tooltip]="
                  (item.publicationTypeCode != 'type_pub_packet'
                    ? 'Details'
                    : 'doBuy'
                  ) | translate
                "
                data-position="bottom center"
              >
                <button
                  type="button"
                  [disabled]="bw.ts.dealBlocked(item.id)"
                  (click)="doAction(item)"
                  class="btn"
                >
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'doBuy'
                      : 'Details'
                    ) | translate
                  }}
                </button>
              </div>
              <ng-template #openAuction2>
                <div
                  class="mcard-mini__btn c-link"
                  data-position="bottom center"
                  [attr.data-tooltip]="item.publicationTypeCode ==='type_pub_offer_add'?('Details' | translate) :( 'Auction' | translate )"
                >
                  <a routerLink="/publications/{{ item.id }}" class="btn">
                    {{ item.publicationTypeCode ==='type_pub_offer_add'?('Details' | translate) :( 'Auction' | translate )}}
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mcards__list jcfs" *ngIf="mode == 27 || mode == 48">
  <div class="mcards__item mcards__item27" *ngFor="let item of list">
    <div class="mcard27 ui accordion">
      <div class="mcard27__content">
        <div class="mcard27__head">
          <div class="mcard27__info">
            <div>
              <a
                routerLink="/publications/{{ item.publicationId }}"
                class="mcard__pname"
                attr.data-tooltip="{{
                  (item.publicationTypeCode == 'type_pub_packet'
                    ? 'package'
                    : 'Request'
                  ) | translate
                }} {{ item.publicationNumber }}"
                data-position="top center"
              >
                {{
                  (item.publicationTypeCode == 'type_pub_packet'
                    ? 'package'
                    : 'Request'
                  ) | translate
                }}
                {{ item.publicationNumber }}
              </a>
            </div>
            <div class="mcard27__favorite" *ngIf="isAuthorized">
              <div class="add-favorite">
                <label
                  class="c-pointer add-favorite__label"
                  data-position="top center"
                  data-tooltip
                  data-favorite
                >
                  <input type="checkbox" class="add-favorite__input" />
                  <svg class="ic ic-add-favorite add-favorite__icon">
                    <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
                  </svg>
                </label>
              </div>
            </div>
            <div class="mcard27__header">
              <div>
                <a
                  routerLink="/clubs/{{ item.clubId }}"
                  class="mcard27__name mcard27__ci ellipsis"
                  data-position="top center"
                  attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
                  data-variation="basic"
                >
                  {{ item.clubNameFormatted | translate }}
                </a>
              </div>
              <div class="mcard27__price">{{ item.priceFormatted }}</div>
              <div class="mcard27__nav-publication-card">
                <div *ngIf="item.publicationTypeCode ==='type_pub_offer_add'" class="persent-publication-card" [ngClass]="{'persent-100-publication-card':+item?.persentConnectionAmount === 100}">
                  {{item.persentConnectionAmount}} %
                </div>
                <!-- <div
                  *ngIf="item.certCount"
                  data-position="bottom center"
                  [attr.data-tooltip]="'Number of certificates' | translate"
                  data-variation="basic"
                >
                  <div class="sticker">{{ item.certCount }}</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mcard27__description">
          <div class="mcard27__title title">
            <svg class="ic ic-card-arrow ic-round">
              <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
            </svg>
          </div>
          <div class="content">
            <ul class="mcard27__list pb15">
              <ng-container
                *ngIf="
                  item.publicationTypeCode != 'type_pub_auction';
                  else auctionBlock
                "
              >
                <li class="dl">
                  <span class="dl__dt">
                    {{ item.dealTypeName | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.priceFormatted }}</span>
                </li>
                <li class="dl" *ngIf="item.certCount > 0">
                  <span class="dl__dt">{{ 'certCount' | translate }}</span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.certCount }}</span>
                </li>
                <li class="dl" *ngIf="item.claimsAmountFormatted">
                  <span class="dl__dt">
                    {{ 'The amount of the claims' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.claimsAmountFormatted }}</span>
                </li>
              </ng-container>
              <ng-template #auctionBlock>
                <li class="dl" *ngIf="item.startingPriceFormatted">
                  <span class="dl__dt">
                    {{ 'Auction starting price' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.startingPriceFormatted }}</span>
                </li>
                <li class="dl">
                  <span class="dl__dt">
                    {{ 'Redemption price' | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.priceFormatted }}</span>
                </li>
              </ng-template>
              <li class="dl" *ngIf="item.packageType">
                <span class="dl__dt">{{ 'packageType' | translate }}</span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.packageType | translate }}</span>
              </li>
              <li class="dl" *ngIf="item.startDateFormatted">
                <span class="dl__dt">
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'Publication date'
                      : item.publicationTypeCode == 'type_pub_auction'
                      ? 'Auction start'
                      : 'Purchase start'
                    ) | translate
                  }}
                </span>
                <span class="dl__points"></span>
                <span class="dl__dd">{{ item.startDateFormatted }}</span>
              </li>
              <li class="dl dl_mod">
                <span class="dl__dt">
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'Sale ends in'
                      : item.publicationTypeCode == 'type_pub_auction'
                      ? 'Auction ends in'
                      : 'Requisition expires in'
                    ) | translate
                  }}
                </span>
                <span class="dl__points"></span>
                <span class="dl__dd">
                  <div
                    *ngIf="(item.endsIn | json) != ({} | json); else elseBlock"
                  >
                    <span *ngFor="let part of item.endsIn | keyvalue">
                      {{ part.value }} {{ $any(part.key) | translate }}
                    </span>
                  </div>
                  <ng-template #elseBlock>
                    {{
                      (item.publicationTypeCode == 'type_pub_packet'
                        ? 'Sale finished'
                        : item.publicationTypeCode == 'type_pub_auction'
                        ? 'Auction finished'
                        : 'Purchase finished'
                      ) | translate
                    }}
                  </ng-template>
                </span>
              </li>
            </ul>
          </div>
          <div class="mcard27__footer">
            <div class="mcard27__footer-rt">
              <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Add' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-add ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-add"></use>
                </svg>
              </button>
              <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Edit' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-edit ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-edit"></use>
                </svg>
              </button>
              <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center"
                [attr.data-tooltip]="'Delete' | translate" *ngIf="isAuthorized">
                <svg class="ic ic-card-del ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-del"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Download' | translate"
              >
                <svg class="ic ic-card-download ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-download"></use>
                </svg>
              </button>
              <button
                type="button"
                class="mr15 dn h-orange h-light:c-blue"
                data-position="bottom center"
                [attr.data-tooltip]="'Print' | translate"
              >
                <svg class="ic ic-card-printer ic-round ic-stroke">
                  <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
                </svg>
              </button>
              <div
                *ngIf="item.dealTypeId != 3; else openAuction3"
                class="mcard27__btn c-link dn"
                [attr.data-tooltip]="
                  (item.publicationTypeCode != 'type_pub_packet'
                    ? 'Details'
                    : 'doBuy*'
                  ) | translate
                "
                data-position="bottom center"
              >
                <button
                  type="button"
                  [disabled]="bw.ts.dealBlocked(item.id)"
                  (click)="doAction(item)"
                  class="btn"
                >
                  {{
                    (item.publicationTypeCode == 'type_pub_packet'
                      ? 'doBuy'
                      : 'Details'
                    ) | translate
                  }}
                </button>
              </div>
              <ng-template #openAuction3>
                <div
                  class="mcard27__btn c-link dn"
                  data-position="bottom center"
                  [attr.data-tooltip]="'Auction' | translate"
                >
                  <a routerLink="/publications/{{ item.id }}" class="btn">
                    {{ 'Auction' | translate }}
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- <div class="mcards__list jcfs" *ngIf="mode == 49">
  <div class="mcards__item mcards__item49" *ngFor="let item of list">
    <div class="mcard49">
      <div class="mcard49__content">
        <div class="mcard49__head">
          <div class="mcard49__picture">
            <a
              routerLink="/publications/{{ item.publicationId }}"
              attr.data-tooltip="{{
                (item.publicationTypeCode == 'type_pub_packet'
                  ? 'package'
                  : 'Request'
                ) | translate
              }} {{ item.publicationNumber }}"
            >
              <img
                [src]="item.clubPhoto"
                alt=""
                class="img-full"
                width="190"
                height="90"
              />
            </a>
          </div>
          <div class="mcard49__favorite" *ngIf="isAuthorized">
            <div class="add-favorite">
              <label
                class="c-pointer add-favorite__label"
                data-position="top center"
                data-tooltip
                data-favorite
              >
                <input type="checkbox" class="add-favorite__input" />
                <svg class="ic ic-add-favorite add-favorite__icon">
                  <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
                </svg>
              </label>
            </div>
          </div>
          <div class="mcard49__info">
            <div
              attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
              data-position="top center"
              data-variation="basic"
            >
              <a
                routerLink="/clubs/{{ item.clubId }}"
                class="mcard49__name mcard49__ci"
              >
                {{ item.clubNameFormatted | translate }}
              </a>
            </div>
            <div class="mcard49__price">{{ item.priceFormatted }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div id="downloading-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for download...' | translate }}
      </div>
    </div>
  </div>
</div>

<div id="printing-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for print...' | translate }}
      </div>
    </div>
  </div>
</div>
