import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { ListItem } from 'src/app/core/models/listitem/list-item.model';
export interface AddPackagesModel {
  purchaseRequisition: Publication;
  packets: ListItem[];
  data: any | undefined;
}
@Component({
  selector: 'app-add-requisition-packages',
  templateUrl: './add-requisition-packages.component.html',
  styleUrls: ['./add-requisition-packages.component.css']
})
export class AddRequisitionPackagesComponent
  extends SimpleModalComponent<AddPackagesModel, ListItem[]>
  implements AddPackagesModel, OnInit
{
  purchaseRequisition!: Publication;
  packets!: ListItem[];
  data: any | undefined;
  response: ListItem[] = [];
  selected: number | null = null;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  async accept() {
    this.response = this.packets.filter((p) => {
      if (p.id === this.selected) return true;
      return false;
    });

    this.result = this.response;
    this.close();
  }
  cancel() {
    this.result = [];
    this.close();
  }
}
