<div class="package-list">
  <div class="package-list__row">
    <div class="package-list__item" *ngFor="let item of publications">
      <div class="package-list__container">
        <div class="dl">
          <span class="dl__dt">{{ item.startDateFormatted }}</span>
          <span class="dl__points"></span>
          <span class="dl__dd">{{ item.stopDateFormatted }}</span>
        </div>
        <div class="dl">
          <span class="dl__dt">{{ 'package' | translate }}</span>
          <span class="dl__points"></span>
          <span
            class="dl__dd ellipsis"
            [attr.data-tooltip]="item.clubNameFormatted | translate"
            data-position="top center"
          >
            {{ item.clubNameFormatted }}
          </span>
        </div>
        <div class="dl">
          <span class="dl__dt">{{ 'soldOut' | translate }}</span>
          <span class="dl__points"></span>
          <span class="dl__dd">{{ item.priceFormatted }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
