<div class="mcards" *ngIf="publications.length; else noPublications">
  <div class="mcards__wrap">
    <div class="mcards__list">
      <div class="mcards__item" *ngFor="let item of publications">
        <div class="pdf-container pdf-shrink">
          <div class="mcard ui accordion">
            <div
              class="hidden pdf-add pb2 mb2 w100"
              style="border-bottom: 1px solid #235"
            >
              <img src="/assets/img/logo-all_dark.svg" alt="" />
              <div class="ff:CormorantInfant fz18 mt1">www.t-st.group</div>
            </div>
            <div class="mcard__picture">
              <img
                [src]="item.clubPhoto"
                alt=""
                class="img-full"
                width="370"
                height="200"
              />

              <div class="mcard__favorite dn auth-df c-pointer pdf-remove">
                <div class="add-favorite">
                  <label
                    class="c-pointer add-favorite__label"
                    data-position="bottom center"
                    data-tooltip
                    data-favorite
                  >
                    <input type="checkbox" class="add-favorite__input" />
                    <svg class="ic ic-add-favorite add-favorite__icon">
                      <use
                        xlink:href="/assets/img/sprite.svg#add-favorite"
                      ></use>
                    </svg>
                  </label>
                </div>
              </div>
            </div>

            <div class="mcard__content">
              <div class="mcard__body">
                <div>
                  <a
                    class="mcard__pname"
                    routerLink="/publications/{{ item.id }}"
                    attr.data-tooltip="{{ 'package' | translate }} {{
                      item.id
                    }}"
                  >
                    {{ 'package' | translate }} {{ item.id }}
                  </a>
                </div>
                <div>
                  <a
                    routerLink="/clubs/{{ item.clubId }}"
                    attr.data-tooltip="{{ item.clubNameFormatted | translate }}"
                    data-position="top center"
                    class="mcard__name mcard__ci"
                  >
                    {{ item.clubNameFormatted | translate }}
                  </a>
                </div>
                <div class="mcard__nav mt2 hidden pdf-add">
                  <ng-container *ngIf="item.packageRatingName">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'packageRating' | translate"
                      data-position="bottom center"
                    >
                      {{ item.packageRatingName }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.userRatingName">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'userRating' | translate"
                      data-position="bottom center"
                    >
                      {{ item.userRatingName }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.certCount">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'certCount' | translate"
                      data-position="bottom center"
                    >
                      {{ item.certCount }}
                    </div>
                  </ng-container>
                </div>
                <div class="dl">
                  <span class="dl__dt">
                    {{ item.dealTypeName | translate }}
                  </span>
                  <span class="dl__points"></span>
                  <span class="dl__dd">{{ item.priceFormatted }}</span>
                </div>
              </div>
              <div class="mcard__footer">
                <div class="mcard__nav">
                  <ng-container *ngIf="item.packageRatingName">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'packageRating' | translate"
                      data-position="bottom center"
                    >
                      {{ item.packageRatingName }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.userRatingName">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'userRating' | translate"
                      data-position="bottom center"
                    >
                      {{ item.userRatingName }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.certCount">
                    <div
                      class="sticker"
                      [attr.data-tooltip]="'certCount' | translate"
                      data-position="bottom center"
                    >
                      {{ item.certCount }}
                    </div>
                  </ng-container>
                </div>
                <div class="mcard__btn c-link">
                  <a
                    href="#"
                    *ngIf="item.dealTypeId != 3; else openAuction1"
                    class="btn"
                    [attr.data-tooltip]="
                      (item.dealTypeId == 2 ? 'doSell' : 'doBuy') | translate
                    "
                    data-position="bottom center"
                  >
                    {{
                      (item.dealTypeId == 2 ? 'doSell' : 'doBuy') | translate
                    }}
                  </a>
                  <ng-template #openAuction1>
                    <a
                      routerLink="/publications/{{ item.id }}"
                      class="btn"
                      [attr.data-tooltip]="'Auction' | translate"
                      data-position="bottom center"
                    >
                      {{ 'Auction' | translate }}
                    </a>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="mcard__description">
              <div class="mcard__title title">
                <svg class="ic ic-card-arrow ic-round">
                  <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
                </svg>
              </div>
              <div class="content">
                <ul class="mcard__list pb15">
                  <ng-container *ngIf="item.dealTypeId != 3; else auctionBlock">
                    <li class="dl">
                      <span class="dl__dt">
                        {{ item.dealTypeName | translate }}
                      </span>
                      <span class="dl__points"></span>
                      <span class="dl__dd">{{ item.priceFormatted }}</span>
                    </li>
                    <li class="dl" *ngIf="item.certCount">
                      <span class="dl__dt">{{ 'certCount' | translate }}</span>
                      <span class="dl__points"></span>
                      <span class="dl__dd">{{ item.certCount }}</span>
                    </li>
                    <li class="dl" *ngIf="item.claimsAmountFormatted">
                      <span class="dl__dt">
                        {{ 'The amount of the claims' | translate }}
                      </span>
                      <span class="dl__points"></span>
                      <span class="dl__dd">
                        {{ item.claimsAmountFormatted }}
                      </span>
                    </li>
                  </ng-container>
                  <ng-template #auctionBlock>
                    <li class="dl" *ngIf="item.startingPriceFormatted">
                      <span class="dl__dt">
                        {{ 'Auction starting price' | translate }}
                      </span>
                      <span class="dl__points"></span>
                      <span class="dl__dd">
                        {{ item.startingPriceFormatted }}
                      </span>
                    </li>
                    <li class="dl">
                      <span class="dl__dt">
                        {{ 'Redemption price' | translate }}
                      </span>
                      <span class="dl__points"></span>
                      <span class="dl__dd">{{ item.priceFormatted }}</span>
                    </li>
                  </ng-template>
                  <li class="dl" *ngIf="item.packageType">
                    <span class="dl__dt">{{ 'packageType' | translate }}</span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">
                      {{ item.packageType | translate }}
                    </span>
                  </li>
                  <li class="dl" *ngIf="item.startDateFormatted">
                    <span class="dl__dt">
                      {{
                        (item.dealTypeId == 2
                          ? 'Purchase start'
                          : item.dealTypeId == 3
                          ? 'Auction start'
                          : 'Publication date'
                        ) | translate
                      }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">{{ item.startDateFormatted }}</span>
                  </li>
                  <li class="dl dl_mod">
                    <span class="dl__dt">
                      {{
                        (item.dealTypeId == 2
                          ? 'Requisition expires in'
                          : item.dealTypeId == 3
                          ? 'Auction ends in'
                          : 'Sale ends in'
                        ) | translate
                      }}
                    </span>
                    <span class="dl__points"></span>
                    <span class="dl__dd">
                      <div
                        *ngIf="
                          (item.endsIn | json) != ({} | json);
                          else elseBlock
                        "
                      >
                        <span *ngFor="let part of item.endsIn | keyvalue">
                          {{ part.value }} {{ $any(part.key) | translate }}
                        </span>
                      </div>
                      <ng-template #elseBlock>
                        {{
                          (item.dealTypeId == 2
                            ? 'Purchase finished'
                            : item.dealTypeId == 3
                            ? 'Auction finished'
                            : 'Sale finished'
                          ) | translate
                        }}
                      </ng-template>
                    </span>
                  </li>
                </ul>
                <div class="modcard__nav df aic pdf-remove">
                  <button
                    type="button"
                    class="mr18 h-orange h-light:c-blue"
                    (click)="download($event)"
                  >
                    <svg
                      class="ic ic-card-download ic-round ic-stroke"
                      data-position="bottom center"
                      attr.data-tooltip="{{ 'Download' | translate }}"
                    >
                      <use
                        xlink:href="/assets/img/sprite.svg#card-download"
                      ></use>
                    </svg>
                  </button>

                  <button
                    type="button"
                    class="mr18 h-orange h-light:c-blue"
                    (click)="print($event)"
                  >
                    <svg
                      class="ic ic-card-printer ic-round ic-stroke"
                      data-position="bottom center"
                      [attr.data-tooltip]="'Print' | translate"
                    >
                      <use
                        xlink:href="/assets/img/sprite.svg#card-printer"
                      ></use>
                    </svg>
                  </button>

                  <div class="mcard__nav mla">
                    <ng-container *ngIf="item.packageRatingName">
                      <div
                        class="sticker"
                        [attr.data-tooltip]="'packageRating' | translate"
                        data-position="bottom center"
                      >
                        {{ item.packageRatingName }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="item.userRatingName">
                      <div
                        class="sticker"
                        [attr.data-tooltip]="'userRating' | translate"
                        data-position="bottom center"
                      >
                        {{ item.userRatingName }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="item.certCount">
                      <div
                        class="sticker"
                        [attr.data-tooltip]="'certCount' | translate"
                        data-position="bottom center"
                      >
                        {{ item.certCount }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="modcard__nav-btns mla df aic">
                    <div class="mcard__btn c-link">
                      <a
                        href="#"
                        *ngIf="item.dealTypeId != 3; else openAuction"
                        class="btn"
                        [attr.data-tooltip]="
                          (item.dealTypeId == 2 ? 'doSell' : 'doBuy')
                            | translate
                        "
                        data-position="bottom center"
                      >
                        {{
                          (item.dealTypeId == 2 ? 'doSell' : 'doBuy')
                            | translate
                        }}
                      </a>
                      <ng-template #openAuction>
                        <a
                          routerLink="/publications/{{ item.id }}"
                          class="btn"
                          [attr.data-tooltip]="'Auction' | translate"
                          data-position="bottom center"
                        >
                          {{ 'Auction' | translate }}
                        </a>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="more" *ngIf="showMoreButton">
      <div class="more__btn">
        <span class="more__line more__line_left bg-wt"></span>
        <a
          (click)="$event.preventDefault(); seeMore()"
          href="#"
          class="btn btn_wt c-link"
        >
          {{ 'seeAll' | translate }}
        </a>
        <span class="more__line more__line_right bg-wt"></span>
      </div>
    </div>
  </div>
</div>
<ng-template #noPublications>
  <div class="mcards__wrap">
    <div class="mcards__list">
      <div class="mcards__item">
        <div class="fz20 ff:CormorantInfant">
          {{ 'No results' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div id="downloading-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for download...' | translate }}
      </div>
    </div>
  </div>
</div>

<div id="printing-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for print...' | translate }}
      </div>
    </div>
  </div>
</div>
