<div id="change-price-modal" class="smodal-content w-480">
    <div class="modal-header">
      <div (click)="cancel()" class="modal-close">
        <svg class="icon">
          <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
        </svg>
      </div>
    </div>

    <div>
      <div class="mw__head">
        <div class="font-alt text-xl text-center">
            Сумма для присоединения
        </div>
        <div class="divider-sm"></div>
      </div>
      <div class="discription-text">
        Введите сумму, которую Вы хотите присоединить к Заявке
    </div>
      <div class="sertificats__block">
        <div class="sertificats__top">
          <p class="form-lbinfo__title">Сумма вносимых Вами средств </p>
          <div
            class="form-lbinfo__icon ic-lbinfo"
            data-position="top center"
          >
          </div>
        </div>
      
        <input
          type="text"
          class="sertificats__input"
          name="price"
          mask="0*.00"
          [showMaskTyped]="false"
          required
          placeholder="Введите От 1 000 ₽"
          [(ngModel)]="enterSumm"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="changeSum()"
        />
        <div class="error__message" *ngIf="errors.get('errorSum')">
          {{ errors.get('errorSum') | translate }}
        </div>
        <div class="error__message" *ngIf="errors.get('errorBalance')" innerHTML=" {{ errors.get('errorBalance') | translate }}">
           
          </div>
      </div>
    </div>
  
    <div>
      <button
        *ngIf="!errors.get('errorBalance')"
        type="submit"
        class="btn w100 mb-2 h-50"
        (click)="setSum()"
        [attr.data-tooltip]="
           'Подтвердить'
        "
        data-position="bottom center"
      >
       Подтвердить
      </button>
      <button
      *ngIf="errors.get('errorBalance')"
      type="submit"
      class="btn w100 mb-2 h-50"
      (click)="setBalance()"
      [attr.data-tooltip]="
         'Пополнить Баланс'
      "
      data-position="bottom center"
    >
     Пополнить Баланс
    </button>
  
      <button
        type="button"
        class="btn btn--dark-outline w100 h-50"
        (click)="cancel()"
      >
        Отмена
      </button>
    </div>
  </div>