import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyRubleSymbol'
})
export class CurrencyRubleSymbolPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (value !== 0 && !value) {
      return '';
    }

    return `${formatNumber(value, 'ru-RU', '1.2-2')} ₽`;
  }
}
