import { formatDate, formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { BuyWfComponent } from 'src/app/blocks/buy-wf/buy-wf.component';
import { SecurityContext } from 'src/app/core/models/auth/security-context.model';
import { AuthorizationService } from 'src/app/core/services/authorization/authorization.service';

import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

declare function ucwords(str: string): string;
declare var scrollIntoView: any;
declare var gsap: any;

@Component({
  selector: 'app-top5-bottom',
  templateUrl: './top5-bottom.component.html',
  styleUrls: ['./top5-bottom.component.css']
})
export class Top5BottomComponent implements OnInit {
  @Input() withCloseControl: boolean = true;

  request: PublicationsRequest = new PublicationsRequest();
  top5: any = {};
  bodyOpened: boolean = false;
  colors = [
    'h:bg-twilight-blue',
    'h:bg-alice-blue',
    'h:bg-champagne',
    'h:bg-iron',
    'h:bg-janna'
  ];

  securityContext: SecurityContext = new SecurityContext();

  constructor(
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private authorizationService: AuthorizationService,
    public bw: BuyWfComponent
  ) {
    this.authorizationService.securityContext.subscribe(
      (securityContext) => (this.securityContext = securityContext)
    );
  }

  async ngOnInit() {
    this.request.orderBy = 'price';
    this.request.orderWay = 'desc';
    this.request.page = 0;
    this.request.limit = 5;
    this.request.onlyActual = 1;

    ['sale', 'purchase'].map(async (block: string) => {
      this.request.packageType =
        block == 'sale'
          ? 'type_pub_packet'
          : 'type_pub_offer_buy, type_pub_offer_add';
      this.request.orderBy = block == 'sale' ? 'claimsAmount' : 'startingPrice';

      this.top5[block] = await this.publicationsService.getWidgetList(
        this.request
      );
      this.top5[block].map((item: any) => {
        (item.itemPrice = item.price),
          (item.price = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`);
        item.stopDate = formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU');
        item.clubName = ucwords(item.clubName);
        //item.claimsAmount=`RUB ${formatNumber(item.claimsAmount, 'ru-RU', '1.2-2')}`;
        item.claimsAmount =
          block == 'sale'
            ? `RUB ${formatNumber(item.claimsAmount, 'ru-RU', '1.2-2')}`
            : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
        item.publicationId = item.publicationId;
      });
    });

    document.addEventListener('scroll', this.onScroll);
    document.addEventListener('resize', this.onScroll);
  }

  ngOnDestroy() {
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('resize', this.onScroll);
  }

  onScroll = this.throttle(() => {
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const footer = document.querySelector('footer');
    const bottomMenu = document.querySelector<HTMLElement>(
      '.offers-bottom .offers-header'
    );
    const topMenuTop =
      document
        .querySelector<HTMLElement>('.offers-top')
        ?.getBoundingClientRect().top || 0;
    var footerTop = footer?.getBoundingClientRect().top;
    if (footerTop! >= windowHeight) {
      if (window.scrollY < 300 || topMenuTop > 0) {
        bottomMenu?.classList.remove('visible');
      } else {
        bottomMenu?.classList.add('sticky');
        bottomMenu?.classList.add('visible');
      }
    } else {
      bottomMenu?.classList.remove('sticky');
      bottomMenu?.classList.add('visible');
    }
  }, 200);

  throttle(func: any, wait: any) {
    let waiting = false;
    return function () {
      if (waiting) {
        return;
      }
      waiting = true;
      setTimeout(() => {
        func();
        waiting = false;
      }, wait);
    };
  }

  openBody() {
    this.bodyOpened = true;
    setTimeout(() => {
      var element = document.querySelector('.offers-bottom-end');
      element!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }, 200);
  }

  closeBody() {
    this.bodyOpened = false;
    setTimeout(() => {
      var element = document.querySelector('.offers-bottom-end');
      element!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }, 200);
  }

  toggleBody() {
    this.bodyOpened ? this.closeBody() : this.openBody();
  }
  buy(item) {
    this.bw.dealStart(item);
  }
}
