<div *ngIf="pages > 1 && page >= 0 && page < pages" class="pa-pagination df">
  <ul class="pa-pagination__body df aic">
    <li class="pa-pagination__item">
      <a *ngIf="page > 0; else firstDisabled" href="javascript:;" class="pa-pagination__arrow">
        <button type="button" (click)="setPage(0)">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L9 6.97285L15 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7 1L1 6.97285L7 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </a>
      <ng-template #firstDisabled>
        <div class="pa-pagination__arrow">
          <button type="button" disabled>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 1L9 6.97285L15 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M7 1L1 6.97285L7 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </ng-template>
    </li>
    <li class="pa-pagination__item">
      <a *ngIf="page > 0; else prevDisabled" href="javascript:;" class="pa-pagination__arrow">
        <button type="button" (click)="setPage(page - 1)">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1L1 6.97285L7 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </a>
      <ng-template #prevDisabled>
        <div class="pa-pagination__arrow">
          <button type="button" disabled>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 1L1 6.97285L7 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </ng-template>
    </li>
    <li *ngFor="let i of pageNumbers" class="pa-pagination__item" [class.active]="page == i">
      <ng-container *ngIf="i != -1; else period">
        <a *ngIf="page != i; else currentButton" href="javascript:;" (click)="setPage(i)">
          <button type="button">{{i + 1}}</button>
        </a>
        <ng-template #currentButton>
          <button type="button" disabled>{{i + 1}}</button>
        </ng-template>
      </ng-container>
      <ng-template #period>
        <button class="period" type="button" disabled>...</button>
      </ng-template>
    </li>
    <li class="pa-pagination__item">
      <a *ngIf="page < pages - 1; else nextDisabled" href="javascript:;" class="pa-pagination__arrow">
        <button type="button" (click)="setPage(page + 1)">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 6.97285L1 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </a>
      <ng-template #nextDisabled>
        <div class="pa-pagination__arrow">
          <button type="button" disabled>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 6.97285L1 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </ng-template>
    </li>
    <li class="pa-pagination__item">
      <a *ngIf="page < pages - 1; else lastDisabled" href="javascript:;" class="pa-pagination__arrow">
        <button type="button" (click)="setPage(pages - 1)">
          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 6.97285L1 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M9 1L15 6.97285L9 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </a>
      <ng-template #lastDisabled>
        <div class="pa-pagination__arrow">
          <button type="button" disabled>
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 6.97285L1 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9 1L15 6.97285L9 12.9457" stroke="#7A7D86" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </ng-template>
    </li>
  </ul>
</div>