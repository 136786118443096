import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { ExpertOpinion } from 'src/app/features/manager-area/models/expert-opinions/expert-opinion.model';
import { ExpertOpinionsRequest } from 'src/app/features/manager-area/models/expert-opinions/expert-opinions-request.model';
import { ExpertOpinionsResponse } from 'src/app/features/manager-area/models/expert-opinions/expert-opinions-response.model';
import { ExpertOpinionsService } from 'src/app/features/manager-area/services/expert-opinions/expert-opinions.service';
import { SelectOperatorService } from 'src/app/features/personal-area/services/select-operator/select-operator.service';

import { AnAlertComponent } from 'src/app/blocks/modals/an-alert/an-alert.component';
export interface SelectOperatorModel {
  data:any|undefined;
}
//declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function initDropdowns(): void;
@Component({
  selector: 'app-select-operator-modal',
  templateUrl: './select-operator-modal.component.html',
  styleUrls: ['./select-operator-modal.component.css']
})
export class SelectOperatorModalComponent extends SimpleModalComponent<SelectOperatorModel , boolean> implements SelectOperatorModel,OnInit, AfterViewInit  {
  data: any = null;
  response: ExpertOpinionsResponse | null = null;
  params: ExpertOpinionsRequest = new ExpertOpinionsRequest();
  entityId: number = 0;
  entity: ExpertOpinion = new ExpertOpinion();
  siteUrl: string | null = null;
  expertOpinionDescription: string | null = null;
  constructor(
    private mainService: ExpertOpinionsService,
    private secondaryService: SelectOperatorService,
    private modalService:SimpleModalService
  ) {
    super()
   }
  async ngAfterViewInit(): Promise<void> {
    // initDatepicker();
  
    initDropdowns();
  }
   async ngOnInit(): Promise<void> {
    await this.getList();
  }


  async getList(page: number = 0): Promise<void> {
    this.params.page = page;
    this.response = await this.mainService.filter(this.params);
  }

  async sendRequest(): Promise<void> {
    if (!this.entityId) {
      await this.modalService.addModal(AnAlertComponent, { title:"Внимание!", body: "Необходимо выбрать оператора экспертного заключения", ok:"OK" }).toPromise<boolean>();
      //await anAlert("Необходимо выбрать оператора экспертного заключения", "OK");
      return;
    }
    let errors = await this.secondaryService.sendRequest(this.entityId);
    if (errors.size == 0) {
      this.result = true;
      this.close();
    }
    else {
      let msg = "Не удалось отправить запрос оператору экспертного заключения: ";
      msg = msg + errors.entries().next().value[1];
      //anAlert(msg, "OK");
      await this.modalService.addModal(AnAlertComponent, { title:"Ошибка!", body: msg, ok:"OK" }).toPromise<boolean>();

      this.result = false;
      this.close();
    }
  }
  cancelAdding() {
    this.result = false;
    this.close();
  }
  async contactSupport(): Promise<void> {

  }

  async onExpertChange(): Promise<void> {
    this.entity = await this.mainService.get(this.entityId);
    this.siteUrl = this.entity.siteUrl ?? '';
    this.expertOpinionDescription = this.entity.expertOpinionDescription ?? '';
  }

}
