<div id="change-price-modal" class="smodal-content w-480">
  <div class="modal-header">
    <div (click)="cancel()" class="modal-close">
      <svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
      </svg>
    </div>
  </div>

  <div>
    <div class="mw__head">
      <div class="font-alt text-xl text-center">
        Установите срок действия заявки
      </div>
      <div class="divider-sm"></div>
    </div>
    <div class="form__list mb3">
      <div class="form__item" [class.error]="errors.get('newPrice')">
        <div class="form-lbinfo">
          <div class="font-alt font-semibold">
            Введите срок действия заявки
          </div>
        </div>
        <dp-date-picker
          name="expirationDate"
          placeholder="дд.мм.гггг"
          [(ngModel)]="newExpirationDate"
          [ngModelOptions]="{ standalone: true }"
          [config]="dpconfig"
          class="sertificats__input"
          #dayPicker
        ></dp-date-picker>
      </div>
    </div>
  </div>

  <div>
    <button
      [disabled]="loading"
      type="submit"
      class="btn w100 mb-2 h-50"
      (click)="setNewExpirationDate()"
      [attr.data-tooltip]="
        (loading ? 'Загрузка...' : 'Установить срок действия заявки')
      "
      data-position="bottom center"
    >
      {{ (loading ? 'Загрузка...' : 'Установить срок действия заявки') }}
    </button>

    <button
      type="button"
      class="btn btn--dark-outline w100 h-50"
      (click)="cancel()"
    >
      Закрыть
    </button>
  </div>
</div>
