import { formatDate, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BuyWfComponent } from 'src/app/blocks/buy-wf/buy-wf.component';
import { DateDiffService } from 'src/app/front/date-diff.service';
//import { PublicationService } from 'src/app/publication.service';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

import * as html2pdf from 'html2pdf.js';
import { PublicationStatusesEnum } from 'src/app/core/enums/publication-statuses.enum';
import { PublicationTypesEnum } from 'src/app/core/enums/publication-types.enum';
import { environment } from '../../../../environments/environment';

declare var $: any;
declare function ucwords(str: string): string;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {
  publication: any = {};
  fragment: string | null = 'unpublished';
  pathName: string = '';
  pathUrl: string = '';
  defendants: Array<string> = new Array<string>();

  pdfOptions = {
    filename: 'package.pdf',
    margin: 2,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { scale: 2 },
    enableLinks: false,
    jsPDF: { unit: 'em', format: 'a4', orientation: 'portrait' }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private dateDiffService: DateDiffService,
    private translateService: TranslateService,
    public bw: BuyWfComponent
  ) {}

  async ngOnInit() {
    await this.get();
  }

  async get() {
    const id = Number(this.route.snapshot.params['id']);
    this.fragment = this.route.snapshot.fragment;
    let p = await this.publicationsService.getDetailedById(id);
    //debugger;
    if (!p) {
      this.router.navigate(['notfound'], { skipLocationChange: true });
    }

    this.defendants = (p.defendants || '').split(';');

    if (this.fragment === 'unpublished') {
      this.pathName = 'Неопубликованные лоты';
      this.pathUrl = '/personal-area/packages/unpublished';
    } else if (this.fragment === 'published') {
      this.pathName = 'Опубликованные лоты';
      this.pathUrl = '/personal-area/packages/published';
    } else if (this.fragment === 'blocked') {
      this.pathName = 'Блокированные лоты';
      this.pathUrl = '/personal-area/packages/blocked';
    } else if (this.fragment === 'archived') {
      this.pathName = 'Лоты в архиве';
      this.pathUrl = '/personal-area/packages/archived';
    }
    if (p.certCount <= 0) {
      p.certCount = 1;
    }

    const ratingNames = ['A', 'B', 'C'];
    const dealTypeNames = ['Sale', 'Purchase', 'Auction'];
    const seasonNames = ['Other', 'Low', 'Medium', 'High', 'Super High'];

    p.clubNameFormatted = p.clubName; //p.certCount == 1 && p.clubName ? ucwords(p.clubName) : 'Mixed';
    p.packageTypeName = 'One Club'; //p.certCount == 1 ? 'One Club' : 'Mixed';
    p.dealTypeName =
      p.publicationTypeCode == PublicationTypesEnum.Sell
        ? 'Sale'
        : p.publicationTypeCode == PublicationTypesEnum.Auction
        ? 'Auction'
        : 'Purchase'; //dealTypeNames[p.dealTypeId - 1] ?? 'Auction';
    p.packageRatingName = ratingNames[p.packageRating ?? 2];
    p.priceFormatted = `RUB ${formatNumber(p.price, 'ru-RU', '1.2-2')}`;
    p.claimsAmountFormatted = `RUB ${formatNumber(
      p.claimsAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    p.courtCostsFormatted = `RUB ${formatNumber(
      p.courtCosts,
      'ru-RU',
      '1.2-2'
    )}`;

    // p.recommendedPriceFormatted = p.recommendedPrice > 0 ? `USD ${formatNumber(p.recommendedPrice, 'ru-RU', '1.2-2')}` : '';
    p.costForOneCertFormatted =
      p.certCount > 0
        ? `RUB ${formatNumber(p.price / p.certCount, 'ru-RU', '1.2-2')}`
        : '';
    const nowDate = new Date();
    const stopDate = new Date(p.stopDate);
    p.endsIn = {};
    if (stopDate > nowDate) {
      p.endsIn = this.dateDiffService.dateDiff(stopDate);
      const maxDays = 355;
      if (p.endsIn.dayAbbr > maxDays) {
        p.endsIn.dayAbbr = maxDays;
      }
    }
    var lang = this.cookieService.get('locale');
    p.userName = (
      lang == 'RU'
        ? `${p.userSurname || ''} ${p.userName || ''} ${p.userMiddleName || ''}`
        : `${p.userSurnameLatin || ''} ${p.userNameLatin || ''} ${
            p.userMiddleNameLatin || ''
          }`
    ).trim();
    p.userPhoto = p.userPhoto
      ? `${environment.mainUrl}photos/users/${p.userPhoto}`
      : '/assets/img/user-empty.png';
    p.userRatingName = ratingNames[p.userRating] ?? 'A';
    p.userCreateDateFormatted = formatDate(
      p.userCreateDate,
      'dd.MM.yyyy',
      'ru-RU'
    );

    p.expiredIn = Object.entries(p.endsIn).length
      ? Object.entries(p.endsIn)
          .map((entry: any) => {
            return `${entry[1]} ${this.translateService.instant(entry[0])}`;
          })
          .join(' ')
      : this.translateService.instant('Expired');

    if (p.certificates?.length) {
      p.certificates.map((cert: any) => {
        cert.clubNameFormatted = ucwords(cert.clubName);
        cert.certificateIssuingDateFormatted = formatDate(
          cert.certificateIssuingDate,
          'dd.MM.yyyy',
          'ru-RU'
        );
        cert.validUntilFormatted =
          cert.validUntil == null
            ? ''
            : formatDate(cert.validUntil, 'dd.MM.yyyy', 'ru-RU');
        cert.seasonName = seasonNames[cert.season] ?? 'Other';
      });
    }

    this.publication = p;
  }

  preparePdfContent() {
    const doc = $('.html2pdf__container');
    doc.find('.pdf-add').removeClass('hidden');
    doc.find('.pdf-remove').remove();
    doc
      .find('.pdf-shrink')
      .css({ width: '70%', 'margin-right': 'auto', 'margin-left': 'auto' });
    doc.find('a').css({ color: 'inherit', 'text-decoration': 'none' });
  }

  download(event: Event) {
    $('#downloading-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .save()
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }

  print(event: Event) {
    $('#printing-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          pdf.autoPrint();
          $.modal.close();
          setTimeout(() => {
            window.open(pdf.output('bloburl'), '_blank');
          }, 100);
        })
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }

  async buy(event: Event): Promise<void> {
    event.preventDefault();

    if (
      this.publication.publicationTypeCode == PublicationTypesEnum.Sell &&
      this.publication.publicationStatusCode ==
        PublicationStatusesEnum.Published
    ) {
      this.bw.dealStart(this.publication);
    } else if (
      this.publication.publicationTypeCode == PublicationTypesEnum.Buy &&
      this.publication.publicationStatusCode ==
        PublicationStatusesEnum.Published
    ) {
      //todo: add new deal logic
    } else if (
      this.publication.publicationTypeCode == PublicationTypesEnum.Add &&
      this.publication.publicationStatusCode ==
        PublicationStatusesEnum.Published
    ) {
      //todo: add new deal logic
    } else {
      let msg = `Не верный статус или тип лота: ${this.publication.publicationTypeCode}/${this.publication.publicationTypeCode}. Данное действие не возможно.`;

      anAlert(msg, 'OK');
    }
  }
}
