import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-remove-tr',
  templateUrl: './remove-tr.component.html',
  styleUrls: ['./remove-tr.component.css']
})
export class RemoveTrComponent implements OnInit {

  @Input() id: any;

  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(item: any) {
    this.clicked.emit(this.id);
  }
}
