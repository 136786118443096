import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from 'src/app/core/utils/utils';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SelectOperatorService {

  locale = 'RU';

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) {
  }

  async sendRequest(expertId: number | null = null): Promise<Map<string, any>> {
    let errors = new Map<string, any>();

    if (expertId == null) {
      errors.set("error", "no expert id...");

      return errors;
    }
    
    try {
      await this.httpClient.post(
        `${environment.webApiUrl}/personal/expertOpinionRequest`, expertId ).toPromise();
    }
    catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }
}
