export class ExpertOpinion{
  constructor() { }
  expertOpinionId: number = 0;
  
  expertOpinionName: string | null = null;
  expertOpinionNameInEnglish: string | null = null;
  expertOpinionDescription: string | null = null;
  expertOpinionDescriptionInEnglish: string | null = null;
  siteUrl: string | null = null;

  sortOrder: number = 0;
  isDisplayed: boolean = true;
}
