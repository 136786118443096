import { Injectable, OnDestroy } from '@angular/core';

import * as SignalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Publication } from '../../models/publications/publication.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LastCompletedPublicationsService implements OnDestroy {
  private hubConnection: SignalR.HubConnection;
  publications = new Subject<Publication[]>();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    this.stopConnection();
  }

  startConnection(): void {
    this.hubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/signalr/LastCompletedPublications`)
      .build();

    this.hubConnection
      ?.start()
      .then(() => console.log('startConnection => success'))
      .catch((error) => console.log('startConnection => error', error));
  }

  stopConnection(): void {
    this.hubConnection
      ?.stop()
      .then(() => console.log('stopConnection => success'))
      .catch((error) => console.log('stopConnection => error', error));
  }

  addPublicationsListener(): void {
    this.hubConnection.on('ReceiveMessage', (data) => {
      console.log('addPublicationsListener => data', data);
      this.publications.next(data);
    });
  }

  getPublications(): Observable<Publication[]> {
    return this.httpClient.get<Publication[]>(
      `${environment.apiUrl}/api/Publications/LastCompleted`
    );
  }
}
