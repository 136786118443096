import { Component, OnInit, Input } from '@angular/core';
import { Role } from 'src/app/core/enums/role.enum';
import { User } from 'src/app/core/models/auth/user.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';

declare function initAccordion(): any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() active: string = '';

  user: User = new User();

  constructor(
    private authService: AuthService,
  ) {
    this.authService.user.subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    setTimeout(() => {
      initAccordion();
    }, 100);
  }

  get isBroker(): boolean {
    return this.user.role === Role.Broker;
  }

  logout(): void {
    this.authService.logout();
  }
}
