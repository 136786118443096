<div id="modal-card" class="mw modcard">
  <div class="mw__content">
    <div class="modcard__head df jcsb">
      <div class="modcard__info">
        <div class="modcard__title">Лот 484 Altamira</div>
        <p class="modcard__t mb2">
          <span class="modcard__tx">Аукцион</span>
          <span class="modcard__ci">GC$1</span>
        </p>
        <div class="modcard__lbls df aic">
          <div class="modcard__stickers df">
            <div class="sticker h-none">c</div>
            <div class="sticker h-none">b</div>
            <div class="sticker h-none">1</div>
          </div>
          <div class="modcard__favorite">
            <span class="material-icons fz24 c-red ">favorite</span>
          </div>
        </div>
      </div>
      <div class="modcard__picture">
        <img src="/assets/img/card.jpg" alt="" width="240" height="170" class="modcard__img">
      </div>
    </div>
    <div class="line bg-wt mt2 mb2"></div>

    <div class="modcard__body">
      <dl class="df aibl mb1">
        <dt>Стартовая цена аукциона: </dt>
        <dd>1</dd>
      </dl>
      <dl class="df aibl mb1">
        <dt>Цена закрытия аукциона: </dt>
        <dd>GC$ 100</dd>
      </dl>
      <dl class="df aibl mb1">
        <dt>Клуб: </dt>
        <dd>Клуб тест</dd>
      </dl>
      <dl class="df aibl mb1">
        <dt>Тип лота: </dt>
        <dd>1 клуб</dd>
      </dl>
      <dl class="df aibl mb1">
        <dt>Старт аукциона: </dt>
        <dd>23.09.21</dd>
      </dl>
      <dl class="df aibl">
        <dt>Осталось до конца аукциона: </dt>
        <dd>1 467 д 20 ч 36 мин</dd>
      </dl>
    </div>

    <div class="line bg-wt mt2 mb2"></div>

    <div class="modcard__footer df aic jcsb">
      <div class="modcard__nav df aic">
        <button type="button" class="feather-icon icon-printer modcard__icon mr2 fz24 c-link"></button>

        <button type="button" class="material-icons modcard__icon fz24 c-link">save_alt</button>
      </div>
      <div class="modcard__links df aic">
        <a href="" class="btn btn_wt mr2 c-link" rel="modal:close">Закрыть</a>
        <a href="" class="btn c-wh bg-dark h-bg-blue c-link" rel="modal:close">К аукциону</a>
      </div>
    </div>
  </div>
</div>