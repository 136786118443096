import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { BehaviorSubject, EMPTY, from, Observable } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { StaticContentType } from '../../static-content.constant';
import { StaticContentService } from './../../services/static-content.service';
@Component({
  selector: 'app-static-content-component',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentComponent implements OnInit, OnDestroy {
  @Input()
  public contentId = 0;

  @Input()
  public contentType: StaticContentType = StaticContentType.HTML;

  public contentTypes = StaticContentType;

  public content$: Observable<string> = EMPTY;

  public pdfContent$: Observable<Blob> = EMPTY;

  public loadingSubject$ = new BehaviorSubject(false);

  public isLoading$ = this.loadingSubject$.asObservable();

  constructor(
    private staticContentService: StaticContentService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // ToDo: refactoring
    this.content$ = this.staticContentService.getContent$(this.contentId);
    // this.pdfContent$ = this.getPDFContent$();
  }

  ngOnDestroy(): void {
    this.loadingSubject$.complete();
  }

  public getPDFContent$(): Observable<Blob> {
    const pdfOptions = {
      filename: `offers.pdf`,
      margin: [1, 1, 1, 1],
      pagebreak: { mode: 'avoid-all' },
      image: { type: 'jpeg', quality: 0.95 },
      html2canvas: { scale: 1 },
      enableLinks: true,
      jsPDF: { unit: 'cm', format: 'a2', orientation: 'portrait' }
    };

    const getPDF = async (element: string): Promise<Blob> => {
      return html2pdf().set(pdfOptions).from(element).output('blob');
    };

    return this.staticContentService.getContent$(this.contentId).pipe(
      tap(() => {
        this.loadingSubject$.next(true);
        this.cdr.markForCheck();
      }),
      switchMap((element) => from(getPDF(element))),
      finalize(() => {
        this.loadingSubject$.next(false);
        this.cdr.markForCheck();
      })
    );
  }
}
