import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
export interface ActivatePublicationModel {
  data:any|undefined;
}
@Component({
  selector: 'app-activate-publication-modal',
  templateUrl: './activate-publication-modal.component.html',
  styleUrls: ['./activate-publication-modal.component.css']
})
export class ActivatePublicationModalComponent  extends SimpleModalComponent<ActivatePublicationModel , boolean> implements ActivatePublicationModel , OnInit {
  data: any = null;

  constructor() { 
    super()
  }

  ngOnInit(): void {
  }
  Accept() {
    this.result = true;
    this.close();
  }
  Cancel() {
    this.result = false;
    this.close();
  }
}
