<div id="change-price-modal" class="smodal-content w-480">
  <div class="modal-header">
    <div (click)="cancel()" class="modal-close">
      <svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
      </svg>
    </div>
  </div>

  <div>
    <div class="mw__head">
      <div class="font-alt text-xl text-center">
        Изменение НДФЛ
      </div>
      <div class="divider-sm"></div>
    </div>
    <div class="form__list mb3">
      <div class="form__item">
        <div class="form-lbinfo">
          <div class="font-alt font-semibold">
            Введите новый размер НДФЛ для удержания
          </div>
        </div>
        <input
          type="number"
          name="newTax"
          [(ngModel)]="newTax"
          min="0"
          class="form__input"
          placeholder="Введите новый размер НДФЛ для удержания"
        />
      </div>
    </div>
  </div>

  <div>
    <button
      [disabled]="loading"
      type="submit"
      class="btn w100 mb-2 h-50"
      (click)="setNewTax()"
      [attr.data-tooltip]="loading ? 'Загрузка...' : 'Установить'"
      data-position="bottom center"
    >
      {{ loading ? 'Загрузка...' : 'Установить' }}
    </button>

    <button
      type="button"
      class="btn btn--dark-outline w100 h-50"
      (click)="cancel()"
    >
      Закрыть
    </button>
  </div>
</div>
