<div class="page" data-attr="private-area">
  <div class="md main">
    <app-packages-slider></app-packages-slider>
    <app-breadcrumbs
      [links]="[
        ['/', 'Main page'],
        ['/personal-area', 'Personal area'],
        [pathUrl, pathName],
        [
          '',
          (publication.publicationTypeCode == 'type_pub_packet'
            ? 'Лот '
            : 'Заявка ') + publication.publicationNumber
        ]
      ]"
    ></app-breadcrumbs>
    <!--['', 'Лот ' + publication.packageId ]-->
    <ng-container *ngIf="publication">
      <div class="package-content">
        <div class="package-info pdf-container pdf-shrink">
          <div
            class="hidden pdf-add pb2 mb3 w100"
            style="border-bottom: 1px solid #235"
          >
            <img src="/assets/img/logo-all_dark.svg" alt="" />
            <div class="ff:CormorantInfant fz18 mt1">www.tst-trade.ru</div>
          </div>

          <div class="package__title">
            {{
              publication.publicationTypeCode == 'type_pub_packet'
                ? 'Лот '
                : 'Заявка '
            }}
            {{ publication.publicationNumber }}
            <span>{{ publication.publicationTypeCode }}</span>
          </div>

          <div class="package-info__list">
            <div class="dl">
              <span class="dl__dt" data-position="bottom center">
                {{ 'Club' | translate }}
              </span>
              <span class="dl__points"></span>
              <a
                routerLink="/clubs/{{ publication.clubId }}"
                class="dl__dd link"
                data-position="top center"
                [attr.data-tooltip]="publication.clubNameFormatted | translate"
                *ngIf="publication.certCount == 1; else clubNameSpan"
              >
                {{ publication.clubNameFormatted | translate }}
              </a>
              <ng-template #clubNameSpan>
                <span class="dl__dd">
                  {{ publication.clubNameFormatted | translate }}
                </span>
              </ng-template>
            </div>
            <div
              class="dl"
              *ngIf="publication.publicationTypeCode == 'type_pub_packet'"
            >
              <span class="dl__dt">
                {{ 'Package type' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.packageTypeName | translate }}
              </span>
            </div>
            <div class="dl">
              <span class="dl__dt">
                {{ 'Deal type' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.dealTypeName | translate }}
              </span>
            </div>
            <div class="dl">
              <span class="dl__dt">
                {{ 'Aggregate package rating' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.packageRatingName }}
              </span>
            </div>
            <div class="dl">
              <span class="dl__dt">
                {{ 'Package cost' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.priceFormatted }}
              </span>
            </div>

            <div class="dl">
              <span class="dl__dt" data-position="bottom center">
                <!-- Сумма исковых требований -->
                {{ 'The amount of the claims' | translate }}
              </span>
              <span class="dl__points"></span>
              <span
                class="dl__dd"
                data-position="bottom center"
                [attr.data-tooltip]="publication.claimsAmountFormatted"
              >
                {{ publication.claimsAmountFormatted }}
              </span>
            </div>
            <div
              class="dl"
              *ngIf="publication.publicationTypeCode == 'type_pub_packet'"
            >
              <span class="dl__dt" data-position="bottom center">
                <!-- Сумма судебных издержек -->
                {{ 'Amount of court costs' | translate }}
              </span>
              <span class="dl__points"></span>
              <span
                class="dl__dd"
                data-position="bottom center"
                [attr.data-tooltip]="publication.courtCostsFormatted"
              >
                {{ publication.courtCostsFormatted }}
              </span>
            </div>

            <!-- <div class="dl">
              <span class="dl__dt" data-position="bottom center">
                {{'Recommended package price' | translate}}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd" data-position="bottom center"
                [attr.data-tooltip]="publication.recommendedPriceFormatted">
                {{publication.recommendedPriceFormatted}}
              </span>
            </div> -->
            <!-- <div class="dl">
              <span class="dl__dt" data-position="bottom center">
                {{'Package gain' | translate}}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd" data-position="bottom center" [attr.data-tooltip]="publication.coefPlus">
                {{publication.coefPlus}}
              </span>
            </div> -->
            <div
              class="dl"
              *ngIf="publication.publicationTypeCode == 'type_pub_packet'"
            >
              <span
                class="dl__dt"
                [innerHTML]="
                  'Average cost of one<br>certificate in a package' | translate
                "
              ></span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.costForOneCertFormatted }}
              </span>
            </div>
            <div
              class="dl"
              *ngIf="publication.publicationTypeCode == 'type_pub_packet'"
            >
              <span class="dl__dt">
                {{ 'Number of certificates in package' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.certCount }}
              </span>
            </div>
            <!-- <div class="dl">
              <span class="dl__dt">
                {{'Sale ends in' | translate}}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{publication.expiredIn}}
              </span>
            </div> -->
            <div
              class="dl"
              *ngIf="publication.publicationTypeCode == 'type_pub_packet'"
            >
              <span class="dl__dt">
                {{ 'Comment' | translate }}
              </span>
              <span class="dl__points"></span>
              <span class="dl__dd">
                {{ publication.comment }}
              </span>
            </div>
          </div>

          <div class="dl">
            <span class="dl__dt" data-position="bottom center">
              {{ 'Defendants' | translate }}
            </span>
            <span class="dl__points"></span>
            <a
              routerLink="/clubs/{{ publication.clubId }}"
              class="dl__dd link"
              data-position="top center"
              [attr.data-tooltip]="publication.clubNameFormatted | translate"
              *ngIf="publication.certCount == 1; else clubNameSpan"
            >
              {{ publication.clubNameFormatted | translate }}
            </a>
            <ng-template #clubNameSpan>
              <span class="dl__dd">
                {{ publication.clubNameFormatted | translate }}
              </span>
            </ng-template>
          </div>

          <!--<ul class="defendants-list">
          <li class="defendants-list__item defendants-list__caption">Список ответчиков:</li>
          <li *ngFor="let x of defendants">{{x}}</li>-->
          <!--<li class="defendants-list__item"><a href="#" class="defendants-list__link">Altenbury</a></li>
          <li class="defendants-list__item"><a href="#" class="defendants-list__link">Allince club</a></li>-->
          <!--</ul>-->
          <div class="participant__footer package-info__footer dn auth-df">
            <div class="package-detail__option participant__nav df aic">
              <button
                type="button"
                class="d-inline-flex mr-15 h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Добавить"
              >
                <svg width="22" height="22" class="ic-round ic-stroke">
                  <use xlink:href="#plus-circle"></use>
                </svg>
              </button>
              <button
                type="button"
                class="d-inline-flex mr-15 h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Редактировать"
              >
                <svg width="24" height="24" class="ic-round ic-stroke">
                  <use xlink:href="#edit"></use>
                </svg>
              </button>
              <button
                type="button"
                class="d-inline-flex mr-15 h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Файл"
              >
                <svg width="22" height="22" class="ic-round ic-stroke">
                  <use xlink:href="#disable-circle"></use>
                </svg>
              </button>
              <button
                type="button"
                class="d-inline-flex mr-15 h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Файл"
              >
                <svg width="18" height="22" class="ic-round ic-stroke">
                  <use xlink:href="#file"></use>
                </svg>
              </button>
              <button
                type="button"
                class="d-inline-flex mr-15 h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Скачать"
              >
                <svg width="24" height="24" class="ic-round ic-stroke">
                  <use xlink:href="img/sprite.svg#card-download"></use>
                </svg>
              </button>
              <button
                type="button"
                class="d-inline-flex h-orange h-light:c-blue"
                data-position="bottom center"
                data-tooltip="Печать"
              >
                <svg width="24" height="24" class="ic-round ic-stroke">
                  <use xlink:href="img/sprite.svg#card-printer"></use>
                </svg>
              </button>
            </div>
            <div class="package-detail__actions">
              <a
                href="javascript:void(0)"
                ng-show="publication.publicationStatusCode == 'pub_published'"
                class="btn btn--primary mb-10 text-xs text-nowrap"
                data-position="top center"
                (click)="buy($event)"
                data-tooltip="Продать"
              >
                Продать
              </a>
              <a
                href="javascript:void(0)"
                ng-show="publication.publicationStatusCode == ''"
                class="btn btn--primary mb-10 text-xs text-nowrap"
                data-position="top center"
                (click)="buy($event)"
                data-tooltip="Выставить на аукцион"
              >
                Выставить на аукцион
              </a>
              <a
                href="javascript:void(0)"
                ng-show="
                  publication.publicationStatusCode == 'pub_no_published'
                "
                class="btn btn--primary mb-10 text-xs text-nowrap"
                data-position="top center"
                (click)="buy($event)"
                data-tooltip="Перенести в архив"
              >
                Перенести в архив
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn--primary text-xs text-nowrap"
                data-position="top center"
                data-tooltip="Редактировать"
              >
                Редактировать
              </a>
            </div>
          </div>
        </div>
        <div class="package-owner pdf-container pdf-shrink">
          <div
            class="hidden pdf-add mb3 pb2"
            style="border-bottom: 1px solid #235"
          >
            <img src="/assets/img/logo-all_dark.svg" alt="" />
            <div class="ff:CormorantInfant fz18 mt1">www.t-st.group</div>
          </div>
          <div class="package__title hidden pdf-add" style="font-size: 4.2rem">
            {{ 'Package owner' | translate }}
          </div>
          <div class="package__title pdf-remove">
            {{ 'Package owner' | translate }}
          </div>

          <div class="package__participant">
            <div class="participant">
              <div class="participant__head">
                <div class="participant__avatar avatar">
                  <img
                    src="{{ publication.userPhoto }}"
                    alt="{{ publication.userName }}"
                    class="avatar__img"
                  />
                </div>
                <div class="participant__desc">
                  <div class="participant__name">
                    <span
                      data-position="bottom center"
                      [attr.data-tooltip]="publication.userName"
                      data-position="top center"
                    >
                      {{ publication.userName }}
                    </span>
                  </div>
                  <div class="participant__post">
                    <span
                      data-position="bottom center"
                      [attr.data-tooltip]="publication.userRole | translate"
                    >
                      {{ publication.userRole | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="participant__body">
                <div class="dl">
                  <div class="dl__dt">
                    {{ 'Rating' | translate }}
                  </div>
                  <div class="dl__points"></div>
                  <div class="dl__dd">
                    {{ publication.userRatingName }}
                  </div>
                </div>
                <div class="dl">
                  <div class="dl__dt">
                    {{ 'Number of publications' | translate }}
                  </div>
                  <div class="dl__points"></div>
                  <div class="dl__dd">
                    {{ publication.userPublicationsCount }}
                  </div>
                </div>
                <div class="dl">
                  <div class="dl__dt">
                    {{ 'Registration date' | translate }}
                  </div>
                  <div class="dl__points"></div>
                  <div class="dl__dd">
                    {{ publication.userCreateDateFormatted }}
                  </div>
                </div>
              </div>
              <div class="participant__footer auth-df pdf-remove">
                <div class="participant__nav df aic">
                  <button
                    type="button"
                    class="mr2 h-orange h-light:c-blue"
                    (click)="download($event)"
                  >
                    <svg
                      class="ic ic-card-download ic-round ic-stroke"
                      data-position="bottom center"
                      [attr.data-tooltip]="'Download' | translate"
                    >
                      <use
                        xlink:href="/assets/img/sprite.svg#card-download"
                      ></use>
                    </svg>
                  </button>
                  <button
                    type="button"
                    class="mr2 h-orange h-light:c-blue"
                    (click)="print($event)"
                  >
                    <svg
                      class="ic ic-card-printer ic-round ic-stroke"
                      data-position="bottom center"
                      [attr.data-tooltip]="'Print' | translate"
                    >
                      <use
                        xlink:href="/assets/img/sprite.svg#card-printer"
                      ></use>
                    </svg>
                  </button>
                  <button
                    type="button"
                    class="mr2 h-orange h-light:c-blue dn svg-message"
                    data-position="bottom center"
                    [attr.data-tooltip]="'Write a message' | translate"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z"
                        stroke="#223355"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div class="participant__favorite">
                  <div class="add-favorite">
                    <label class="c-pointer add-favorite__label" data-favorite>
                      <input type="checkbox" class="add-favorite__input" />
                      <svg
                        class="ic ic-add-favorite add-favorite__icon"
                        data-position="bottom center"
                        [attr.data-tooltip]="'Add to favorites' | translate"
                      >
                        <use
                          xlink:href="/assets/img/sprite.svg#add-favorite"
                        ></use>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="publication.certificates?.length">
        <div class="sertificate-header">
          <div class="stitle">{{ 'Package certificates' | translate }}:</div>
          <div class="nav-btns df mla">
            <a href="#" class="nav-btns__btn is-active">Права требований лота</a>
            <a href="#" class="nav-btns__btn">История операций</a>
          </div>
        </div>

        <div class="table table_package table_select fixed">
          <div class="scroll-table">
            <table>
              <thead>
                <tr>
                  <th style="width: 100px"><span>Id</span></th>
                  <th style="width: 200px">
                    <span>{{ 'Club' | translate }}</span>
                  </th>
                  <th style="width: 200px">
                    <span
                      [innerHTML]="
                        'Date of issue<br>of the certificate' | translate
                      "
                    ></span>
                  </th>
                  <th style="width: 200px">
                    <span
                      [innerHTML]="
                        'Membership suspension<br>date (year)' | translate
                      "
                    ></span>
                  </th>
                  <th style="width: 100px">
                    <span>{{ 'Season' | translate }}</span>
                  </th>
                  <th style="width: 100px">
                    <span [innerHTML]="'Apartment<br>type' | translate"></span>
                  </th>
                  <th style="width: 200px">
                    <span
                      [innerHTML]="'Certificate selling price, USD' | translate"
                    ></span>
                  </th>
                  <th style="width: 300px">
                    <span
                      [innerHTML]="
                        'Total cost of timeshare in contract currency, USD'
                          | translate
                      "
                    ></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let cert of publication.certificates">
                  <tr class="pseudo">
                    <td colspan="8"></td>
                  </tr>
                  <tr>
                    <td>
                      <span>{{ cert.id }}</span>
                    </td>
                    <td>
                      <span class="table__club">
                        <a
                          routerLink="/clubs/{{ cert.clubId }}"
                          class="table__club-tx link"
                          data-position="top center"
                          [attr.data-tooltip]="cert.clubNameFormatted"
                        >
                          {{ cert.clubNameFormatted }}
                        </a>
                      </span>
                    </td>
                    <td>
                      <span>{{ cert.certificateIssuingDateFormatted }}</span>
                    </td>
                    <td>
                      <span>{{ cert.validUntilFormatted }}</span>
                    </td>
                    <td>
                      <span>{{ cert.seasonName }}</span>
                    </td>
                    <td>
                      <span>{{ cert.apartmentTypeName }}</span>
                    </td>
                    <td>
                      <span>{{ cert.price }}</span>
                    </td>
                    <td>
                      <span>{{ cert.fullCostGC }}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<app-top5-bottom></app-top5-bottom>

<div id="downloading-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for download...' | translate }}
      </div>
    </div>
  </div>
</div>

<div id="printing-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{ 'Preparing for print...' | translate }}
      </div>
    </div>
  </div>
</div>
