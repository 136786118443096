import { Component } from '@angular/core';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { SimpleModalComponent } from "ngx-simple-modal";
import { Router } from '@angular/router';


export interface ChangePriceModel {
  maxPrice:number;
  balance:number;
  publicationNumber:number;
}

declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;

@Component({
  selector: 'app-depositing-funds-modal',
  templateUrl: './depositing-funds-modal.component.html',
  styleUrls: ['./depositing-funds-modal.component.css']
})
export class DepositingFundsModalComponent extends SimpleModalComponent<ChangePriceModel  , number> implements ChangePriceModel {


  errors: Map<string, any> = new Map<string, any>();
  loading: boolean = false;


  constructor(
    private publicationsService: PublicationsService,
    private router: Router,
  ) { 
    super();
  }
  publicationNumber: number;
  maxPrice:number;
  enterSumm:number;
  balance: number;


  async setSum(): Promise<void> {
  
     
    if (this.enterSumm === undefined ||this.enterSumm < 1000 ||   this.enterSumm > this.maxPrice)  {
      this.errors.set('errorSum', 'Укажите cумма вносимых Вами средств не меньше 1000 и не больше допустимой для данной Заявки.');
    }
     
    if (!this.errors.size) {
      this.result = this.enterSumm
      this.close();
    } 

   
  }

  changeSum(){
      this.errors.delete('errorSum');
      this.errors.delete('errorBalance')
      
      if(this.balance< (+(parseFloat(this.enterSumm + '' ) +
      parseFloat(this.enterSumm + '' ) * 0.09))){
        this.errors.set('errorBalance', 'Сумма вносимых вами средств <b>превышает доступные</b> на Вашем балансе.');
        
      }
 
  }

  setBalance(){
    this.router.navigate(['/personal-area/balance'])
    this.close()
  }

  cancel() {
    this.result = -1;
    this.close();
  }

}
