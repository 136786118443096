<div class="mb-10 fz16 font-medium">{{ 'tradesPulse' | translate }}</div>
<ngx-slick-carousel
  (init)="sliderInit($event)"
  [config]="sliderConfig"
  [class.hidden]="!isSliderInit"
>
  <div
    *ngFor="let publication of publications"
    ngxSlickItem
    class="package-list__container"
  >
    <div class="package-list__item">
      <div class="dl">
        <span class="dl__dt">{{ 'soldOut' | translate }}</span>
        <span class="dl__dd">{{ publication.price | currencyRuble }}</span>
      </div>

      <div class="dl">
        <span class="dl__dt">{{ 'Defendant' | translate }}</span>
        <a routerLink="/clubs/{{ publication.clubId }}" class="dl__dd ellipsis">
          {{ publication.clubName }}
        </a>
      </div>
    </div>
  </div>
</ngx-slick-carousel>
