import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { SimpleModalComponent } from "ngx-simple-modal";
import { PublicationChangeExpirationDate } from 'src/app/core/models/publications/publication-change-expiration-date.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
@Component({
  selector: 'app-expiration-date-modal',
  templateUrl: './change-expiration-date-modal.component.html',
  styleUrls: ['./change-expiration-date-modal.component.css']
})
export class ChangeExpirationDateModalComponent  extends SimpleModalComponent<PublicationChangeExpirationDate, boolean> implements PublicationChangeExpirationDate ,OnInit, OnDestroy  {
  subscriptions: any;

  constructor(
    private publicationsService: PublicationsService,
    private router: Router,
  ) { 
    super();
  }
  publicationId: number = 0;
  newExpirationDate: string | null = null;
  errors: Map<string, any> = new Map<string, any>();
  loading: boolean = false;
  dpconfig!: IDatePickerConfig;

  ngOnInit(): void {
    this.options.autoFocus = false;
    this.dpconfig = {
      returnedValueType: ECalendarValue.String,
      format: 'DD.MM.YYYY',
      monthFormat: 'MMMM YYYY',
      firstDayOfWeek: 'mo',
      weekDayFormat: 'dd',
      min: dayjs().format('DD.MM.YYYY'),
      max: dayjs().add(30, 'day').format('DD.MM.YYYY')
    };
    if (!this.newExpirationDate) {
      this.newExpirationDate = dayjs().add(1, 'day').format('DD.MM.YYYY');
    }
  }
  ngOnDestroy(): void {
  }
  
  async setNewExpirationDate(): Promise<void> {
    this.loading = true;

    this.errors = await this.publicationsService.updateExpirationDate({publicationId: this.publicationId, newExpirationDate: this.newExpirationDate});

    this.loading = false;

    if (!this.errors.size) {
      this.result = true;
      this.close();
    }
    else {
      anAlert("Не удалось обновить срок действия заявки: " + this.errors.entries().next().value[1], "OK");
      this.result = false;
      this.close();
    }
  }
  cancel() {
    this.result = false;
    this.close();
  }
}
