import { DOCUMENT, formatDate, formatNumber } from '@angular/common';
import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';

//import { PublicationService } from 'src/app/publication.service';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

import * as html2pdf from 'html2pdf.js';
import { DateDiffService } from 'src/app/front/date-diff.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare function cardAnimations(params: string): any;
declare function initAccordion(): any;
declare function ucwords(str: string): string;

@Component({
  selector: 'app-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.css']
})
export class CardsListComponent implements OnInit {
  @Input() dealType: string = '1,2,3';
  @Input() clubId: number = 0;
  @Input() showMoreButton: boolean = false;
  @Input() searchTerm: string | null = '';

  page: number = 0;
  publications: Publication[] = [];
  request: PublicationsRequest = new PublicationsRequest();

  pdfOptions = {
    filename: 'package.pdf',
    margin: 2,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { scale: 2 },
    enableLinks: false,
    jsPDF: { unit: 'em', format: 'a4', orientation: 'portrait' }
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private dateDiffService: DateDiffService
  ) {}

  async getPublications(page = 0) {
    this.request.page = page;
    this.request.orderBy = 'id';
    this.request.orderWay = 'desc';
    this.request.limit = 10;
    this.request.clubId = this.clubId;
    this.request.searchTerm = this.searchTerm ?? '';
    this.request.onlyActual = 1;

    const publications = await this.publicationsService.getWidgetList(
      this.request
    );
    const ratings = ['A', 'B', 'C'];
    publications.map((item: Publication) => {
      item.dealTypeName =
        item.dealTypeId == 1
          ? 'Sale'
          : item.dealTypeId == 2
          ? 'Purchase'
          : 'Auction';
      item.startingPriceFormatted = !item.startingPrice
        ? ''
        : `USD ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
      item.priceFormatted = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`;
      item.startDateFormatted = formatDate(
        item.startDate,
        'dd.MM.yyyy',
        'ru-RU'
      );
      item.userRatingName = ratings[item.userRating] ?? 'A';
      item.packageRatingName = ratings[item.packageRating ?? 2];

      if (item.certCount <= 0) {
        item.certCount = 1;
      }

      item.clubNameFormatted =
        item.certCount == 1 && item.clubName ? ucwords(item.clubName) : 'Mixed';
      item.packageType = item.certCount == 1 ? 'One Club' : 'Mixed';

      item.costForOneCertFormatted = `RUB ${formatNumber(
        item.price / item.certCount,
        'ru-RU',
        '1.2-2'
      )}`;

      const nowDate = new Date();
      const stopDate = new Date(item.stopDate);
      item.endsIn = {};
      if (stopDate > nowDate) {
        item.endsIn = this.dateDiffService.dateDiff(stopDate);
        const maxDays = 355;
        if (item.endsIn.dayAbbr > maxDays) {
          item.endsIn.dayAbbr = maxDays;
        }
      }

      item.clubPhoto =
        environment.mainUrl +
        (item.clubPhoto
          ? `photos/clubs/${item.clubPhoto}`
          : 'assets/img/image-empty.png');
    });

    return publications;
  }

  async ngOnInit() {
    this.seeMore();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.searchTerm !== undefined &&
      changes.searchTerm?.isFirstChange() === false
    ) {
      this.page = 0;
      this.publications = [];
      this.showMoreButton = true;
      this.seeMore();
    }
  }

  async seeMore() {
    const publications = await this.getPublications(this.page++);
    if (publications.length < 10) {
      this.showMoreButton = false;
    } else if (!publications.length) {
      return;
    }
    this.publications = this.publications.concat(publications);
    setTimeout(() => {
      initAccordion();
      cardAnimations('.mcards__item');
    }, 500);
  }

  preparePdfContent() {
    const doc = $('.html2pdf__container');
    doc.find('.pdf-add').removeClass('hidden');
    doc.find('.pdf-remove').remove();
    doc
      .find('.pdf-shrink')
      .css({ width: '70%', 'margin-right': 'auto', 'margin-left': 'auto' });
    doc.find('a').css({ color: 'inherit', 'text-decoration': 'none' });
  }

  download(event: Event) {
    $('#downloading-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .save()
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }

  print(event: Event) {
    $('#printing-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => {
          this.preparePdfContent();
        })
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          pdf.autoPrint();
          $.modal.close();
          setTimeout(() => {
            window.open(pdf.output('bloburl'), '_blank');
          }, 100);
        })
        .finally(() => {
          setTimeout(() => {
            $.modal.close();
          }, 100);
        });
    }, 100);
  }
}
