<div
  class="static-content-component"
  [ngClass]="{
    'static-content-component--is-loading': isLoading$ | async,
    'static-content-component--pdf': contentType === contentTypes.PDF
  }"
>
  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="contentTypes.PDF">
      <ng-container *ngIf="pdfContent$ | async as pdfBlob">
        <ngx-extended-pdf-viewer
          [minHeight]="'100vh'"
          [src]="pdfBlob"
          [sidebarVisible]="true"
        ></ngx-extended-pdf-viewer>
      </ng-container>
    </ng-container>
    <div *ngSwitchDefault [innerHTML]="content$ | async"></div>
  </ng-container>

  <svg
    *ngIf="
      (contentType !== contentTypes.PDF && !(content$ | async)) ||
      (contentType === contentTypes.PDF && !(isLoading$ | async))
    "
    class="spinner"
    viewBox="0 0 50 50"
  >
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
