import { Component, OnInit } from '@angular/core';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsResponse } from 'src/app/core/models/publications/publications-response.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { Utils } from 'src/app/core/utils/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicationStatusesEnum } from 'src/app/core/enums/publication-statuses.enum';

declare function initAccordion(): void;
declare function initDropdowns(): void;
declare function initRangeSliders(edges: any): void;
declare function resetRangeSliders(): void;

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {

  h1: string = 'Published';
  noResultsText: string = 'No unpublished packages.';
  request: PublicationsRequest = new PublicationsRequest();
  response!: PublicationsResponse;

  userRatings: any[] = [];
  userRatingsAllSelected: boolean = false;
  packageRatings: any[] = [];
  packageRatingsAllSelected: boolean = false;
  slidersInitialValues: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private publicationsService: PublicationsService,
    private utils: Utils,
    private router: Router,
  ) {
    this.userRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false },
    ];
    this.packageRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false },
    ];
  }

  async ngOnInit() {
    this.request.packageState = PublicationStatusesEnum.Published;

    this.activatedRoute.data.subscribe(data => {
      if (data.type == 'published') {
        this.h1 = 'Published';
        this.request.packageState = PublicationStatusesEnum.Published;
        this.noResultsText = 'No published packages.';
      } else if (data.type == 'unpublished') {
        this.h1 = 'Unpublished';
        this.request.packageState = PublicationStatusesEnum.Unpublished;
        this.noResultsText = 'No unpublished packages.';
      } else if (data.type == 'blocked') {
        this.h1 = 'Blocked';
        this.request.packageState = PublicationStatusesEnum.Blocked;
        this.noResultsText = 'No blocked packages.';
      } else if (data.type == 'archived') {
        this.h1 = 'Archived packages';
        this.request.packageState = PublicationStatusesEnum.Archived;
        this.noResultsText = 'No archived packages.';
      }
    });

    initAccordion();
    initDropdowns();

    await this.getList();
    this.request.minPrice = this.response?.minPrice ?? 0;
    this.request.maxPrice = this.response?.maxPrice ?? 1_000_000_000;
    this.request.minCertPrice = this.response?.minCertPrice ?? 0;
    this.request.maxCertPrice = this.response?.maxCertPrice ?? 1_000_000_000;
    this.request.minCertQuantity = this.response?.minCertQuantity ?? 0;
    this.request.maxCertQuantity = this.response?.maxCertQuantity ?? 1_000_000;
    this.slidersInitialValues = {
      minPrice: this.request.minPrice,
      maxPrice: this.request.maxPrice,
      minCertPrice: this.request.minCertPrice,
      maxCertPrice: this.request.maxCertPrice,
      minCertQuantity: this.request.minCertQuantity,
      maxCertQuantity: this.request.maxCertQuantity,
    }
    initRangeSliders(this.slidersInitialValues);
  }

  async getList() {    
    this.request.onlyMy = 0;
    this.request.limit = 10000;
    this.request.onlyPublished = false;
    this.request.minCertPrice = 0;
    this.request.minPrice = 0;
    try {
      this.response = await this.publicationsService.getPublicList(this.request);
    }
    catch (ex) {
      console.error(ex);
    }
    setTimeout(() => {
      initAccordion();
    }, 100);
  }

  onUserRatingsChange() {
    this.request.userRatings = this.userRatings.filter(x => x.selected).map(x => x.id).join();
    this.userRatingsAllSelected = this.userRatings.every(x => x.selected);
  }

  onPackageRatingsChange() {
    this.request.packageRatings = this.packageRatings.filter(x => x.selected).map(x => x.id).join();
    this.packageRatingsAllSelected = this.packageRatings.every(x => x.selected);
  }

  onChangeAllUserRatings() {
    this.userRatings.map(x => x.selected = this.userRatingsAllSelected);
    this.request.userRatings = this.userRatings.filter(x => x.selected).map(x => x.id).join();
  }

  onChangeAllPackageRatings() {
    this.packageRatings.map(x => x.selected = this.packageRatingsAllSelected);
    this.request.packageRatings = this.packageRatings.filter(x => x.selected).map(x => x.id).join();
  }

  onResetFilterClick() {
    this.userRatings.map(x => x.selected = false);
    this.packageRatings.map(x => x.selected = false);
    this.request.userRatings = '';
    this.request.packageRatings = '';
    this.request.clubName = '';
    this.request.packageType = ' ';
    this.request.minPrice = this.slidersInitialValues.minPrice;
    this.request.maxPrice = this.slidersInitialValues.maxPrice;
    this.request.minCertPrice = this.slidersInitialValues.minCertPrice;
    this.request.maxCertPrice = this.slidersInitialValues.maxCertPrice;
    this.request.minCertQuantity = this.slidersInitialValues.minCertQuantity;
    this.request.maxCertQuantity = this.slidersInitialValues.maxCertQuantity;
    resetRangeSliders();
    initDropdowns();
  }

  async onFilterSubmit() {
    const filterElement = document.querySelector('.filter') as HTMLElement;
    const headerElement = document.querySelector('.header') as HTMLElement;
    const position = filterElement.offsetTop - headerElement.offsetHeight - 32
    this.utils.scrollToTop(position);
    this.utils.closeAccordion();
    await this.getList();
  }

  async addNewCertificate() {
    this.router.navigateByUrl("/personal-area/add-certificate");
  }
}
