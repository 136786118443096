import { Component } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
import { FinancialStatisticsService } from 'src/app/features/personal-area/services/financial-statistics/financial-statistics.service';

export interface ChangeTaxModel {
  financialTransactionId: number;
}

@Component({
  selector: 'app-change-tax-modal',
  templateUrl: './change-tax-modal.component.html',
  styleUrls: ['./change-tax-modal.component.css']
})
export class ChangeTaxModalComponent extends SimpleModalComponent<ChangeTaxModel, number | null> implements ChangeTaxModel  {
  subscriptions: any;

  constructor(
    private service: FinancialStatisticsService,
  ) { 
    super();
  }
  financialTransactionId: number;
  loading: boolean = false;
  newTax: number = 0;

  async setNewTax() {
    this.loading = true;
    const response = await this.service.changeTax(this.financialTransactionId, this.newTax);
    
    if(response.Errors.size === 0)
    {
      this.result = this.newTax;
      this.close();  
    } else
      this.loading = false;
  }

  cancel() {
    this.result = null;
    this.close();
  }
}
