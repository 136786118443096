import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PublicationStatusesEnum } from 'src/app/core/enums/publication-statuses.enum';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsResponse } from 'src/app/core/models/publications/publications-response.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { Utils } from 'src/app/core/utils/utils';

declare function initAccordion(): void;
declare function initFilterButton(): void;
declare function initDropdowns(): void;
declare function initRangeSliders(edges: any): void;
declare function resetRangeSliders(): void;

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {
  @Input() dealTypeIds = '1,2,3';
  @Input() userId = '';

  publicationsRequest: PublicationsRequest = new PublicationsRequest();
  publicationsResponse: PublicationsResponse | null = null;

  userRatings: any[] = [];
  userRatingsAllSelected = false;
  packageRatings: any[] = [];
  packageRatingsAllSelected = false;
  slidersInitialValues: any = {};

  constructor(
    private publicationsService: PublicationsService,
    private utils: Utils,
    private router: Router
  ) {
    this.userRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false }
    ];
    this.packageRatings = [
      { id: 0, name: 'A', selected: false },
      { id: 1, name: 'B', selected: false },
      { id: 2, name: 'C', selected: false }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.publicationsRequest.dealTypeIds = this.dealTypeIds;
    initFilterButton();
    initDropdowns();
    this.publicationsRequest.minPrice =
      this.publicationsResponse?.minPrice ?? 0;
    this.publicationsRequest.maxPrice =
      this.publicationsResponse?.maxPrice ?? 10_000_000_000;
    this.publicationsRequest.minCertPrice =
      this.publicationsResponse?.minCertPrice ?? 0;
    this.publicationsRequest.maxCertPrice =
      this.publicationsResponse?.maxCertPrice ?? 10_000_000_000;
    this.publicationsRequest.minCertQuantity =
      this.publicationsResponse?.minCertQuantity ?? 0;
    this.publicationsRequest.maxCertQuantity =
      this.publicationsResponse?.maxCertQuantity ?? 1_000;

    await this.getPublications();

    this.slidersInitialValues = {
      minPrice: this.publicationsRequest.minPrice,
      maxPrice: this.publicationsRequest.maxPrice,
      minCertPrice: this.publicationsRequest.minCertPrice,
      maxCertPrice: this.publicationsRequest.maxCertPrice,
      minCertQuantity: this.publicationsRequest.minCertQuantity,
      maxCertQuantity: this.publicationsRequest.maxCertQuantity
    };
    initRangeSliders(this.slidersInitialValues);
  }

  async getPublications(page: number = 0): Promise<void> {
    this.publicationsRequest.page = page;

    let response: PublicationsResponse;

    if (this.router.url === '/buy') {
      this.publicationsRequest.packageState = PublicationStatusesEnum.Published;

      response = await this.publicationsService.getBuyList(
        this.publicationsRequest
      );
    } else if (this.router.url === '/sell') {
      this.publicationsRequest.packageState = PublicationStatusesEnum.Published;
      
      response = await this.publicationsService.getSellList(
        this.publicationsRequest
      );
    } else {
      this.publicationsRequest.packageState = PublicationStatusesEnum.Published;

      response = await this.publicationsService.getPublicList(
        this.publicationsRequest
      );
    }

    const oldList = this.publicationsResponse?.list || [];
    if (page && oldList.length) {
      response.list = oldList.concat(response.list);
    }

    this.publicationsResponse = response;

    setTimeout(() => {
      initAccordion();
    }, 100);
  }

  async onSortChange(): Promise<void> {
    await this.getPublications();
  }

  async toggleSortWay(): Promise<void> {
    this.publicationsRequest.orderWay =
      this.publicationsRequest.orderWay === 'asc' ? 'desc' : 'asc';
    await this.getPublications();
  }

  async changeLimit(newLimit: number): Promise<void> {
    this.publicationsRequest.limit = newLimit;
    await this.getPublications();
  }

  onUserRatingsChange(): void {
    this.publicationsRequest.userRatings = this.userRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
    this.userRatingsAllSelected = this.userRatings.every((x) => x.selected);
  }

  onPackageRatingsChange(): void {
    this.publicationsRequest.packageRatings = this.packageRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
    this.packageRatingsAllSelected = this.packageRatings.every(
      (x) => x.selected
    );
  }

  onChangeAllUserRatings(): void {
    this.userRatings.map((x) => (x.selected = this.userRatingsAllSelected));
    this.publicationsRequest.userRatings = this.userRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
  }

  onChangeAllPackageRatings(): void {
    this.packageRatings.map(
      (x) => (x.selected = this.packageRatingsAllSelected)
    );
    this.publicationsRequest.packageRatings = this.packageRatings
      .filter((x) => x.selected)
      .map((x) => x.id)
      .join();
  }

  onResetFilterClick(): void {
    this.userRatings.map((x) => (x.selected = false));
    this.packageRatings.map((x) => (x.selected = false));
    this.publicationsRequest.userRatings = '';
    this.publicationsRequest.packageRatings = '';
    this.publicationsRequest.clubName = '';
    this.publicationsRequest.packageType = '';
    this.publicationsRequest.minPrice = this.slidersInitialValues.minPrice;
    this.publicationsRequest.maxPrice = this.slidersInitialValues.maxPrice;
    this.publicationsRequest.minCertPrice =
      this.slidersInitialValues.minCertPrice;
    this.publicationsRequest.maxCertPrice =
      this.slidersInitialValues.maxCertPrice;
    this.publicationsRequest.minCertQuantity =
      this.slidersInitialValues.minCertQuantity;
    this.publicationsRequest.maxCertQuantity =
      this.slidersInitialValues.maxCertQuantity;
    resetRangeSliders();
  }

  async onFilterSubmit(): Promise<void> {
    const filterElement = document.querySelector('.filter') as HTMLElement;
    const headerElement = document.querySelector('.header') as HTMLElement;
    const position = filterElement.offsetTop - headerElement.offsetHeight - 32;
    this.utils.scrollToTop(position);
    this.utils.closeAccordion();
    await this.getPublications();
  }

  async onSeeMoreClick(): Promise<void> {
    await this.getPublications(this.publicationsRequest.page + 1);
  }
}
