<div id="change-price-modal" class="smodal-content w-480">
  <div class="modal-header">
    <div (click)="cancel()" class="modal-close">
      <svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
      </svg>
    </div>
  </div>

  <div>
    <div class="mw__head">
      <div class="font-alt text-xl text-center">
        {{ 'Package price changing' | translate }}
      </div>
      <div class="divider-sm"></div>
      <div class="mw__stitle mt2" *ngIf="newPriceSetted">
        {{ 'New package price is set' | translate }}
      </div>
    </div>
    <div class="form__list mb3">
      <div class="form__item" [class.error]="errors.get('newPrice')">
        <div class="form-lbinfo">
          <div class="font-alt font-semibold">
            {{ 'Enter your new package price' | translate }}
          </div>
        </div>
        <input
          type="number"
          name="newPrice"
          [(ngModel)]="entity.newPrice"
          min="1"
          class="form__input"
          placeholder="{{ 'Enter your new package price' | translate }}"
          (ngModelChange)="errors.delete('newPrice')"
        />
        <div class="error__message" *ngIf="errors.get('newPrice')">
          {{ errors.get('newPrice')[0] | translate }}
        </div>
      </div>
    </div>
  </div>

  <div>
    <button
      [disabled]="loading"
      type="submit"
      class="btn w100 mb-2 h-50"
      (click)="setNewPrice()"
      [attr.data-tooltip]="
        (loading ? 'Loading...' : 'Set new package price') | translate
      "
      data-position="bottom center"
    >
      {{ (loading ? 'Loading...' : 'Set new package price') | translate }}
    </button>

    <button
      type="button"
      class="btn btn--dark-outline w100 h-50"
      (click)="cancel()"
    >
      Закрыть
    </button>
  </div>
</div>
