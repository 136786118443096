<div class="mrow">
  <div class="mcoll pdf-container" *ngFor="let item of members?.list">
    <div class="participant pdf-shrink">
      <div class="hidden pdf-add mb3 pb2" style="border-bottom: 1px solid #235; ">
        <img src="/assets/img/logo-all_dark.svg" alt="">
        <div class="ff:CormorantInfant fz18 mt1">www.t-st.group</div>
      </div>
      <div class="package__title hidden pdf-add" style="font-size: 4.2rem;">
        {{'Package owner' | translate}}
      </div>
      <div class="participant__head">
        <div class="participant__avatar avatar">
          <img src="{{item.photo}}" alt="" class="avatar__img">
        </div>
        <div class="participant__desc">
          <div class="participant__name">
            <div class="inline" data-position="top center" [attr.data-tooltip]="item.fullName">
              {{item.fullName}}
            </div>
          </div>
          <div class="participant__post" data-position="bottom center" [attr.data-tooltip]="item.role | translate">
            {{item.role | translate}}
          </div>
        </div>
      </div>
      <div class="participant__body">
        <div class="dl">
          <div class="dl__dt">{{'Number of publications' | translate}}</div>
          <div class="dl__points"></div>
          <div class="dl__dd">{{item.publicationsCount}}</div>
        </div>
        <div class="dl">
          <div class="dl__dt">{{'Registration date' | translate}}</div>
          <div class="dl__points"></div>
          <div class="dl__dd">{{item.createDate}}</div>
        </div>
      </div>
      <div *ngIf="showFooter" class="participant__footer auth-df pdf-remove">
        <div class="participant__nav df aic">
          <button type="button" class="mr2 h-orange  h-light:c-blue" data-position="bottom center"
            [attr.data-tooltip]="'Download' | translate" (click)="download($event)">
            <svg class="ic ic-card-download ic-round ic-stroke">
              <use xlink:href="/assets/img/sprite.svg#card-download"></use>
            </svg>
          </button>
          <button type="button" class="mr2 h-orange  h-light:c-blue" data-position="bottom center"
            [attr.data-tooltip]="'Print' | translate" (click)="print($event)">
            <svg class="ic ic-card-printer ic-round ic-stroke">
              <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
            </svg>
          </button>
          <button *ngIf="showChat" type="button" class="mr2 h-orange  h-light:c-blue svg-message" data-position="bottom center"
            [attr.data-tooltip]="'Write a message' | translate">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z"
                stroke="#223355" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <div class="participant__favorite">
          <div class="add-favorite">
            <label class="c-pointer add-favorite__label" data-position="bottom center" data-tooltip data-favorite>
              <input type="checkbox" class="add-favorite__input">
              <svg class="ic ic-add-favorite add-favorite__icon">
                <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="downloading-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{'Preparing for download...' | translate}}
      </div>
    </div>
  </div>
</div>

<div id="printing-modal" class="mw">
  <div class="mw__content">
    <div class="mw__body">
      <div class="stitle mb4 text-center">
        {{'Preparing for print...' | translate}}
      </div>
    </div>
  </div>
</div>