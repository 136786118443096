import { formatDate, formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Utils } from '../../utils/utils';

import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';

import { PublicationTypesEnum } from 'src/app/core/enums/publication-types.enum';
import { Certificate } from 'src/app/features/personal-area/models/certificate/certificate.model';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';
import { Publication } from '../../models/publications/publication.model';
import { PublicationsRequest } from '../../models/publications/publications-request.model';
import { PublicationsResponse } from '../../models/publications/publications-response.model';

import { BehaviorSubject, Observable } from 'rxjs';
import { PublicationChangeExpirationDate } from '../../models/publications/publication-change-expiration-date.model';

const ratings = ['A', 'B', 'C'];

@Injectable({
  providedIn: 'root'
})
export class PublicationsService {
  private _publicationInfoSubject: BehaviorSubject<PublicationChangePrice>;
  public publicationInfo$: Observable<PublicationChangePrice>;

  private _publicationIsSplittedSubject: BehaviorSubject<boolean>;
  public publicationIsSplitted$: Observable<boolean>;

  private _publicationSplitRequestSubject: BehaviorSubject<boolean>;
  public publicationSplitRequest$: Observable<boolean>;

  private _publicationsJoinRequestSubject: BehaviorSubject<boolean>;
  public publicationsJoinRequest$: Observable<boolean>;

  private _publicationsUnselectRequestSubject: BehaviorSubject<boolean>;
  public publicationsUnselectRequest$: Observable<boolean>;

  private _publicationsShowMoreRequestSubject: BehaviorSubject<boolean>;
  public publicationsShowMoreRequest$: Observable<boolean>;

  private _publicationsHasSelectedItemsRequestSubject: BehaviorSubject<boolean>;
  public publicationsHasSelectedItemsRequest$: Observable<boolean>;

  private _publicationsUpdateRequestSubject: BehaviorSubject<boolean>;
  public publicationsUpdateRequest$: Observable<boolean>;

  private _publicationsListEventsSubject: BehaviorSubject<boolean>;
  public publicationsListEvent$: Observable<boolean>;

  public errors: Map<string, any> = new Map<string, any>();

  constructor(private httpClient: HttpClient, private utils: Utils) {
    this._publicationInfoSubject = new BehaviorSubject<PublicationChangePrice>(
      new PublicationChangePrice()
    );
    this.publicationInfo$ = this._publicationInfoSubject.asObservable();

    this._publicationIsSplittedSubject = new BehaviorSubject<boolean>(false);
    this.publicationIsSplitted$ =
      this._publicationIsSplittedSubject.asObservable();

    this._publicationSplitRequestSubject = new BehaviorSubject<boolean>(false);
    this.publicationSplitRequest$ =
      this._publicationSplitRequestSubject.asObservable();

    this._publicationsJoinRequestSubject = new BehaviorSubject<boolean>(false);
    this.publicationsJoinRequest$ =
      this._publicationsJoinRequestSubject.asObservable();

    this._publicationsUnselectRequestSubject = new BehaviorSubject<boolean>(
      false
    );
    this.publicationsUnselectRequest$ =
      this._publicationsUnselectRequestSubject.asObservable();

    this._publicationsShowMoreRequestSubject = new BehaviorSubject<boolean>(
      false
    );
    this.publicationsShowMoreRequest$ =
      this._publicationsShowMoreRequestSubject.asObservable();

    this._publicationsHasSelectedItemsRequestSubject =
      new BehaviorSubject<boolean>(false);
    this.publicationsHasSelectedItemsRequest$ =
      this._publicationsHasSelectedItemsRequestSubject.asObservable();

    this._publicationsUpdateRequestSubject = new BehaviorSubject<boolean>(
      false
    );
    this.publicationsUpdateRequest$ =
      this._publicationsUpdateRequestSubject.asObservable();

    this._publicationsListEventsSubject = new BehaviorSubject<boolean>(false);
    this.publicationsListEvent$ =
      this._publicationsListEventsSubject.asObservable();
  }

  public async updateCurrentPublicationInfo(
    publicationPriceChangesInfo: PublicationChangePrice
  ) {
    this._publicationInfoSubject.next(publicationPriceChangesInfo);
  }

  public async publicationStateChanged(isSplitted: boolean) {
    this._publicationIsSplittedSubject.next(isSplitted);
  }

  public async requestPublicationSplit(isRequested: boolean) {
    this._publicationSplitRequestSubject.next(isRequested);
  }

  public async requestPublicationsJoin(isRequested: boolean) {
    this._publicationsJoinRequestSubject.next(isRequested);
  }

  public async requestPublicationsUnselect(isRequested: boolean) {
    this._publicationsUnselectRequestSubject.next(isRequested);
  }

  public async requestPublicationsShowMore(isRequested: boolean) {
    this._publicationsShowMoreRequestSubject.next(isRequested);
  }

  public async requestHasSelectedItems(hasSelected: boolean) {
    this._publicationsHasSelectedItemsRequestSubject.next(hasSelected);
  }

  public async requestUpdateItems(isRequested: boolean) {
    this._publicationsUpdateRequestSubject.next(isRequested);
  }

  public async publicationEventRaise(event: boolean) {
    this._publicationsListEventsSubject.next(event);
  }

  public get getPublicationInfo(): PublicationChangePrice {
    return this._publicationInfoSubject.value;
  }

  public get getPublicationIsSplitted(): boolean {
    return this._publicationIsSplittedSubject.value;
  }

  public get getPublicationSplitRequestState(): boolean {
    return this._publicationIsSplittedSubject.value;
  }

  public get getPublicationsJoinRequestState(): boolean {
    return this._publicationsJoinRequestSubject.value;
  }

  public get getPublicationsUnselectRequestState(): boolean {
    return this._publicationsUnselectRequestSubject.value;
  }

  public get getPublicationsShowMoreRequestState(): boolean {
    return this._publicationsShowMoreRequestSubject.value;
  }

  public get getPublicationsHasSelectedItems(): boolean {
    return this._publicationsHasSelectedItemsRequestSubject.value;
  }

  public get getPublicationsUpdateItemsRequestState(): boolean {
    return this._publicationsUpdateRequestSubject.value;
  }

  public get getPublicationEventData(): boolean {
    return this._publicationsListEventsSubject.value;
  }

  formatPublicationForSell(item: Publication): Publication {
    item.dealTypeName =
      item.publicationTypeCode === PublicationTypesEnum.Auction
        ? 'Auction'
        : item.publicationTypeCode === PublicationTypesEnum.Sell
        ? 'Purchase'
        : 'Sale';
    item.startingPriceFormatted = !item.startingPrice
      ? ''
      : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
    item.priceFormatted = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`;
    item.claimsAmountFormatted = `RUB ${formatNumber(
      item.claimsAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.courtCostsFormatted = `RUB ${formatNumber(
      item.courtCosts,
      'ru-RU',
      '1.2-2'
    )}`;

    // ToDo: wrong logic, claimsAmount = startingPrice
    // if (
    //   item.publicationTypeCode !== PublicationTypesEnum.Sell &&
    //   item.publicationTypeCode !== PublicationTypesEnum.Auction
    // ) {
    //   item.claimsAmountFormatted = !item.startingPrice
    //     ? ''
    //     : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
    // }

    item.startDateFormatted = item.startDate
      ? formatDate(item.startDate, 'dd.MM.yyyy', 'ru-RU')
      : '';
    item.stopDateFormatted = item.stopDate
      ? formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU')
      : '';

    item.userRatingName = ratings[item.userRating] ?? 'A';
    item.packageRatingName = ratings[item.packageRating ?? 2] ?? 'C';
    if (item.certCount <= 0) {
      item.certCount = 1;
    }
    item.clubNameFormatted = item.clubName;
    item.packageType = 'One Club';

    item.costForOneCertFormatted =
      item.certCount > 0
        ? `RUB ${formatNumber(item.price / item.certCount, 'ru-RU', '1.2-2')}`
        : `RUB ${formatNumber(0, 'ru-RU', '1.2-2')}`;

    const nowDate = new Date();
    const stopDate = new Date(item.stopDate);
    item.endsIn = {};
    if (stopDate > nowDate) {
      item.endsIn = this.utils.dateDiff(stopDate);
      const maxDays = 355;
      if (item.endsIn.dayAbbr > maxDays) {
        item.endsIn.dayAbbr = maxDays;
      }
    }

    item.clubPhoto =
      environment.mainUrl +
      (item.clubPhoto
        ? `photos/clubs/${item.clubPhoto}`
        : 'assets/img/image-empty.png');

    return item;
  }

  formatPublicationForBuy(item: Publication): Publication {
    item.dealTypeName =
      item.publicationTypeCode === PublicationTypesEnum.Auction
        ? 'Auction'
        : item.publicationTypeCode === PublicationTypesEnum.Sell
        ? 'Purchase'
        : 'Sale';
    item.startingPriceFormatted = !item.startingPrice
      ? ''
      : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
    item.priceFormatted = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`;
    item.claimsAmountFormatted = `RUB ${formatNumber(
      item.claimsAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.courtCostsFormatted = `RUB ${formatNumber(
      item.courtCosts,
      'ru-RU',
      '1.2-2'
    )}`;

    item.startDateFormatted = item.startDate
      ? formatDate(item.startDate, 'dd.MM.yyyy', 'ru-RU')
      : '';
    item.stopDateFormatted = item.stopDate
      ? formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU')
      : '';

    item.userRatingName = ratings[item.userRating] ?? 'A';
    item.packageRatingName = ratings[item.packageRating ?? 2] ?? 'C';
    if (item.certCount <= 0) {
      item.certCount = 1;
    }
    item.clubNameFormatted = item.clubName;
    item.packageType = 'One Club';

    item.costForOneCertFormatted = item.claimsAmount
      ? `RUB ${formatNumber(item.claimsAmount, 'ru-RU', '1.2-2')}`
      : `RUB ${formatNumber(0, 'ru-RU', '1.2-2')}`;

    const nowDate = new Date();
    const stopDate = new Date(item.stopDate);
    item.endsIn = {};
    if (stopDate > nowDate) {
      item.endsIn = this.utils.dateDiff(stopDate);
      const maxDays = 355;
      if (item.endsIn.dayAbbr > maxDays) {
        item.endsIn.dayAbbr = maxDays;
      }
    }

    item.clubPhoto =
      environment.mainUrl +
      (item.clubPhoto
        ? `photos/clubs/${item.clubPhoto}`
        : 'assets/img/image-empty.png');

    return item;
  }

  formatPublication(item: Publication): Publication {
    item.dealTypeName =
      item.publicationTypeCode === PublicationTypesEnum.Auction
        ? 'Auction'
        : item.publicationTypeCode === PublicationTypesEnum.Sell
        ? 'Purchase'
        : 'Sale';
    item.startingPriceFormatted = !item.startingPrice
      ? ''
      : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
    item.priceFormatted = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`;
    item.claimsAmountFormatted = `RUB ${formatNumber(
      item.claimsAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.courtCostsFormatted = `RUB ${formatNumber(
      item.courtCosts,
      'ru-RU',
      '1.2-2'
    )}`;

    // ToDo: wrong logic, claimsAmount = startingPrice
    // if (
    //   item.publicationTypeCode !== PublicationTypesEnum.Sell &&
    //   item.publicationTypeCode !== PublicationTypesEnum.Auction
    // ) {
    //   item.claimsAmountFormatted = !item.startingPrice
    //     ? ''
    //     : `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`;
    // }

    item.startDateFormatted = item.startDate
      ? formatDate(item.startDate, 'dd.MM.yyyy', 'ru-RU')
      : '';
    item.stopDateFormatted = item.stopDate
      ? formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU')
      : '';

    item.userRatingName = ratings[item.userRating] ?? 'A';
    item.packageRatingName = ratings[item.packageRating ?? 2] ?? 'C';
    if (item.certCount <= 0) {
      item.certCount = 1;
    }
    item.clubNameFormatted = item.clubName;
    item.packageType = 'One Club';

    item.costForOneCertFormatted =
      item.publicationTypeCode === PublicationTypesEnum.Sell
        ? item.claimsAmount
          ? `RUB ${formatNumber(item.claimsAmount, 'ru-RU', '1.2-2')}`
          : `RUB ${formatNumber(0, 'ru-RU', '1.2-2')}`
        : item.startingPrice
        ? `RUB ${formatNumber(item.startingPrice, 'ru-RU', '1.2-2')}`
        : `RUB ${formatNumber(0, 'ru-RU', '1.2-2')}`;

    const nowDate = new Date();
    const stopDate = new Date(item.stopDate);
    item.endsIn = {};
    if (stopDate > nowDate) {
      item.endsIn = this.utils.dateDiff(stopDate);
      const maxDays = 355;
      if (item.endsIn.dayAbbr > maxDays) {
        item.endsIn.dayAbbr = maxDays;
      }
    }

    item.clubPhoto =
      environment.mainUrl +
      (item.clubPhoto
        ? `photos/clubs/${item.clubPhoto}`
        : 'assets/img/image-empty.png');
    return item;
  }

  private formatCertificate(item: Certificate): Certificate {
    item.certificateRatingFormated =
      ratings[item.certificateRating ?? 2] ?? 'C';
    item.certificateClaimsAmountFormated = `RUB ${formatNumber(
      item.certificateClaimsAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.certificatefullCostFormated =
      item.certificatefullCost != null
        ? `RUB ${formatNumber(item.certificatefullCost || 0, 'ru-RU', '1.2-2')}`
        : `RUB ${formatNumber(0, 'ru-RU', '1.2-2')}`;
    item.certificateManagementFeeAmountFormated = `RUB ${formatNumber(
      item.certificateManagementFeeAmount || 0,
      'ru-RU',
      '1.2-2'
    )}`;
    item.certificateLastFeeAmountFormated = `RUB ${formatNumber(
      item.certificateLastFeeAmount,
      'ru-RU',
      '1.2-2'
    )}`;

    item.certificateCourtCostsFormated = item.certificateCourtCosts
      ? `RUB ${formatNumber(item.certificateCourtCosts, 'ru-RU', '1.2-2')}`
      : '';
    item.certificateDateFormated =
      item.certificateDate != null
        ? formatDate(item.certificateDate, 'dd.MM.yyyy', 'ru-RU')
        : '';
    item.certificatePriceFormated = item.certificatePrice
      ? `RUB ${formatNumber(item.certificatePrice, 'ru-RU', '1.2-2')}`
      : '';

    if (item.relatedUserSurname) {
      item.certificateUsernameFormated = `${item.relatedUserSurname} `;
    }
    if (item.relatedUserName) {
      item.certificateUsernameFormated += item.relatedUserName;
    }
    if (item.relatedUserMiddleName) {
      item.certificateUsernameFormated += ` ${item.relatedUserMiddleName}`;
    }

    return item;
  }

  async getSellList(
    params: PublicationsRequest
  ): Promise<PublicationsResponse> {
    try {
      const publications = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetSellList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
      publications.totalPages = Math.ceil(
        (publications.totalRows ?? 0) / params.limit
      );

      publications.list.map((publication: Publication) =>
        this.formatPublicationForSell(publication)
      );

      return publications;
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return {} as PublicationsResponse;
  }

  async getBuyList(params: PublicationsRequest): Promise<PublicationsResponse> {
    try {
      const publications = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetBuyList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
      publications.totalPages = Math.ceil(
        (publications.totalRows ?? 0) / params.limit
      );

      publications.list.map((publication: Publication) =>
        this.formatPublicationForBuy(publication)
      );

      return publications;
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return {} as PublicationsResponse;
  }

  async getPersonalList(
    params: PublicationsRequest
  ): Promise<GeneralResponse<PublicationsResponse>> {
    const result = new GeneralResponse<PublicationsResponse>();

    try {
      const response: PublicationsResponse = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetPersonalList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();

      response.totalPages = Math.ceil((response.totalRows ?? 0) / params.limit);

      response.list.map((publication: Publication) =>
        this.formatPublication(publication)
      );

      result.Response = response;
    } catch (ex) {
      result.Errors = this.utils.collectErrors(ex);
    }

    return result;
  }

  async getSearchResultList(
    params: PublicationsRequest
  ): Promise<PublicationsResponse> {
    try {
      const publications = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetSearchResultList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
      publications.totalPages = Math.ceil(
        (publications.totalRows ?? 0) / params.limit
      );

      publications.list.map((publication: Publication) =>
        this.formatPublication(publication)
      );

      return publications;
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return {} as PublicationsResponse;
  }

  async getPublicList(
    params: PublicationsRequest
  ): Promise<PublicationsResponse> {
    try {
      const response: PublicationsResponse = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetPublicList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();

      response.list.map((publication: Publication) =>
        this.formatPublication(publication)
      );

      return response;
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return {} as PublicationsResponse;
  }

  async getWidgetList(params: PublicationsRequest): Promise<Publication[]> {
    try {
      return await this.httpClient
        .get<Publication[]>(
          `${environment.apiUrl}/api/publications/GetWidgetList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return Array<Publication>(0);
  }

  async getTradePulseList(params: PublicationsRequest): Promise<Publication[]> {
    try {
      return await this.httpClient
        .get<Publication[]>(
          `${environment.apiUrl}/api/publications/GetTradePulseList`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
    } catch (ex) {
      this.errors = this.utils.collectErrors(ex);
    }

    return Array<Publication>(0);
  }

  async getById(id: number): Promise<Publication> {
    return await this.httpClient
      .get<Publication>(
        `${environment.apiUrl}/api/publications/GetById/${id}`,
        { headers: this.utils.jsonHeaders() }
      )
      .toPromise();
  }

  async getDetailedById(id: number): Promise<Publication> {
    const result = await this.httpClient
      .get<Publication>(
        `${environment.apiUrl}/api/publications/GetDetailedById/${id}`,
        { headers: this.utils.jsonHeaders() }
      )
      .toPromise();

    return this.formatPublication(result);
  }

  async updatePrice(
    publicationChangePrice: PublicationChangePrice
  ): Promise<Map<string, any>> {
    try {
      await this.httpClient
        .post(
          `${environment.apiUrl}/api/publications/updatePrice`,
          JSON.stringify(publicationChangePrice),
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch (response: any) {
      this.errors = this.utils.collectErrors(response);
    }
    return this.errors;
  }

  async updateExpirationDate(
    publicationChangeExpirationDate: PublicationChangeExpirationDate
  ): Promise<Map<string, any>> {
    try {
      await this.httpClient
        .post(
          `${environment.apiUrl}/api/publications/updateExpirationDate`,
          JSON.stringify(publicationChangeExpirationDate),
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch (response: any) {
      return this.utils.collectErrors(response);
    }
    return new Map<string, any>();
  }

  async publish(id: number): Promise<Map<string, any>> {
    try {
      await this.httpClient
        .get(`${environment.apiUrl}/api/publications/publish/${id}`, {
          headers: this.utils.jsonHeaders()
        })
        .toPromise();
    } catch (response: any) {
      this.errors = this.utils.collectErrors(response);
    }
    return this.errors;
  }

  async unpublish(id: number): Promise<Map<string, any>> {
    try {
      await this.httpClient
        .get(`${environment.apiUrl}/api/publications/unpublish/${id}`, {
          headers: this.utils.jsonHeaders()
        })
        .toPromise();
    } catch (response: any) {
      this.errors = this.utils.collectErrors(response);
    }
    return this.errors;
  }

  async block(publicationId: number): Promise<any> {
    const result = new GeneralResponse<any>();

    try {
      result.Response = await this.httpClient
        .post<any>(
          `${environment.webApiUrl}/api/Publications/BlockPermanent/${publicationId}`,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch (error: any) {
      result.Errors = this.utils.collectErrors(error);
    }

    return result;
  }

  async blockOld(publicationId: number): Promise<Map<string, any>> {
    try {
      await this.httpClient
        .get(`${environment.apiUrl}/api/publications/block/${publicationId}`, {
          headers: this.utils.jsonHeaders()
        })
        .toPromise();
    } catch (response: any) {
      this.errors = this.utils.collectErrors(response);
    }
    return this.errors;
  }

  async inactivateAndChangeOwnerByDealId(
    dealId: number,
    buyerId: string
  ): Promise<GeneralResponse<boolean>> {
    const result = new GeneralResponse<boolean>();

    try {
      result.Response = await this.httpClient
        .post<boolean>(
          `${environment.apiUrl}/api/publications/InactivateAndChangeOwnerByDealId/${dealId}/${buyerId}`,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }
    return result;
  }

  async getValidPublicationsForSell(
    sellPublicationId: number
  ): Promise<GeneralResponse<Publication[]>> {
    const result = new GeneralResponse<Publication[]>();
   
    try {
      const response: Publication[] = await this.httpClient
        .get<Publication[]>(
          `${environment.apiUrl}/api/publications/GetValidPublicationsForSell/${sellPublicationId}`,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();

      response.map((publication: Publication) =>
        this.formatPublication(publication)
      );

      result.Response = response;
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }
    return result;
  }

  async getPublicationsByType(
    params: PublicationsRequest
  ): Promise<GeneralResponse<PublicationsResponse>> {
    const result = new GeneralResponse<PublicationsResponse>();

    try {
      const response: PublicationsResponse = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetPublicationsByType`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();

      response.list.map((publication: Publication) =>
        this.formatPublication(publication)
      );

      result.Response = response;
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }

    return result;
  }

  async joinPackages(
    publicationsId: number[]
  ): Promise<GeneralResponse<number>> {
    const result = new GeneralResponse<number>();

    publicationsId = publicationsId ?? new Array<number>(0);

    try {
      if (!publicationsId.length) {
        throw new Error('No-one publications is selected');
      }

      result.Response = await this.httpClient
        .post<number>(
          `${environment.apiUrl}/api/publications/joinPackagesToOne`,
          JSON.stringify(publicationsId),
          {
            headers: this.utils.jsonHeaders()
          }
        )
        .toPromise();
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }

    return result;
  }

  async getPersonalListCount(
    params: PublicationsRequest
  ): Promise<GeneralResponse<PublicationsResponse>> {
    const result = new GeneralResponse<PublicationsResponse>();

    try {
      const publications = await this.httpClient
        .get<PublicationsResponse>(
          `${environment.apiUrl}/api/publications/GetPersonalListCount`,
          { headers: this.utils.jsonHeaders(), params: params as any }
        )
        .toPromise();
      publications.totalPages = Math.ceil(
        (publications.totalRows ?? 0) / params.limit
      );

      result.Response = publications;
    } catch (ex) {
      result.Errors = this.utils.collectErrors(ex);
    }

    return result;
  }

  // ToDo: remove
  // Very, very obsolete :) Must be totaly removed later
  async getLastPublications(page = 0, dealType = ''): Promise<any> {
    const url = `http://45.143.138.74:8094/api/lastpublication?page=${page}${
      dealType ? '&dealType=' + dealType : ''
    }`;
    // var url = `http://45.89.66.165:5000/api/lastpublication?page=${page}${dealType ? '&dealType=' + dealType : ''}`;

    const result = await this.httpClient
      .get(url, { headers: this.utils.jsonHeaders() })
      .toPromise();

    return result;
  }
}
