export class PublicationsRequest {
  constructor() { }
  limit: number = 8;
  page: number = 0;
  userId: string = '';
  dealTypeIds: string = '1,2,3';
  orderBy: string = 'id';
  orderWay: string = 'desc';
  onlyActual: number = 1;
  clubId: number = 0;
  searchTerm: string = '';
  clubName: string = '';
  packageType: string = '';
  packageTypes: string = '';
  userRatings: string = '';
  packageRatings: string = '';
  minPrice: number = 1;
  maxPrice: number = 1_000_000_000;
  minCertPrice: number = 1;
  maxCertPrice: number = 100_000_000;
  minCertQuantity: number = 1;
  maxCertQuantity: number = 1_000_000;
  packageState: string = 'published';
  onlyMy: number = 0;
  onlyPublished: boolean = true;
  onlyMyConnection: number = 0;
  publicationNumbers: string = '';
  minClaimsAmount : number = 0;
  maxClaimsAmount  : number = 1000000000;
}
