import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyRuble'
})
export class CurrencyRublePipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (value !== 0 && !value) {
      return '';
    }

    return `RUB ${formatNumber(value, 'ru-RU', '1.2-2')}`;
  }
}
