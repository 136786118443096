import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sort-chevron',
  templateUrl: './sort-chevron.component.html',
  styleUrls: ['./sort-chevron.component.css']
})
export class SortChevronComponent implements OnInit {

  @Input() params: any = {};
  @Input() by: string = '';
  @Input() way: string = 'asc';

  constructor() { }

  ngOnInit(): void {
  }

}
