import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { LastCompletedPublicationsService } from '../../../core/services/publications/last-completed-publications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-packages-slider',
  templateUrl: './packages-slider.component.html',
  styleUrls: ['./packages-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesSliderComponent implements OnInit, OnDestroy {
  publications: Publication[] = [];
  request: PublicationsRequest = new PublicationsRequest();

  isSliderInit = false;
  sliderSpeed = 300;
  sliderConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 3000,
    speed: this.sliderSpeed,
    pauseOnHover: true,
    pauseOnFocus: false
  };

  subscriptions = new Subscription();

  constructor(
    private lastCompletedPublicationsService: LastCompletedPublicationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getPublications();
    // this.lastCompletedPublicationsService.startConnection();
    // this.lastCompletedPublicationsService.addPublicationsListener();
    // this.subscribePublicationsChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    // this.lastCompletedPublicationsService.stopConnection();
  }

  getPublications(): void {
    const subscription = this.lastCompletedPublicationsService
      .getPublications()
      .subscribe(
        (data) => {
          console.log('getPublications => data', data);
          this.publications = data;
          this.cdr.markForCheck();
        },
        (error) => {
          console.log('getPublications => error', error);
          this.publications = [];
          this.cdr.markForCheck();
        }
      );
    this.subscriptions.add(subscription);
  }

  subscribePublicationsChanges(): void {
    const subscription =
      this.lastCompletedPublicationsService.publications.subscribe(
        (data) => {
          console.log('subscribePublicationsChanges => data', data);
          this.publications = data;
          this.cdr.markForCheck();
        },
        (error) => {
          console.log('subscribePublicationsChanges => error', error);
          this.publications = [];
          this.cdr.markForCheck();
        }
      );
    this.subscriptions.add(subscription);
  }

  // ToDo: сделать общий компонент из TradesPulseComponent и PackagesSliderComponent
  // Fix: для { infinite: true } слайдер стартует с предпоследнего элемента
  // Иначально прячем слайдер, прокручиваем к первому элементу, показываем слайдер
  sliderInit(event: any) {
    event?.slick?.slickGoTo(0);
    this.isSliderInit = true;
    // Принудительно вызываем change detection с задержкой скорости анимации сладйера
    setTimeout(() => this.cdr.detectChanges(), this.sliderSpeed);
  }
}
