import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateDiffService {

  constructor() { }

  /**
   * ☃ dateDiff "Snowman Carl" (http://stackoverflow.com/questions/13903897)
   * Returns a detail object about the difference between two dates
   *
   * When providing custom units, provide them in descending order (eg week,day,hour; not hour,day,week)
   *
   * @param {Date} dateStart - date to compare to
   * @param {Date|string} [dateEnd=new Date()] - second date, can be used as unit param instead
   * @param {...string} [units=Object.keys(dateDiffDef)] - limits the returned object to provided keys
   */
  dateDiff(
    dateStart: Date,
    dateEnd: Date | string = new Date,
    ...units: string[]
  ): {
    [key: string]: number
  } {
    if (typeof dateEnd === 'string')
      dateEnd = new Date();

    let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());

    return (units.length ? units : Object.keys(this.dateDiffDef))
      .reduce((res: { [key: string]: number }, key: string) => {
        if (!this.dateDiffDef.hasOwnProperty(key))
          throw new Error('Unknown unit in dateDiff: ' + key);

        const value = Math.floor(delta / this.dateDiffDef[key]);
        if (value != 0) {
          res[key] = value;
          delta -= res[key] * this.dateDiffDef[key];
        }

        return res;
      }, {});
  }

  // default time units for dateDiff
  private dateDiffDef: { [key: string]: number } = {
    // millennium: 31536000000000,
    // century: 3153600000000,
    // decade: 315360000000,
    // year: 31536000000,
    // quarter: 7776000000,
    // month: 2592000000,
    // week: 604800000,
    dayAbbr: 86400000,
    hourAbbr: 3600000,
    minuteAbbr: 60000,
    // second: 1000,
    // millisecond: 1
  };
}
