import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from "ngx-simple-modal";
import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

export interface ChangePriceModel {
  entity: PublicationChangePrice;
  data:any|undefined;
}
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
@Component({
  selector: 'app-change-price-modal',
  templateUrl: './change-price-modal.component.html',
  styleUrls: ['./change-price-modal.component.css']
})
export class ChangePriceModalComponent  extends SimpleModalComponent<ChangePriceModel  , number> implements ChangePriceModel ,OnInit, OnDestroy  {
  subscriptions: any;

  constructor(
    private publicationsService: PublicationsService,
    private router: Router,
  ) { 
    super();
  }
  entity: PublicationChangePrice=new PublicationChangePrice();
  data: any;
  errors: Map<string, any> = new Map<string, any>();
  loading: boolean = false;
  newPriceSetted: boolean = false;

  ngOnInit(): void {
    // this.subscriptions = this.publicationsService.publicationInfo$.subscribe((priceInfo: PublicationChangePrice) => {
    //   if (this.loading)
    //     return;

    //   this.entity.newPrice = priceInfo.newPrice;
    //   this.entity.publicationId = priceInfo.publicationId;
    //   this.entity.oldPrice = priceInfo.oldPrice;
    // });
  }
  ngOnDestroy(): void {
   // this.subscriptions.unsubscribe();
  }
  async setNewPrice(): Promise<void> {
    this.loading = true;

    if (this.entity != null) {
      await this.publicationsService.updateCurrentPublicationInfo(this.entity);
      this.errors = await this.publicationsService.updatePrice(this.entity);

      this.loading = false;

      if (!this.errors.size) {
        this.newPriceSetted = true;
        this.result = this.entity.newPrice;
        this.close();
      }
      else {
        anAlert("Не удалось обновить стоимость лота для продажи: " + this.errors.entries().next().value[1], "OK");
        this.result = 0;
        this.close();
      }
    }
    else {
      this.loading = false;
      anAlert("Не удалось определить данные лота. Обратитесь к администратору.", "OK");
      this.result = 0;
      this.close();
    }
  }
  cancel() {
    this.result = -1;
    this.close();
  }
}
