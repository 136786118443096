<div id="personal-data" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Collection and processing of personal data' | translate}}</div>
      <span class="mw__line mb2"></span>
      <div class="mw__stitle mb3">{{'PresonalDataProcessingText' | translate}}</div>
      <div class="tc">
        <a href="#close-modal" rel="modal:close" class="btn" title="{{'I understand' | translate}}">{{'I understand' | translate}}</a>
      </div>
    </div>
  </div>
</div>