import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sortable-th',
  templateUrl: './sortable-th.component.html',
  styleUrls: ['./sortable-th.component.css']
})
export class SortableThComponent implements OnInit {

  @Input() text: string = '';
  @Input() by: string = '';
  @Input() way: string = 'asc';
  @Input() params: any = {};

  @Output() sort = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSortClick(event: Event, sortBy: string, sortWay?: string) {
    event.preventDefault();
    if (this.params.sortBy == sortBy) {
      this.params.sortWay = this.params.sortWay == 'asc' ? 'desc' : 'asc';
    } else {
      this.params.sortBy = sortBy;
      if (sortWay !== undefined) {
        this.params.sortWay = sortWay;
      }
    }
    this.sort.emit();
  }
}
