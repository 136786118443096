import { formatDate, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
//import { PublicationService } from 'src/app/publication.service';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { PublicationsRequest } from 'src/app/core/models/publications/publications-request.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

declare function ucwords(str: string): string;
declare function packageSliderTick(): void;

//interface IPublication {
//  startDate: string;
//  stopDate: string;
//  clubName: string;
//  certificatePrice: number;
//  startDateFormatted: string;
//  stopDateFormatted: string;
//  clubNameFormatted: string;
//  priceFormatted: string;
//}

@Component({
  selector: 'app-packages-slider',
  templateUrl: './packages-slider.component.html',
  styleUrls: ['./packages-slider.component.css']
})
export class PackagesSliderComponent implements OnInit {
  publications: Array<Publication> = [];
  interval: any = undefined;
  request: PublicationsRequest = new PublicationsRequest();

  constructor(
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService
  ) {}

  async ngOnInit() {
    this.request.orderBy = 'id';
    this.request.orderWay = 'desc';
    this.request.page = 0;
    this.request.limit = 5;
    this.request.onlyActual = 1;

    this.publications = await this.publicationsService.getWidgetList(
      this.request
    );
    this.publications.map((item: Publication) => {
      item.startDateFormatted = formatDate(
        item.startDate,
        'dd.MM.yyyy',
        'ru-RU'
      );
      item.stopDateFormatted = formatDate(item.stopDate, 'dd.MM.yyyy', 'ru-RU');
      item.clubNameFormatted = ucwords(item.clubName);
      item.priceFormatted = `RUB ${formatNumber(item.price, 'ru-RU', '1.2-2')}`;
    });

    this.interval = setInterval(() => {
      this.packageSliderTick();
    }, 3000);
  }

  ngOnDestroy() {
    if (this.interval != undefined) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  packageSliderTick() {
    const row = document.querySelector('.package-list__row');
    if (!row) return;
    const item = row.querySelector('.package-list__item');
    if (!item) return;
    const style = getComputedStyle(item);
    const width = parseInt(style.width) || 0;
    const marginRight = parseInt(style.marginRight) || 0;
    const shift = width + marginRight;
    row.insertAdjacentHTML('afterbegin', item.outerHTML);
    row.append(item);
    row.animate(
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(-${shift}px)` }
      ],
      {
        easing: 'ease',
        duration: 500
      }
    ).onfinish = () => {
      row.querySelector('.package-list__item')?.remove();
    };
  }
}
