import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { Subscription } from 'rxjs';
import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { SupportService } from 'src/app/core/services/support/support.service';
import { SmsConfirmModalComponent } from '../sms-confirm-modal/sms-confirm-modal.component';
export interface PriceAgreementModel {
  entity: PublicationChangePrice 
  data:any|undefined;
}

declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
@Component({
  selector: 'app-price-agreement-modal',
  templateUrl: './price-agreement-modal.component.html',
  styleUrls: ['./price-agreement-modal.component.css']
})
export class PriceAgreementModalComponent extends SimpleModalComponent<PriceAgreementModel, boolean> implements PriceAgreementModel, OnInit {
  entity: PublicationChangePrice = new PublicationChangePrice();
  data: any = null;
  constructor(
    private modalService:SimpleModalService
  ) {
    super();
  }
  async Accept() {
    let smsConfirm = await this.modalService.addModal(SmsConfirmModalComponent, {   //SMS confirmation form
      data: {
        title: "Подтверждение сделки",
        text: "Введите код из СМС, чтобы принять соглашение"
      }
    }).toPromise<boolean>();
    if (smsConfirm) {
      this.result = true;
      this.close();
      
    } else {
      this.result = false;
      this.close();
    }
  }
  Cancel() {
    this.result = false;
    this.close();
    
  }
  ngOnInit(): void {
    
  }
}
