<div class="filter df aic jcsb mb4" data-attr="shared-area">
  <div class="df aitc">
    <div class="df aic mr15">
      <span class="fz14 ff:CormorantInfant">
        {{ 'Packages per page' | translate }}
      </span>
    </div>
    <div
      class="ui selection dropdown ddown filter__dropdown mw10 filter__quantity"
      tabindex="0"
    >
      <i class="dropdown icon"></i>
      <div class="text">
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00072 0.789474H0.789474L0.789473 2.40591H6.00072L6.00072 0.789474ZM0.789474 0C0.353459 0 0 0.35346 0 0.789474V2.40591C0 2.84193 0.353459 3.19539 0.789473 3.19539H6.00072C6.43674 3.19539 6.7902 2.84193 6.7902 2.40591V0.789474C6.7902 0.353459 6.43674 0 6.00072 0H0.789474Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00072 8.73039H0.789474L0.789473 10.3468H6.00072L6.00072 8.73039ZM0.789474 7.94092C0.353459 7.94092 0 8.29438 0 8.73039V10.3468C0 10.7828 0.353459 11.1363 0.789473 11.1363H6.00072C6.43674 11.1363 6.7902 10.7828 6.7902 10.3468V8.73039C6.7902 8.29438 6.43674 7.94092 6.00072 7.94092H0.789474Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2117 0.789474H9.00041L9.00041 2.40591H14.2117L14.2117 0.789474ZM9.00041 0C8.5644 0 8.21094 0.35346 8.21094 0.789474V2.40591C8.21094 2.84193 8.5644 3.19539 9.00041 3.19539H14.2117C14.6477 3.19539 15.0011 2.84193 15.0011 2.40591V0.789474C15.0011 0.353459 14.6477 0 14.2117 0H9.00041Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2117 8.73039H9.00041L9.00041 10.3468H14.2117L14.2117 8.73039ZM9.00041 7.94092C8.5644 7.94092 8.21094 8.29438 8.21094 8.73039V10.3468C8.21094 10.7828 8.5644 11.1363 9.00041 11.1363H14.2117C14.6477 11.1363 15.0011 10.7828 15.0011 10.3468V8.73039C15.0011 8.29438 14.6477 7.94092 14.2117 7.94092H9.00041Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2117 4.65275H9.00041L9.00041 6.26919H14.2117L14.2117 4.65275ZM9.00041 3.86328C8.5644 3.86328 8.21094 4.21674 8.21094 4.65276V6.26919C8.21094 6.70521 8.5644 7.05867 9.00041 7.05867H14.2117C14.6477 7.05867 15.0011 6.70521 15.0011 6.26919V4.65275C15.0011 4.21674 14.6477 3.86328 14.2117 3.86328H9.00041Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2117 12.5942H9.00041L9.00041 14.2106H14.2117L14.2117 12.5942ZM9.00041 11.8047C8.5644 11.8047 8.21094 12.1581 8.21094 12.5942V14.2106C8.21094 14.6466 8.5644 15.0001 9.00041 15.0001H14.2117C14.6477 15.0001 15.0011 14.6466 15.0011 14.2106V12.5942C15.0011 12.1581 14.6477 11.8047 14.2117 11.8047H9.00041Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00072 4.65275H0.789474L0.789473 6.26919H6.00072L6.00072 4.65275ZM0.789474 3.86328C0.353459 3.86328 0 4.21674 0 4.65276V6.26919C0 6.70521 0.353459 7.05867 0.789473 7.05867H6.00072C6.43674 7.05867 6.7902 6.70521 6.7902 6.26919V4.65275C6.7902 4.21674 6.43674 3.86328 6.00072 3.86328H0.789474Z"
            fill="#223355"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00072 12.5942H0.789474L0.789473 14.2106H6.00072L6.00072 12.5942ZM0.789474 11.8047C0.353459 11.8047 0 12.1581 0 12.5942V14.2106C0 14.6466 0.353459 15.0001 0.789473 15.0001H6.00072C6.43674 15.0001 6.7902 14.6466 6.7902 14.2106V12.5942C6.7902 12.1581 6.43674 11.8047 6.00072 11.8047H0.789474Z"
            fill="#223355"
          />
        </svg>
        8
      </div>
      <div class="menu" tabindex="-1">
        <div class="item" data-value="1" (click)="changeLimit(8)">
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00072 0.789474H0.789474L0.789473 2.40591H6.00072L6.00072 0.789474ZM0.789474 0C0.353459 0 0 0.35346 0 0.789474V2.40591C0 2.84193 0.353459 3.19539 0.789473 3.19539H6.00072C6.43674 3.19539 6.7902 2.84193 6.7902 2.40591V0.789474C6.7902 0.353459 6.43674 0 6.00072 0H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00072 8.73039H0.789474L0.789473 10.3468H6.00072L6.00072 8.73039ZM0.789474 7.94092C0.353459 7.94092 0 8.29438 0 8.73039V10.3468C0 10.7828 0.353459 11.1363 0.789473 11.1363H6.00072C6.43674 11.1363 6.7902 10.7828 6.7902 10.3468V8.73039C6.7902 8.29438 6.43674 7.94092 6.00072 7.94092H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2117 0.789474H9.00041L9.00041 2.40591H14.2117L14.2117 0.789474ZM9.00041 0C8.5644 0 8.21094 0.35346 8.21094 0.789474V2.40591C8.21094 2.84193 8.5644 3.19539 9.00041 3.19539H14.2117C14.6477 3.19539 15.0011 2.84193 15.0011 2.40591V0.789474C15.0011 0.353459 14.6477 0 14.2117 0H9.00041Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2117 8.73039H9.00041L9.00041 10.3468H14.2117L14.2117 8.73039ZM9.00041 7.94092C8.5644 7.94092 8.21094 8.29438 8.21094 8.73039V10.3468C8.21094 10.7828 8.5644 11.1363 9.00041 11.1363H14.2117C14.6477 11.1363 15.0011 10.7828 15.0011 10.3468V8.73039C15.0011 8.29438 14.6477 7.94092 14.2117 7.94092H9.00041Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2117 4.65275H9.00041L9.00041 6.26919H14.2117L14.2117 4.65275ZM9.00041 3.86328C8.5644 3.86328 8.21094 4.21674 8.21094 4.65276V6.26919C8.21094 6.70521 8.5644 7.05867 9.00041 7.05867H14.2117C14.6477 7.05867 15.0011 6.70521 15.0011 6.26919V4.65275C15.0011 4.21674 14.6477 3.86328 14.2117 3.86328H9.00041Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2117 12.5942H9.00041L9.00041 14.2106H14.2117L14.2117 12.5942ZM9.00041 11.8047C8.5644 11.8047 8.21094 12.1581 8.21094 12.5942V14.2106C8.21094 14.6466 8.5644 15.0001 9.00041 15.0001H14.2117C14.6477 15.0001 15.0011 14.6466 15.0011 14.2106V12.5942C15.0011 12.1581 14.6477 11.8047 14.2117 11.8047H9.00041Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00072 4.65275H0.789474L0.789473 6.26919H6.00072L6.00072 4.65275ZM0.789474 3.86328C0.353459 3.86328 0 4.21674 0 4.65276V6.26919C0 6.70521 0.353459 7.05867 0.789473 7.05867H6.00072C6.43674 7.05867 6.7902 6.70521 6.7902 6.26919V4.65275C6.7902 4.21674 6.43674 3.86328 6.00072 3.86328H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00072 12.5942H0.789474L0.789473 14.2106H6.00072L6.00072 12.5942ZM0.789474 11.8047C0.353459 11.8047 0 12.1581 0 12.5942V14.2106C0 14.6466 0.353459 15.0001 0.789473 15.0001H6.00072C6.43674 15.0001 6.7902 14.6466 6.7902 14.2106V12.5942C6.7902 12.1581 6.43674 11.8047 6.00072 11.8047H0.789474Z"
              fill="#223355"
            />
          </svg>
          8
        </div>
        <div class="item" data-value="1" (click)="changeLimit(12)">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.15789 9.47356H0.789474L0.789474 14.2104H3.15789V9.47356ZM0.789474 8.68408C0.353459 8.68408 0 9.03754 0 9.47356V14.2104C0 14.6464 0.353459 14.9999 0.789474 14.9999H3.15789C3.59391 14.9999 3.94737 14.6464 3.94737 14.2104V9.47356C3.94737 9.03754 3.59391 8.68408 3.15789 8.68408H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.15789 0.789474H0.789474L0.789474 5.52632H3.15789V0.789474ZM0.789474 0C0.353459 0 0 0.353459 0 0.789474V5.52632C0 5.96233 0.353459 6.31579 0.789474 6.31579H3.15789C3.59391 6.31579 3.94737 5.96233 3.94737 5.52632V0.789474C3.94737 0.353459 3.59391 0 3.15789 0H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.68524 9.47356H6.31682L6.31682 14.2104H8.68524V9.47356ZM6.31682 8.68408C5.8808 8.68408 5.52734 9.03754 5.52734 9.47356V14.2104C5.52734 14.6464 5.8808 14.9999 6.31682 14.9999H8.68524C9.12125 14.9999 9.47471 14.6464 9.47471 14.2104V9.47356C9.47471 9.03754 9.12125 8.68408 8.68524 8.68408H6.31682Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.68524 0.789474H6.31682L6.31682 5.52632H8.68524V0.789474ZM6.31682 0C5.8808 0 5.52734 0.353459 5.52734 0.789474V5.52632C5.52734 5.96233 5.8808 6.31579 6.31682 6.31579H8.68524C9.12125 6.31579 9.47471 5.96233 9.47471 5.52632V0.789474C9.47471 0.353459 9.12125 0 8.68524 0H6.31682Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2087 9.47356H11.8403L11.8403 14.2104H14.2087V9.47356ZM11.8403 8.68408C11.4042 8.68408 11.0508 9.03754 11.0508 9.47356V14.2104C11.0508 14.6464 11.4042 14.9999 11.8403 14.9999H14.2087C14.6447 14.9999 14.9981 14.6464 14.9981 14.2104V9.47356C14.9981 9.03754 14.6447 8.68408 14.2087 8.68408H11.8403Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2087 0.789474H11.8403L11.8403 5.52632H14.2087V0.789474ZM11.8403 0C11.4042 0 11.0508 0.353459 11.0508 0.789474V5.52632C11.0508 5.96233 11.4042 6.31579 11.8403 6.31579H14.2087C14.6447 6.31579 14.9981 5.96233 14.9981 5.52632V0.789474C14.9981 0.353459 14.6447 0 14.2087 0H11.8403Z"
              fill="#223355"
            />
          </svg>
          12
        </div>
        <!-- <div class="item" data-value="2" (click)="changeLimit(27)">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2105 0.789474H0.789474L0.789473 5.6391H14.2105V0.789474ZM0.789474 0C0.353459 0 0 0.35346 0 0.789474V5.6391C0 6.07511 0.353459 6.42857 0.789473 6.42857H14.2105C14.6465 6.42857 15 6.07511 15 5.6391V0.789474C15 0.353459 14.6465 0 14.2105 0H0.789474Z"
              fill="#223355"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.2105 9.36076H0.789474L0.789473 14.2104H14.2105V9.36076ZM0.789474 8.57129C0.353459 8.57129 0 8.92475 0 9.36076V14.2104C0 14.6464 0.353459 14.9999 0.789473 14.9999H14.2105C14.6465 14.9999 15 14.6464 15 14.2104V9.36076C15 8.92475 14.6465 8.57129 14.2105 8.57129H0.789474Z"
              fill="#223355"
            />
          </svg>
          27
        </div> -->
        <div class="item" data-value="3" (click)="changeLimit(48)">
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.625 5.8335H14.375"
              stroke="#223355"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.625 1.25H14.375"
              stroke="#223355"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.625 10.4165H14.375"
              stroke="#223355"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          48
        </div>
      </div>
    </div>
  </div>
  <div class="df aitc">
    <a
      href="javascript:;"
      class="df aic mr15 sort__btn"
      (click)="toggleSortWay()"
      [class.active]="publicationsRequest.orderWay == 'asc'"
    >
      <svg
        class="ic ic-sort mr05"
        data-position="top center"
        [attr.data-tooltip]="
          (publicationsRequest.orderWay == 'asc' ? 'Ascending' : 'Descending')
            | translate
        "
      >
        <use xlink:href="/assets/img/sprite.svg#sort"></use>
      </svg>
      <span
        class="fz14 ff:CormorantInfant"
        data-position="top center"
        [attr.data-tooltip]="'Sort' | translate"
      >
        {{ 'Sort' | translate }}
      </span>
    </a>
    <select
      class="ui selection dropdown ddown filter__dropdown"
      [(ngModel)]="publicationsRequest.orderBy"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onSortChange()"
    >
      <option value="id">{{ 'By default' | translate }}</option>
      <option value="price">{{ 'By price' | translate }}</option>
    </select>
  </div>
</div>
<div *ngIf="publicationsResponse" class="mb5">
  <div *ngIf="publicationsResponse.list.length > 0; else noResults">
    <app-publication-cards
      [list]="publicationsResponse.list"
      [mode]="publicationsRequest.limit"
    ></app-publication-cards>

    <div
      class="mt2 mb5"
      *ngIf="publicationsRequest.page < publicationsResponse.totalPages - 1"
    >
      <div class="more__btn">
        <span class="more__line more__line_left bg-wt"></span>
        <a
          (click)="onSeeMoreClick()"
          href="javascript:;"
          class="btn btn_wt c-link"
        >
          {{ 'seeAll' | translate }}
        </a>
        <span class="more__line more__line_right bg-wt"></span>
      </div>
    </div>
  </div>
  <ng-template #noResults>
    <div class="mcards__wrap">
      <div class="mcards__list">
        <div class="mcards__item">
          <div class="fz20 ff:CormorantInfant">
            {{ 'No results' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
