<div class="crumbs">
  <ul>
    <li *ngFor="let link of links">
      <a *ngIf="link[0] != ''; else last" [routerLink]="link[0]" title="{{ link[1] | translate }}">
        {{ link[1] | translate }}
      </a>
      <ng-template #last>
        <span>{{ link[1] | translate }}</span>
      </ng-template>
    </li>
  </ul>
</div>