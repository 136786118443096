import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ExpertOpinion } from '../../models/expert-opinions/expert-opinion.model';
import { ExpertOpinionsResponse } from '../../models/expert-opinions/expert-opinions-response.model';
import { Utils } from 'src/app/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ExpertOpinionsService {
  private serverUrl = `${environment.cmsApiUrl}/api/ExpertOpinionsDirectory`;

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  async getAll(params: any): Promise<ExpertOpinionsResponse> {
    return await this.httpClient.get<ExpertOpinionsResponse>(
      `${this.serverUrl}/GetAll`,
      { params: params, headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async filter(params: any): Promise<ExpertOpinionsResponse> {
    return await this.httpClient.get<ExpertOpinionsResponse>(
      `${this.serverUrl}/Filter`,
      { params: params, headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async get(id: number): Promise<ExpertOpinion> {
    return await this.httpClient.get<ExpertOpinion>(
      `${this.serverUrl}/get/${id}`,
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async add(entity: ExpertOpinion): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/add`,
      JSON.stringify(entity),
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async update(entity: ExpertOpinion): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/update`,
      JSON.stringify(entity),
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async delete(id: number): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/delete/${id}`,
      {},
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }
}
