<div class="filter-components ui accordion">
  <button
    type="button"
    class="title btn filter__btn mr3"
    data-position="bottom center"
    data-tooltip="Фильтр"
  >
    <svg class="ic ic-filter mr05">
      <use xlink:href="/assets/img/sprite.svg#filter"></use>
    </svg>
    {{ 'Filter' | translate }}
  </button>
  <div class="content">
    <form class="filter-form" (submit)="onSubmit()">
      <div class="filter-form__search mb15">
        <div class="df aic mb15">
          <span class="fz14 ff:CormorantInfant">{{ 'Club' | translate }}</span>
        </div>
        <div class="pr">
          <input
            type="search"
            id="filter-input"
            class="form__input h4"
            placeholder="Ввести название"
          />
          <button type="button" class="form-serach__btn">
            <span class="feather-icon icon-search"></span>
          </button>
        </div>
        <div class="filter-form__search-result">
          <div class="form__item mb15">
            <div class="ch">
              <label class="ch__content">
                <input id="checkbox1" type="checkbox" class="ch__input" />
                <span class="ch__icon"></span>
                <span class="ch__tx">Altamira</span>
              </label>
            </div>
          </div>
          <div class="form__item mb15">
            <div class="ch">
              <label class="ch__content">
                <input id="checkbox2" type="checkbox" class="ch__input" />
                <span class="ch__icon"></span>
                <span class="ch__tx">Altamira 2</span>
              </label>
            </div>
          </div>
          <div class="form__item mb15">
            <div class="ch">
              <label class="ch__content">
                <input id="checkbox3" type="checkbox" class="ch__input" />
                <span class="ch__icon"></span>
                <span class="ch__tx">Altamira 3</span>
              </label>
            </div>
          </div>
          <div class="form__item mb15">
            <div class="ch">
              <label class="ch__content">
                <input id="checkbox4" type="checkbox" class="ch__input" />
                <span class="ch__icon"></span>
                <span class="ch__tx">Altamira 4</span>
              </label>
            </div>
          </div>
          <div class="form__item mb0">
            <div class="ch">
              <label class="ch__content">
                <input id="checkbox5" type="checkbox" class="ch__input" />
                <span class="ch__icon"></span>
                <span class="ch__tx">Altamira 5</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="selection-filter">
        <div id="selected1" class="selection-filter__item aic">
          <p>Altamira</p>
          <button
            type="button"
            class="selection-filter__close js-selection-filter-close"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="selected2" class="selection-filter__item aic">
          <p>Altamira 2</p>
          <button
            type="button"
            class="selection-filter__close js-selection-filter-close"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="selected3" class="selection-filter__item aic">
          <p>Altamira 3</p>
          <button
            type="button"
            class="selection-filter__close js-selection-filter-close"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="selected4" class="selection-filter__item aic">
          <p>Altamira 4</p>
          <button
            type="button"
            class="selection-filter__close js-selection-filter-close"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div id="selected5" class="selection-filter__item aic">
          <p>Altamira 5</p>
          <button
            type="button"
            class="selection-filter__close js-selection-filter-close"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="#223355"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="filter-form__line"></div>
      <div class="filter-form__select">
        <div class="df aic mb15">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Package type' | translate }}
          </span>
        </div>
        <select
          class="ui selection dropdown ddown filter__dropdown w100"
          [(ngModel)]="request.packageType"
          [ngModelOptions]="{ standalone: true }"
        >
          <option value="">{{ 'All' | translate }}</option>
          <option value=" ">{{ 'All' | translate }}</option>
          <option value="Mixed">{{ 'Mixed' | translate }}</option>
          <option value="One Club">{{ 'One Club' | translate }}</option>
        </select>
      </div>
      <div class="filter-form__line"></div>
      <div class="filter-form__check">
        <div class="df aic mb15">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Owner rating' | translate }}:
          </span>
        </div>
        <div class="mcard__title title">
          <svg class="ic ic-card-arrow ic-round">
            <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
          </svg>
        </div>
        <div class="content ui accordion">
          <div class="">
            <div class="form__item mb1" *ngFor="let userRating of userRatings">
              <div class="ch">
                <label class="ch__content">
                  <input
                    type="checkbox"
                    class="ch__input"
                    [(ngModel)]="userRating.selected"
                    (change)="onUserRatingsChange()"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <span class="ch__icon"></span>
                  <span class="ch__tx">{{ userRating.name }}</span>
                </label>
              </div>
            </div>
            <div class="form__item mb1">
              <div class="ch">
                <label class="ch__content">
                  <input
                    type="checkbox"
                    class="ch__input"
                    [(ngModel)]="userRatingsAllSelected"
                    (change)="onChangeAllUserRatings()"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <span class="ch__icon"></span>
                  <span class="ch__tx">{{ 'All' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-form__line"></div>
      <div class="filter-form__check">
        <div class="df aic mb15">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Package rating' | translate }}:
          </span>
        </div>
        <div class="mcard__title title">
          <svg class="ic ic-card-arrow ic-round">
            <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
          </svg>
        </div>
        <div class="content ui accordion">
          <div class="">
            <div
              class="form__item mb1"
              *ngFor="let packageRating of packageRatings"
            >
              <div class="ch">
                <label class="ch__content">
                  <input
                    type="checkbox"
                    class="ch__input"
                    [(ngModel)]="packageRating.selected"
                    (change)="onPackageRatingsChange()"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <span class="ch__icon"></span>
                  <span class="ch__tx">{{ packageRating.name }}</span>
                </label>
              </div>
            </div>
            <div class="form__item mb1">
              <div class="ch">
                <label class="ch__content">
                  <input
                    type="checkbox"
                    class="ch__input"
                    [(ngModel)]="packageRatingsAllSelected"
                    (change)="onChangeAllPackageRatings()"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <span class="ch__icon"></span>
                  <span class="ch__tx">{{ 'All' | translate }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-form__line"></div>
      <div class="filter-form__chose mb2">
        <div class="df aic">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Package cost' | translate }}, USD:
          </span>
        </div>
        <div class="chose__range-items">
          <input
            type="range"
            name=""
            class="js-range-slider1"
            id="js-range-slider"
          />
          <div class="extra-controls">
            <span>
              {{ 'From' | translate }}
              <input
                [(ngModel)]="request.minPrice"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_from"
                class="js-input-from rang_slider_from1"
                value=""
              />
            </span>
            <span>
              {{ 'To' | translate }}
              <input
                [(ngModel)]="request.maxPrice"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_to"
                class="js-input-to rang_slider_to1"
                value=""
              />
            </span>
          </div>
          <!-- <div id="cost"></div> -->
        </div>
      </div>
      <div class="filter-form__chose mb2">
        <div class="df aic">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Cost for 1 certificate' | translate }}, USD:
          </span>
        </div>
        <div class="chose__range-items">
          <input
            type="range"
            name=""
            class="js-range-slider1"
            id="js-range-slider2"
          />
          <div class="extra-controls">
            <span>
              {{ 'From' | translate }}
              <input
                [(ngModel)]="request.minCertPrice"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_from"
                class="js-input-from rang_slider_from2"
                value=""
              />
            </span>
            <span>
              {{ 'To' | translate }}
              <input
                [(ngModel)]="request.maxCertPrice"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_to"
                class="js-input-to rang_slider_to2"
                value=""
              />
            </span>
          </div>
          <!-- <div id="cost"></div> -->
        </div>
      </div>
      <div class="filter-form__chose mb2">
        <div class="df aic">
          <span class="fz14 ff:CormorantInfant">
            {{ 'Number of certificates' | translate }}:
          </span>
        </div>
        <div class="chose__range-items">
          <input
            type="range"
            name=""
            class="js-range-slider1"
            id="js-range-slider3"
          />
          <div class="extra-controls">
            <span>
              {{ 'From' | translate }}
              <input
                [(ngModel)]="request.minCertQuantity"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_from"
                class="js-input-from rang_slider_from3"
                value=""
              />
            </span>
            <span>
              {{ 'To' | translate }}
              <input
                [(ngModel)]="request.maxCertQuantity"
                [ngModelOptions]="{ standalone: true }"
                type="number"
                name="price_to"
                class="js-input-to rang_slider_to3"
                value=""
              />
            </span>
          </div>
          <!-- <div id="cost"></div> -->
        </div>
      </div>
      <div class="filter-form__line"></div>
      <div class="df aic jcc filter-form__moretitle title">
        <div class="df aic mr15">
          <svg
            class="ic ic-sort mr05"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 14.9768V10.2558"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66797 7.55815V2.83722"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 14.9767V8.90698"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 6.20931V2.83722"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.332 14.9768V11.6047"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.332 8.90699V2.83722"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.667969 10.2558H4.66797"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6.20932H10"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.332 11.6047H15.332"
              stroke="#223355"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="fz14 ff:CormorantInfant open">Расширенный фильтр</span>
          <span class="fz14 ff:CormorantInfant close">Сокращенный фильтр</span>
        </div>
      </div>

      <div class="filter-form__morecontent content mb2">
        <div class="filter-form__select mb2">
          <div class="df aic mb15">
            <span class="fz14 ff:CormorantInfant">Тип права требования</span>
          </div>
          <select class="ui selection dropdown ddown filter__dropdown w100">
            <option value="0">Бессрочный</option>
            <option value="1">Cрочный</option>
          </select>
        </div>
        <div class="filter-form__params">
          <div class="filter-form__title mb15">Параметры права требования:</div>
          <div class="df aic jcsb mb15">
            <div class="filter-form__select">
              <div class="df aic mb15">
                <span class="fz14 ff:CormorantInfant">Кол-во интервалов</span>
              </div>
              <select class="ui selection dropdown ddown filter__dropdown">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            <div class="filter-form__select">
              <div class="df aic mb15">
                <span class="fz14 ff:CormorantInfant">Номер недели</span>
              </div>
              <select class="ui selection dropdown ddown filter__dropdown">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div class="filter-form__check">
            <div class="df aic mb15">
              <span class="fz14 ff:CormorantInfant">Наличие документов:</span>
            </div>
            <div class="form__item mb1">
              <div class="ch">
                <label class="ch__content mb15">
                  <input
                    type="radio"
                    name="doc"
                    value="Да"
                    class="ch-radio__input"
                    checked
                  />
                  <span class="ch__tx">Да</span>
                </label>
                <label class="ch__content">
                  <input
                    type="radio"
                    name="doc"
                    value="Нет"
                    class="ch-radio__input"
                  />
                  <span class="ch__tx">Нет</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__select">
          <div class="df aic mb15">
            <span class="fz14 ff:CormorantInfant">Система обмена</span>
          </div>
          <select class="ui selection dropdown ddown filter__dropdown w100">
            <option value="0">RCI</option>
            <option value="1">RCI</option>
          </select>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__select">
          <div class="df aic mb15">
            <span class="fz14 ff:CormorantInfant">
              Управленческие взносы, последний
              <br />
              год оплаты:
            </span>
          </div>
          <select class="ui selection dropdown ddown filter__dropdown w100">
            <option value="0">1990</option>
            <option value="1">1991</option>
            <option value="1">1992</option>
            <option value="1">1993</option>
          </select>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__check">
          <div class="df aic mb15">
            <span class="fz14 ff:CormorantInfant">Сезон:</span>
          </div>
          <div class="mcard__title title">
            <svg class="ic ic-card-arrow ic-round">
              <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
            </svg>
          </div>
          <div class="content ui accordion">
            <div class="">
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content aifs">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">Super High – (супер высокий) RED</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content aifs">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">High – (высокий) RED</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content aifs">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">
                      Middle – (cредний) YELLOW,
                      <br />
                      GREEN (желтый, зеленый)
                    </span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content aifs">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">
                      Low – (низкий) BLUE,
                      <br />
                      WHITE (голубой, белый)
                    </span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">Все</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-form__line"></div>
        <div class="filter-form__check">
          <div class="df aic mb15">
            <span class="fz14 ff:CormorantInfant">Тип апартаментов:</span>
          </div>
          <div class="mcard__title title">
            <svg class="ic ic-card-arrow ic-round">
              <use xlink:href="/assets/img/sprite.svg#card-arrow"></use>
            </svg>
          </div>
          <div class="content ui accordion">
            <div class="">
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">T0</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">T1</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">T2</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">T3</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">T4</span>
                  </label>
                </div>
              </div>
              <div class="form__item mb1">
                <div class="ch">
                  <label class="ch__content">
                    <input type="checkbox" class="ch__input" />
                    <span class="ch__icon"></span>
                    <span class="ch__tx">Все</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-form__btns df aic jcsb">
        <button type="submit" class="btn">{{ 'Apply' | translate }}</button>
        <button type="button" class="btn btn_wt" (click)="onResetFilterClick()">
          {{ 'Reset' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
