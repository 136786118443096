import { Component, OnInit, Input } from '@angular/core';
import * as html2pdf from 'html2pdf.js';

declare var $: any;

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css']
})
export class MembersListComponent implements OnInit {

  @Input() members: any;
  @Input() showFooter: boolean = false;
  @Input() showChat: boolean = false;

  pdfOptions = {
    filename: 'member.pdf',
    margin: 2,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { scale: 2 },
    enableLinks: false,
    jsPDF: { unit: 'em', format: 'a4', orientation: 'portrait' }
  };

  constructor() { }

  ngOnInit(): void {
  }

  preparePdfContent() {
    const doc = $('.html2pdf__container');
    doc.find('.pdf-add').removeClass('hidden');
    doc.find('.pdf-remove').remove();
    doc.find('.pdf-shrink').css({width: '70%', 'margin-right': 'auto', 'margin-left': 'auto'});
    doc.find('a').css({ color: 'inherit', 'text-decoration': 'none' });
  }

  download(event: Event) {
    $('#downloading-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => { this.preparePdfContent(); })
        .save()
        .finally(() => { setTimeout(() => { $.modal.close(); }, 100) });
    }, 100);
  }

  print(event: Event) {
    $('#printing-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.pdf-container');
      html2pdf()
        .set(this.pdfOptions)
        .from(element)
        .toContainer()
        .then(() => { this.preparePdfContent(); })
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          pdf.autoPrint();
          $.modal.close();
          setTimeout(() => {
            window.open(pdf.output('bloburl'), '_blank');
          }, 100);
        })
        .finally(() => { setTimeout(() => { $.modal.close(); }, 100) });
    }, 100);
  }

}
