import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit {
  @Input() page: number = 0;
  @Output() pageChange = new EventEmitter<number>(true);
  @Input() pages: number = 0;

  pageNumbers = new Array<number>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.paginate();
  }

  paginate(): void {
    console.log('paginate');
    this.pageNumbers = [];

    if (this.pages <= 0 || this.page < 0 || this.page >= this.pages) return;

    let minus = false;
    for (let i: number = 0; i < this.pages; i++) {
      if (
        i == 0 ||
        (i <= 4 && this.page < 4) ||
        (i >= this.page - 1 && i <= this.page + 1) ||
        (i >= this.pages - 1 - 4 && this.page > this.pages - 1 - 4) ||
        i == this.pages - 1
      ) {
        this.pageNumbers.push(i);
        minus = false;
        continue;
      }
      if (!minus) {
        this.pageNumbers.push(-1);
        minus = true;
      }
    }
  }

  setPage(page: number): void {
    this.page = page;
    this.pageChange.emit(page);
  }
}
